const projectUrl = "/projects";

class ProjectApi {
  constructor(fetcher) {
    this.fetcher = fetcher;
  }

  async createOne(data) {
    const res = await this.fetcher.post(projectUrl, data);
    return res.data.data;
  }

  async updateOne(id, data) {
    const res = await this.fetcher.put(`${projectUrl}/${id}`, data);
    return res.data.data;
  }

  async deleteOne(id) {
    const res = await this.fetcher.delete(`${projectUrl}/${id}`);
    return res.data.data;
  }

  async actionExecuter(id, action) {
    const res = await this.fetcher.put(`${projectUrl}/${id}/${action}`);
    return res.data.data;
  }

  async cloneOne(id) {
    const res = await this.fetcher.post(`${projectUrl}/${id}/clone`);
    return res.data.data;
  }

  async calculate(id) {
    const res = await this.fetcher.get(`/populate-project-report-collection/${id}`);
    return res.data.data;
  }
}

export default ProjectApi;
