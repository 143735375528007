import {
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Textarea,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../components/Layout/PageHeader";
import PageLayout from "../../../components/Layout/PageLayout";
import FormContainer from "../../../components/Form/FormContainer";
import FormFooter from "../../../components/Form/FormFooter";
import SelectWrapper from "../../../components/Form/SelectWrapper";
import Card from "../../../components/Layout/Card";
import { useApi } from "../../../contexts/ApiContext";
import { useUiContext } from "../../../contexts/UiContext";
import { regexEmail } from "../../../util/regex";

// TODO: phone regex
export function CompanyUpsertPage({ company, sectors, mutateCompany }) {
  const navigate = useNavigate();
  const toast = useToast();
  const { companyApi } = useApi();
  const { dispatchUiChange } = useUiContext();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name: company?.name ?? "",
      fullName: company?.fullName ?? "",
      sector: company?.sector?._id ?? "",
      address: company?.address ?? "",
      email: company?.email ?? "",
      website: company?.website ?? "",
      phone: company?.phone ?? "",
    },
  });

  const onSubmit = async (data) => {
    try {
      if (company) {
        await companyApi.updateOne(company._id, data);
        mutateCompany();
        toast({
          title: "Başarılı",
          description: "Firma başarıyla düzenlendi.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await companyApi.createOne(data);
        dispatchUiChange({
          type: "tableUIReset",
          payload: { name: "companyTable" },
        });
        toast({
          title: "Başarılı",
          description: "Yeni firma başarıyla eklendi.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      navigate("..");
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <PageLayout>
      <PageHeader
        title={company ? "Firma Düzenle" : "Yeni Firma Ekle"}
        backUrl={".."}
      />
      <Card topMargin>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContainer>
            <Stack direction={["column", "row"]} spacing="24px">
              <FormControl isInvalid={errors.name}>
                <FormLabel htmlFor="name">Firma</FormLabel>
                <Input
                  id="name"
                  placeholder="Abc"
                  {...register("name", {
                    required: "Gerekli alan",
                    minLength: {
                      value: 3,
                      message: "En az 3 karakter gerekli.",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.fullName}>
                <FormLabel htmlFor="fullName">Firma Ticari Adı</FormLabel>
                <Input
                  id="fullName"
                  placeholder="Abc Ltd. Sti."
                  {...register("fullName")}
                />
                <FormErrorMessage>
                  {errors.fullName && errors.fullName.message}
                </FormErrorMessage>
              </FormControl>
            </Stack>

            <Stack direction={["column", "row"]} spacing="24px">
              <FormControl isInvalid={errors.sector}>
                <FormLabel htmlFor="sector">Sektör</FormLabel>
                <SelectWrapper
                  register={{
                    ...register("sector", { required: "Gerekli alan" }),
                  }}
                  data={
                    sectors &&
                    sectors.map((item) => ({
                      value: item._id,
                      text: item.name,
                    }))
                  }
                />
                <FormErrorMessage>
                  {errors.sector && errors.sector.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.phone}>
                <FormLabel htmlFor="phone">Telefon</FormLabel>
                <Input
                  id="phone"
                  placeholder="+90 555 555 55 55"
                  {...register("phone")}
                />
                <FormErrorMessage>
                  {errors.phone && errors.phone.message}
                </FormErrorMessage>
              </FormControl>
            </Stack>

            <Stack direction={["column", "row"]} spacing="24px">
              <FormControl isInvalid={errors.email}>
                <FormLabel htmlFor="email">E-Posta Adresi</FormLabel>
                <Input
                  id="email"
                  placeholder="abc@firma.com"
                  {...register("email", {
                    pattern: {
                      value: regexEmail,
                      message: "Geçersiz e-posta",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.website}>
                <FormLabel htmlFor="website">Web Sitesi</FormLabel>
                <Input
                  id="website"
                  placeholder="www.google.com"
                  {...register("website")}
                />
                <FormErrorMessage>
                  {errors.website && errors.website.message}
                </FormErrorMessage>
              </FormControl>
            </Stack>

            <FormControl isInvalid={errors.address}>
              <FormLabel htmlFor="address">Adres</FormLabel>
              <Textarea
                variant={"filled"}
                id="address"
                placeholder="Firma Açık Adres"
                {...register("address")}
              />
              <FormErrorMessage>
                {errors.address && errors.address.message}
              </FormErrorMessage>
            </FormControl>

            <FormFooter>
              <Button
                colorScheme={"pink"}
                isLoading={isSubmitting}
                type="submit"
              >
                Kaydet
              </Button>
            </FormFooter>
          </FormContainer>
        </form>
      </Card>
    </PageLayout>
  );
}
