import { Box, HStack, IconButton, Text, Alert } from "@chakra-ui/react";
import { FiCheck, FiX } from "react-icons/fi";
import { useMemo } from "react";
import useSWR from "swr";
import { format } from "date-fns";
import LoadingIndicator from "../../../components/LoadingIndicator";
import PageHeader from "../../../components/Layout/PageHeader";
import PageLayout from "../../../components/Layout/PageLayout";
import ClientTable from "../../../components/Table/ClientTable";
import ConfirmAlert from "../../../components/Alert/ConfirmAlert";
import { InviteStatusTag } from "../../pms/plan/visitDetails/visitMsInvites";
import Card from "../../../components/Layout/Card";
import { useApi } from "../../../contexts/ApiContext";
import { useUiContext } from "../../../contexts/UiContext";

export function MsVisitInvitesPage() {
  const { data: invites, mutate: mutateInvites } = useSWR(
    "/mystery-shopper/visit-invites"
  );
  const { visitInviteApi } = useApi();
  const { showAlert } = useUiContext();

  async function action(type, inviteId) {
    await visitInviteApi.updateStatus(inviteId, type);
    mutateInvites();
  }

  const columns = useMemo(
    () => [
      {
        Header: "FİRMA",
        accessor: "location.company.name",
      },
      {
        Header: "ŞUBE",
        accessor: "location.name",
      },
      // {
      //   Header: "ÜLKE",
      //   accessor: "location.country",
      // },
      {
        Header: "ŞEHİR",
        accessor: "location.city",
      },
      {
        Header: "İLÇE",
        accessor: "location.district",
      },
      {
        Header: "BAŞLANGIÇ TARİHİ",
        accessor: (row) => {
          const date = row.visit?.plannedStartDate;
          return date ? format(new Date(date), "dd-MM-yyyy") : null;
        },
      },
      {
        Header: "BİTİŞ TARİHİ",
        accessor: (row) => {
          const date = row.visit?.plannedEndDate;
          return date ? format(new Date(date), "dd-MM-yyyy") : null;
        },
      },
      {
        Header: "Cevap",
        accessor: "_id",
        Cell: ({ cell }) => {
          const status = cell.row.original.status;
          return status === "pending" ? (
            <HStack>
              <IconButton
                size="xs"
                colorScheme="green"
                aria-label="Kabul"
                icon={<FiCheck />}
                onClick={() => {
                  showAlert(
                    <ConfirmAlert
                      header="Ziyareti kabul et"
                      body="Bu ziyarete katılmak istediğinize emin misiniz?"
                      buttonLabel="Onayla"
                      buttonColorScheme="green"
                      onAction={() => action("accept", cell.row.values._id)}
                    />
                  );
                }}
              />
              <IconButton
                size="xs"
                colorScheme="red"
                aria-label="Ret"
                icon={<FiX />}
                onClick={() => {
                  showAlert(
                    <ConfirmAlert
                      header="Ziyareti reddet"
                      body="Bu ziyarete katılmak istemediğinize emin misiniz?"
                      buttonLabel="Onayla"
                      buttonColorScheme="red"
                      onAction={() => action("reject", cell.row.values._id)}
                    />
                  );
                }}
              />
            </HStack>
          ) : (
            <InviteStatusTag status={status} />
          );
        },
      },
    ],
    []
  );

  return (
    <PageLayout>
      <PageHeader title="Ziyaret Davetleri" />
      <Card topMargin>
        {!invites ? (
          <LoadingIndicator />
        ) : (
          <Box>
            <Alert status="warning">
              Planlanladığımız ziyaretlere gizli müşteri olarak katılıp
              katılamayacağınızı bildirmenizi rica ederiz.
              <br />
              Teşekkürlerimizle, Çizgi Araştırma
            </Alert>
            <ClientTable data={invites} columns={columns} />
          </Box>
        )}
      </Card>
    </PageLayout>
  );
}
