import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export function RequireRole({ children, requiredRole }) {
  const { authState } = useAuth();
  const {
    user: { role },
  } = authState;

  // Admin or given role required
  if (role !== "admin" && role !== requiredRole) {
    return <Navigate to="/" />;
  }
  return children ? children : <Outlet />;
}
