import { useToast } from "@chakra-ui/react";
import useSWR from "swr";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { PlanUpsertComp } from "./planUpsertComp";
import { useAuth } from "../../../contexts/AuthContext";

export function PlanUpsertContainer({
  projectId,
  id,
  closeModal,
  mutateVisits,
}) {
  const { isFieldMgr, getCurrentUserId } = useAuth();
  const toast = useToast();
  const {
    data: visit,
    error: visitError,
    mutate,
  } = useSWR(id ? `/visits/${id}` : null);

  const { data: project, error: projectError } = useSWR(
    "/projects/" + projectId
  );

  const { data: locations, error: locationsError } = useSWR(
    () => "/companies/" + project.company._id + "/locations"
  );

  const { data: shoppers, error: shoppersError } = useSWR(
    "/mystery-shoppers/status/active"
  );

  const { data: fieldManagers, error: fieldManagersError } =
    useSWR("/field-managers");

  const { data: projectBreakdowns, error: projectBreakdownsError } = useSWR(
    `/projects/${projectId}/project-breakdowns`
  );

  const { data: scenarios, error: visitScenariosError } = useSWR(
    `/projects/${projectId}/visit-scenarios`
  );

  if (
    visitError ||
    projectError ||
    locationsError ||
    shoppersError ||
    fieldManagersError ||
    projectBreakdownsError ||
    visitScenariosError
  ) {
    toast({
      title: "Bir hata oluştu.",
      description: "Lütfen tekrar deneyin.",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
  }

  if (
    id &&
    (!visit ||
      !project ||
      !locations ||
      !shoppers ||
      !fieldManagers ||
      !projectBreakdowns)
  ) {
    return <LoadingIndicator />;
  }

  return (
    <PlanUpsertComp
      visit={visit}
      mutate={mutate}
      projectId={projectId}
      locations={locations}
      shoppers={shoppers}
      fieldManagers={fieldManagers}
      projectBreakdowns={projectBreakdowns}
      scenarios={scenarios}
      mutateVisits={mutateVisits}
      closeModal={closeModal}
      fm={isFieldMgr() && getCurrentUserId()}
    />
  );
}
