import {
  Box,
  Heading,
  Text,
  Button,
  Stack,
  Center,
  useToast,
} from "@chakra-ui/react";
import { useApi } from "../../../contexts/ApiContext";

export function MSTrainingPage({ ms, mutateMs }) {
  const { mysteryShopperApi } = useApi();
  const toast = useToast();

  async function handleClick() {
    try {
      await mysteryShopperApi.registerTraining(ms._id);
      mutateMs();
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  return (
    <Stack
      as={Box}
      textAlign={"center"}
      spacing={{ base: 8, md: 14 }}
      py={{ base: 20, md: 36 }}
    >
      <Heading
        fontWeight={600}
        fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
        lineHeight={"110%"}
      >
        Gizli Müşteri <br />
        <Text as={"span"} color={"pink.400"}>
          Eğitimi
        </Text>
      </Heading>
      <Text color={"gray.500"}>
        Gizli müşteri eğitimi tamamladıktan sonra eğitimin tamamlandığına dair
        onay verilir. Başvuru formunu doldurup, eğitimini tamamlayan GM adayı
        Admin tarafından onaylanmayı bekler 👍 / 👎
      </Text>
      <Center>
        <Button
          onClick={handleClick}
          colorScheme={"pink"}
          rounded={"full"}
          px={6}
        >
          Eğitim Tamamlandı
        </Button>
      </Center>
    </Stack>
  );
}
