import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Stack,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import PageLayout from "../../../components/Layout/PageLayout";
import PageHeader from "../../../components/Layout/PageHeader";
import FormContainer from "../../../components/Form/FormContainer";
import FormFooter from "../../../components/Form/FormFooter";
import StandardFormControl from "../../../components/Form/StandardFormControl";
import SelectWrapper from "../../../components/Form/SelectWrapper";
import Card from "../../../components/Layout/Card";
import { useApi } from "../../../contexts/ApiContext";
import { regexEmail, regexPassword } from "../../../util/regex";

// TODO: phone regex
export function NewUserPage() {
  const toast = useToast();
  const { adminApi, fieldMgrApi } = useApi();

  const {
    handleSubmit,
    register,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      role: "admin",
      name: "",
      email: "",
      password: "Pass1923",
      phone: "",
      status: true,
      region: "",
      // picture: "",
    },
  });

  const watchRole = watch("role");

  const onSubmit = async (data) => {
    const { status } = data;
    data.status = status ? "active" : "inactive";
    try {
      if (data.role === "admin") {
        // console.log("Admin", data);
        await adminApi.createOne(data);
        // mutate("/users");
      } else {
        // console.log("FieldMgr", data);
        await fieldMgrApi.createOne(data);
        // mutate("/users");
      }
      toast({
        title: "Başarılı",
        description: "Yeni kullanıcı oluşturuldu.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <PageLayout>
      <PageHeader title={"Yeni Kullanıcı Ekle"} backUrl={".."} />
      <Card topMargin>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContainer>
            <Stack direction={{ base: "column", md: "row" }} spacing="24px">
              <StandardFormControl
                errors={errors}
                name="role"
                label="Kullanıcı Rolü"
              >
                <SelectWrapper
                  register={{
                    ...register("role", { required: "Gerekli alan" }),
                  }}
                  data={[
                    { value: "admin", text: "Admin" },
                    { value: "fieldMgr", text: "Saha Çalışanı" },
                  ]}
                />
              </StandardFormControl>

              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="status" mb="0">
                  Aktif?
                </FormLabel>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Switch
                        id="status"
                        colorScheme="pink"
                        {...field}
                        isChecked={field.value ? true : false}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    );
                  }}
                />
              </FormControl>
            </Stack>

            <Stack direction={{ base: "column", md: "row" }} spacing="24px">
              <StandardFormControl errors={errors} name="name" label="Ad Soyad">
                <Input
                  id={"name"}
                  {...register("name", { required: "Gerekli alan" })}
                />
              </StandardFormControl>

              <StandardFormControl errors={errors} name="email" label="E-Posta">
                <Input
                  id={"email"}
                  {...register("email", {
                    required: "Gerekli alan",
                    pattern: {
                      value: regexEmail,
                      message: "Geçersiz e-posta",
                    },
                  })}
                />
              </StandardFormControl>
            </Stack>

            <Stack direction={{ base: "column", md: "row" }} spacing="24px">
              <StandardFormControl
                errors={errors}
                name="password"
                label="Şifre"
              >
                <Input
                  id={"password"}
                  isDisabled
                  {...register("password", {
                    validate: function (pass) {
                      if (pass === "" || regexPassword.test(pass)) {
                        return true;
                      }
                      return "En az 8 karakter giriniz";
                    },
                  })}
                />
              </StandardFormControl>

              <StandardFormControl errors={errors} name="phone" label="Telefon">
                <Input id={"phone"} {...register("phone")} />
              </StandardFormControl>
            </Stack>

            {watchRole === "fieldMgr" && (
              <Stack direction={{ base: "column", md: "row" }} spacing="24px">
                <StandardFormControl
                  errors={errors}
                  name="region"
                  label="Çalışma Bölgesi"
                >
                  <Input id={"region"} {...register("region")} />
                </StandardFormControl>
                <Spacer minW="33%" />
              </Stack>
            )}

            <FormFooter>
              <Button
                colorScheme={"pink"}
                isLoading={isSubmitting}
                type="submit"
              >
                Gönder
              </Button>
            </FormFooter>
          </FormContainer>
        </form>
      </Card>
    </PageLayout>
  );
}
