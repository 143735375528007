import { Tag } from "@chakra-ui/react";
import { projectStatus } from "../../../util/enums";

function getStatusColor(status) {
  const colors = {
    draft: "yellow",
    inProgress: "blue",
    completed: "purple",
  };
  return colors[status];
}

export function ProjectStatusTag({ status, ...rest }) {
  return (
    <Tag colorScheme={getStatusColor(status)} {...rest}>
      {projectStatus[status]?.tr}
    </Tag>
  );
}
