import LoadingIndicator from "../../../components/LoadingIndicator";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import PageHeader from "../../../components/Layout/PageHeader";
import PageLayout from "../../../components/Layout/PageLayout";
import ClientTable from "../../../components/Table/ClientTable";
import useSWR from "swr";
import Card from "../../../components/Layout/Card";

export function MsVisitListPage({ status }) {
  const { data } = useSWR("/mystery-shopper/visits");
  const visits =
    status === "pending"
      ? data?.filter((v) => v.status === "ms_confirmed")
      : status === "rejected"
      ? data?.filter(
          (v) => v.status === "rejected" || v.status === "fm_rejected"
        )
      : status === "inProgress"
      ? data?.filter(
          (v) =>
            v.status === "waiting_approval_admin" ||
            v.status === "waiting_approval_fm" ||
            v.status === "fm_approved"
        )
      : status === "approved"
      ? data?.filter((v) => v.status === "approved" || v.status === "published")
      : [];

  const columns = useMemo(
    () => [
      {
        Header: "ZİYARET",
        accessor: "name",
        Cell: ({ cell }) =>
          status !== "inProgress" ? (
            <Link
              to={
                status === "approved"
                  ? `/ina/ms/visits/${cell.row.original._id}/financials`
                  : `/ina/ms/visits/${cell.row.original._id}`
              }
            >
              {cell.row.values.name}
            </Link>
          ) : (
            cell.row.values.name
          ),
      },
      {
        Header: "FİRMA",
        accessor: "project.company.name",
      },
      {
        Header: "Lokasyon",
        accessor: "location.name",
      },
    ],
    [status]
  );

  const pageTitle = {
    pending: "Bekleyen Ziyaretler",
    inProgress: "Onay Bekleyen Ziyaretler",
    rejected: "Revize Gereken Ziyaretler",
    approved: "Tamamlanan Ziyaretler",
  };

  return (
    <PageLayout>
      <PageHeader title={pageTitle[status]} />
      <Card topMargin>
        {!visits ? (
          <LoadingIndicator />
        ) : (
          <ClientTable data={visits} columns={columns} />
        )}
      </Card>
    </PageLayout>
  );
}
