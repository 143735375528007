import { crmCompanyUrl } from "./apiFactory";
const crmLocationMgrUrl = "/location-managers";

class LocationMgrApi {
  constructor(fetcher) {
    this.fetcher = fetcher;
  }

  async createOneForCompany(companyId, data) {
    const res = await this.fetcher.post(
      `${crmCompanyUrl}/${companyId}/location-managers`,
      data
    );
    return res.data.data;
  }

  async updateOne(id, data) {
    const res = await this.fetcher.put(`${crmLocationMgrUrl}/${id}`, data);
    return res.data.data;
  }

  async deleteOne(id) {
    const res = await this.fetcher.delete(`${crmLocationMgrUrl}/${id}`);
    return res.data.data;
  }
}

export default LocationMgrApi;
