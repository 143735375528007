import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  DrawerHeader,
  useToast,
} from "@chakra-ui/react";
import PageHeader from "../../../components/Layout/PageHeader";
import { FiPlus, FiMoreHorizontal } from "react-icons/fi";
import { useMemo } from "react";
import ClientTable from "../../../components/Table/ClientTable";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { useApi } from "../../../contexts/ApiContext";
import { useUiContext } from "../../../contexts/UiContext";
import DeleteConfirmAlert from "../../../components/Alert/DeleteConfirmAlert";
import LocationUpsertComp from "./locationUpsertComp";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { countryNames } from "../../../util/enums";
import Card from "../../../components/Layout/Card";

export function LocationListComp() {
  const toast = useToast();
  const { id: companyId } = useParams();
  const { locationApi } = useApi();
  const { showAlert, closeAlertBox, showDrawer, closeDrawer } = useUiContext();
  const { data: locations, mutate } = useSWR(
    `/companies/${companyId}/locations`
  );

  const columns = useMemo(
    () => [
      {
        Header: "Lokasyon",
        accessor: "name",
      },
      {
        Header: "Ülke",
        accessor: (row) => countryNames[row.country]?.tr,
      },
      {
        Header: "ŞEHİR",
        accessor: "city",
      },
      {
        Header: "İLÇE",
        accessor: "district",
      },

      {
        Header: "",
        accessor: "_id",
        isNumeric: true,
        Cell: ({ cell }) => (
          <Menu>
            <MenuButton
              variant="ghost"
              size="sm"
              as={IconButton}
              icon={<FiMoreHorizontal />}
            />
            <MenuList>
              <MenuItem
                onClick={() => {
                  showDrawer(
                    <>
                      <DrawerHeader>
                        Lokasyon: {cell.row.values.name} Düzenle{" "}
                      </DrawerHeader>

                      <LocationUpsertComp
                        companyId={companyId}
                        id={cell.row.values._id}
                        closeDrawer={closeDrawer}
                      />
                    </>
                  );
                }}
              >
                Düzenle
              </MenuItem>
              <MenuItem
                onClick={() => {
                  showAlert(
                    <DeleteConfirmAlert
                      name={"Firmaya ait lokasyon"}
                      onDelete={() => deleteRow(cell.row.values._id)}
                    />
                  );
                }}
              >
                Sil
              </MenuItem>
            </MenuList>
          </Menu>
        ),
      },
    ],
    []
  );

  const deleteRow = async (id) => {
    try {
      await locationApi.deleteOne(id);
      toast({
        title: "Başarılı",
        description: "Firmaya ait olan lokasyon silindi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    mutate();
  };

  return (
    <>
      <Card>
        <PageHeader inBox subheader title={`Firma Lokasyonları`}>
          <Button
            leftIcon={<FiPlus />}
            size="sm"
            colorScheme="orange"
            onClick={() => {
              showDrawer(
                <>
                  <DrawerHeader>Yeni Lokasyon Ekle</DrawerHeader>
                  <LocationUpsertComp
                    companyId={companyId}
                    closeDrawer={closeDrawer}
                  />
                </>
              );
            }}
          >
            Yeni Ekle
          </Button>
        </PageHeader>

        {!locations ? (
          <LoadingIndicator />
        ) : (
          <ClientTable data={locations} columns={columns} />
        )}
      </Card>
    </>
  );
}
