export const Logo = (props) => {
  return (
    <svg height={24} viewBox="0 0 151 72" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31 70H0.3V67.6L9 66.7V6.49999L0.3 5.59999V3.2H31V5.59999L22.3 6.49999V66.7L31 67.6V70ZM64.3375 31.8C67.3375 24.0667 73.3708 20.2 82.4375 20.2C86.9708 20.2 90.6375 21.5667 93.4375 24.3C96.2375 26.9667 97.6375 30.8 97.6375 35.8C97.6375 44.4 97.3708 54.8333 96.8375 67.1L103.738 67.6V70H81.6375V67.6L87.0375 67.2C87.5708 52.1333 87.8375 43.5 87.8375 41.3C87.8375 39.1 87.8042 37.4333 87.7375 36.3C87.6708 35.1 87.4708 33.6667 87.1375 32C86.8708 30.2667 86.4375 28.9 85.8375 27.9C84.3708 25.4333 82.0708 24.2 78.9375 24.2C74.6708 24.2 71.1708 25.9667 68.4375 29.5C65.7042 33.0333 64.3375 36.8667 64.3375 41V67.1L71.8375 67.6V70H47.9375V67.6L54.5375 67.1V24.8L46.0375 24.4V22.1L63.9375 20.9L63.4375 31.7L64.3375 31.8ZM112.847 59.2C112.847 63.5333 114.614 66.1 118.147 66.9C118.947 67.1 119.714 67.2 120.447 67.2C122.98 67.2 125.614 66.1333 128.347 64C131.08 61.8 132.514 58.8667 132.647 55.2V46.6C119.447 46.7333 112.847 50.9333 112.847 59.2ZM116.847 71.2C113.047 71.2 109.78 70.1667 107.047 68.1C104.38 65.9667 103.047 63 103.047 59.2C103.114 54.0667 105.68 50.2333 110.747 47.7C115.88 45.1667 123.18 43.9 132.647 43.9V35.3C132.647 26.8333 129.58 22.6 123.447 22.6C120.314 22.6 117.78 23.9 115.847 26.5C114.514 28.3 113.847 30.5333 113.847 33.2C113.847 34.3333 113.98 35.5333 114.247 36.8C113.314 37.4667 112.347 37.8 111.347 37.8C110.414 37.8 109.48 37.6 108.547 37.2C106.414 36.4 105.347 34.8667 105.347 32.6C105.347 29.5333 106.447 27 108.647 25C112.247 21.7333 117.214 20.1 123.547 20.1C129.88 20.1 134.48 21.4667 137.347 24.2C140.214 26.8667 141.647 31.1 141.647 36.9V67.2L150.047 67.6L150.147 69.9L133.447 71.1L132.547 62.2C129.48 68.2 124.247 71.2 116.847 71.2Z"
        fill="black"
      />
      <path
        d="M47.4047 9.7C47.4047 13.7667 45.7714 17.6333 42.5047 21.3C39.238 24.9667 36.4714 27.2333 34.2047 28.1L32.7047 25.7C34.9714 24.5 36.9047 22.7333 38.5047 20.4C40.1047 18.0667 41.038 15.6667 41.3047 13.2C40.5047 13.6 39.4047 13.8 38.0047 13.8C36.6714 13.8 35.5047 13.2 34.5047 12C33.5714 10.8 33.1047 9.23333 33.1047 7.3C33.1047 5.3 33.8047 3.73333 35.2047 2.6C36.6047 1.46666 38.338 0.899996 40.4047 0.899996C42.538 0.899996 44.238 1.63333 45.5047 3.1C46.7714 4.5 47.4047 6.7 47.4047 9.7Z"
        fill="#C20E1A"
      />
    </svg>
  );
};
