import {
  Button,
  FormControl,
  FormLabel,
  Select,
  SimpleGrid,
  Center,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { useController } from "react-hook-form";
import useSWR from "swr";

function LocationTag({ label, onClick }) {
  return (
    <Tag size="lg" borderRadius="full" variant="outline" colorScheme="pink">
      <TagLabel>{label}</TagLabel>
      <TagCloseButton onClick={onClick} />
    </Tag>
  );
}

export function LocationMultiSelect({ name, control, companyId }) {
  // UI state
  const [locationId, setLocationId] = useState("");
  // locationId = "id1"
  // selectedLocationIds & value = ["id1", "id2", ...]
  const {
    field: { value, onChange },
    fieldState: { invalid, error },
  } = useController({ name, control });
  const [selectedLocationIds, setSelectedLocationIds] = useState(value);

  const { data: locations, error: swrError } = useSWR(
    `/companies/${companyId}/locations`
  );

  function handleChangeLocation(locationId) {
    setLocationId(locationId);
  }

  function addValue() {
    if (!selectedLocationIds.includes(locationId) && locationId !== "") {
      setSelectedLocationIds((prevState) => [...prevState, locationId]); // UI update
      onChange([...value, locationId]); // value update
    }
  }

  function removeValue(locationId) {
    setSelectedLocationIds((prevState) =>
      prevState.filter((loc) => loc !== locationId)
    ); // UI update
    onChange(value.filter((loc) => loc !== locationId)); // value update
  }

  return (
    <Stack direction={"column"} spacing="12px">
      <FormControl isInvalid={invalid}>
        <FormLabel>Lokasyon</FormLabel>
        {!locations ? (
          // empty select as placeholder
          <Select variant={"filled"} placeholder="Lütfen seçiniz" />
        ) : (
          <Select
            placeholder="Lütfen seçiniz"
            variant={"filled"}
            name="locationId"
            value={locationId}
            onChange={(e) => handleChangeLocation(e.target.value)}
          >
            {locations.map(({ _id, name }) => (
              <option key={_id} value={_id}>
                {name}
              </option>
            ))}
          </Select>
        )}
      </FormControl>

      <Stack direction="row" spacing="12px">
        <Button size={"sm"} onClick={addValue} colorScheme="blue">
          <AddIcon />
        </Button>
        {selectedLocationIds?.length > 0 && (
          <SimpleGrid
            columns={"2"}
            spacing={2}
            border={"2px"}
            borderColor="gray.200"
            borderRadius={"lg"}
            p={2}
          >
            {selectedLocationIds.map((id) => (
              <LocationTag
                key={id}
                label={locations?.filter((loc) => loc._id === id)[0]?.name}
                onClick={() => removeValue(id)}
              />
            ))}
          </SimpleGrid>
        )}
        <Center color={"red.500"}>{error?.message}</Center>
      </Stack>
    </Stack>
  );
}
