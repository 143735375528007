import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export function RequireAdmin({ children }) {
  const { isAdmin } = useAuth();
  if (!isAdmin()) {
    return <Navigate to="/" />;
  }
  return children ? children : <Outlet />;
}
