import { Input } from "@chakra-ui/react";

export function RequiredFileInput({ name, register }) {
  return (
    <Input
      id={name}
      type={"file"}
      sx={{
        "::file-selector-button": {
          height: 10,
          padding: 1,
          mr: 4,
          background: "none",
          border: "none",
          fontWeight: "bold",
        },
      }}
      {...register(name, {
        required: "Gerekli alan",
      })}
    />
  );
}
