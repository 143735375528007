import {
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
  FormControl,
  FormErrorMessage,
  Image,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { NotAllowedIcon } from "@chakra-ui/icons";
import { RequiredFileInput } from "./Form";
import { useForm } from "react-hook-form";
import { useUiContext } from "../contexts/UiContext";
import { useApi } from "../contexts/ApiContext";
import { useSWRConfig } from "swr";

export const LOGO_SOURCE = "/public/images/company";

export function CompanyLogoAlert({ companyId, logo }) {
  const { closeAlertBox } = useUiContext();
  const toast = useToast();
  const { mutate } = useSWRConfig();
  const { fileUploadApi } = useApi();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      await fileUploadApi.uploadLogoForCompany(companyId, data);
      mutate(`/companies/${companyId}`);
      toast({
        title: "Başarılı",
        description: "Şirket logosu kaydedildi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      closeAlertBox();
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <AlertDialogContent>
      <AlertDialogHeader fontSize="lg" fontWeight="bold" alignSelf={"center"}>
        {logo ? "Logoyu Değiştir" : "Yeni Logo Ekle"} 📷
      </AlertDialogHeader>

      <form onSubmit={handleSubmit(onSubmit)}>
        <AlertDialogBody>
          <Stack direction={"column"} align={"center"} spacing="5">
            <Image
              borderRadius="full"
              borderStyle={"solid"}
              borderWidth={"thin"}
              boxSize="128px"
              objectFit="cover"
              src={`${LOGO_SOURCE}/${logo}`}
              fallback={<NotAllowedIcon boxSize={"128px"} color={"gray.200"} />}
            />

            <FormControl isInvalid={errors.logo}>
              <RequiredFileInput name="logo" register={register} />
              <FormErrorMessage>{errors.logo?.message}</FormErrorMessage>
            </FormControl>
          </Stack>
          <AlertDialogFooter marginTop={4}>
            <Button onClick={closeAlertBox}>Vazgeç</Button>
            <Button
              colorScheme="green"
              ml={3}
              isLoading={isSubmitting}
              type="submit"
            >
              Kaydet
            </Button>
          </AlertDialogFooter>
        </AlertDialogBody>
      </form>
    </AlertDialogContent>
  );
}
