import { Stack, useToast } from "@chakra-ui/react";
import useSWR from "swr";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import { CommentCard, NewCommentForm } from "../../../../components/Comment";

export function VisitNotes({ visitId }) {
  const toast = useToast();
  const {
    data: comments,
    error,
    mutate: mutateComments,
  } = useSWR(`/visit/${visitId}/comments`);

  if (error) {
    toast({
      title: "Sunucu hatası",
      description: "Lütfen tekrar deneyin.",
      status: "error",
      duration: 3000,
      // isClosable: true,
    });
  }

  if (!comments) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Stack spacing={4} direction="column">
        {comments.map((comment) => (
          <CommentCard
            key={comment._id}
            comment={comment}
            mutateComments={mutateComments}
          />
        ))}
      </Stack>
      <NewCommentForm forVisit id={visitId} mutateComments={mutateComments} />
    </>
  );
}
