import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
} from "@chakra-ui/react";
import SelectWrapper from "./SelectWrapper";

import useSWR from "swr";

export function PersonnelTypesSelect({ errors, register, projectId }) {
  const { data: personnelTypes, error } = useSWR(
    `/projects/${projectId}/personneltypes`
  );

  return (
    <FormControl isInvalid={errors.personnelType}>
      <FormLabel>Personel Tipi</FormLabel>
      {!personnelTypes ? (
        // empty select as placeholder
        <Select variant={"filled"} placeholder="Lütfen seçiniz" />
      ) : (
        <SelectWrapper
          register={{
            ...register("personnelType"),
          }}
          data={personnelTypes.map((type) => ({
            value: type._id,
            text: type.name,
          }))}
        />
      )}
      <FormErrorMessage>{errors.personnelType?.message}</FormErrorMessage>
    </FormControl>
  );
}
