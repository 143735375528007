import {
  Box,
  Button,
  DrawerHeader,
  IconButton,
  Menu,
  MenuButton,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { FiMoreHorizontal, FiPlus } from "react-icons/fi";
import { useMemo } from "react";
import useSWR from "swr";
import { Link } from "react-router-dom";
import ClientTable from "../../components/Table/ClientTable";
import PageHeader from "../../components/Layout/PageHeader";
import PageLayout from "../../components/Layout/PageLayout";
import LoadingIndicator from "../../components/LoadingIndicator";
import MSMenuList from "./msMenuList";
import { Scale } from "../../components/Scale";
import { MSStatusTag } from "./msStatusTag";
import { NewMSForm } from "./newMSForm";
import { useMSStatusActions } from "../../hooks/useMsStatusActions";
import { useAuth } from "../../contexts/AuthContext";
import { useUiContext } from "../../contexts/UiContext";
import Card from "../../components/Layout/Card";

export function MysteryShopperListComp({ status }) {
  // Same component is used for different status types: status and application-status
  // "Onay bekleyenler" and "Reddedilenler" uses /msm/mystery-shoppers/application-status/:status
  // "Aktif" and "Pasif" uses /msm/mystery-shoppers/status/:status
  const url =
    status === "active" || status === "inactive"
      ? "/mystery-shoppers/status"
      : "/mystery-shoppers/application-status";
  const { data: mysteryShoppers } = useSWR(`${url}/${status}`);

  const {
    isLoading,
    makeMsActive,
    makeMsPassive,
    approveMsApplication,
    denyMsApplication,
  } = useMSStatusActions();

  const { showDrawer, closeDrawer } = useUiContext();
  const { isAdmin } = useAuth();

  const columns = useMemo(
    () => [
      {
        Header: "AD SOYAD",
        accessor: "name",
        Cell: ({ cell }) => {
          const { name, email } = cell.row.original;
          if (status !== "pending") {
            return (
              <Link to={`../${cell.row.values._id}`}>
                <Box>
                  <Text fontWeight="bold">{name}</Text>
                  <Text fontSize="sm">{email}</Text>
                </Box>
              </Link>
            );
          }
          return (
            <Box>
              <Text fontWeight="bold">{name}</Text>
              <Text fontSize="sm">{email}</Text>
            </Box>
          );
        },
      },
      {
        Header: "DURUM",
        accessor: "status",
        Cell: ({ cell }) => (
          <MSStatusTag
            status={cell.row.original.status}
            applicationStatus={cell.row.original.applicationStatus}
          />
        ),
      },
      {
        Header: "SÇM",
        accessor: "fieldMgr.name",
      },
      {
        Header: "PUAN",
        accessor: "averageVisitScore",
        Cell: ({ cell }) => {
          const score = cell.row.values.averageVisitScore;
          return score ? <Scale value={score} /> : null;
        },
      },
      {
        Header: "",
        accessor: "_id",
        isNumeric: true,
        Cell: ({ cell }) =>
          status !== "pending" && (
            <Menu>
              <MenuButton
                variant="ghost"
                size="sm"
                as={IconButton}
                icon={<FiMoreHorizontal />}
              />
              <MSMenuList
                cell={cell}
                handlePassive={makeMsPassive}
                handleActive={makeMsActive}
                handleApprove={approveMsApplication}
                handleDeny={denyMsApplication}
              />
            </Menu>
          ),
      },
    ],
    [status]
  );

  if (!mysteryShoppers) {
    return <LoadingIndicator />;
  }

  return (
    <PageLayout>
      <PageHeader
        title={
          status === "trainingCompleted"
            ? "Onay Bekleyen Başvurular"
            : status === "pending"
            ? "Yeni Başvurular"
            : status === "denied"
            ? "Reddedilen Başvurular"
            : "Gizli Müşteriler"
        }
      >
        {isLoading && (
          <Spinner
            thickness="4px"
            speed="0.75s"
            emptyColor="gray.200"
            color="pink.500"
            size="lg"
          />
        )}
        {isAdmin() && status === "pending" && (
          <Button
            leftIcon={<FiPlus />}
            size="sm"
            colorScheme="orange"
            onClick={() => {
              showDrawer(
                <>
                  <DrawerHeader>Yeni Gizli Müşteri Ekle</DrawerHeader>
                  <NewMSForm closeDrawer={closeDrawer} />
                </>
              );
            }}
          >
            Yeni Ekle
          </Button>
        )}
      </PageHeader>
      <Card topMargin>
        <ClientTable
          data={mysteryShoppers}
          columns={columns}
          tableName={`msm-${status}`}
        />
      </Card>
    </PageLayout>
  );
}
