import { useToast } from "@chakra-ui/react";
import useSWR from "swr";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { FieldMgrUpdateForm } from "./";

export function FieldMgrUpdateComp({ id }) {
  const { data: fieldMgr, error, mutate } = useSWR(`/field-managers/${id}`);
  const toast = useToast();

  if (error) {
    toast({
      title: "Bir hata oluştu.",
      status: "error",
    });
  }

  if (!fieldMgr) {
    return <LoadingIndicator />;
  }

  return (
    <FieldMgrUpdateForm
      fieldMgr={fieldMgr}
      mutateFieldMgr={mutate}
      showStatus
      disablePassword
    />
  );
}
