import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react";
import { FiPlus, FiMoreHorizontal } from "react-icons/fi";
import { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import useSWR from "swr";
import PageHeader from "../../../components/Layout/PageHeader";
import PageLayout from "../../../components/Layout/PageLayout";
import Card from "../../../components/Layout/Card";
import ClientTable from "../../../components/Table/ClientTable";
import { useApi } from "../../../contexts/ApiContext";
import { useUiContext } from "../../../contexts/UiContext";
import DeleteConfirmAlert from "../../../components/Alert/DeleteConfirmAlert";
import ConfirmAlert from "../../../components/Alert/ConfirmAlert";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { ProjectStatusTag } from "./projectStatusTag";

export function ProjectIndexPage({ archive }) {
  const projectsUrl = archive ? "/projects/status/archived" : "/projects";
  const navigate = useNavigate();
  const toast = useToast();
  const { projectApi } = useApi();
  const { data: projects, mutate: mutateProjects } = useSWR(projectsUrl);
  const { showAlert, closeAlertBox } = useUiContext();

  const columns = useMemo(
    () => [
      {
        Header: "PROJE ADI",
        accessor: "name",
        Cell: ({ cell }) => (
          <Link
            to={
              archive
                ? `../${cell.row.values._id}/project-dashboard`
                : `${cell.row.values._id}/project-dashboard`
            }
          >
            {cell.row.values.name}
          </Link>
        ),
      },
      {
        Header: "FİRMA",
        accessor: "company.name",
      },
      {
        Header: "Durum",
        accessor: "status",
        Cell: ({ cell }) => (
          <ProjectStatusTag status={cell.row.values.status} size={"sm"} />
        ),
      },
      {
        Header: "",
        accessor: "_id",
        isNumeric: true,
        Cell: ({ cell }) => (
          <Menu>
            <MenuButton
              variant="ghost"
              size="sm"
              as={IconButton}
              icon={<FiMoreHorizontal />}
            />
            <MenuList>
              {!archive && (
                <MenuItem
                  onClick={() => {
                    navigate(`${cell.row.values._id}/edit`);
                  }}
                >
                  Düzenle
                </MenuItem>
              )}
              {cell.row.original.status === "draft" && (
                <MenuItem
                  onClick={() => {
                    showAlert(
                      <DeleteConfirmAlert
                        name={"Proje"}
                        onDelete={() => deleteRow(cell.row.values._id)}
                      />
                    );
                  }}
                >
                  Sil
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  showAlert(
                    <ConfirmAlert
                      header="Projeyi Kopyala"
                      body="Projeyi kopyalamak istediğinize emin misiniz? Bu işlem zaman alabilir."
                      buttonLabel="Kopyala"
                      buttonColorScheme="pink"
                      onAction={() => cloneProject(cell.row.values._id)}
                    />
                  );
                }}
              >
                Klonla
              </MenuItem>
            </MenuList>
          </Menu>
        ),
      },
    ],
    [archive]
  );

  const deleteRow = async (id) => {
    try {
      await projectApi.deleteOne(id);
      mutateProjects();
      toast({
        title: "Başarılı",
        description: "Proje silindi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const cloneProject = async (id) => {
    try {
      await projectApi.cloneOne(id);
      mutateProjects();
      toast({
        title: "Başarılı",
        description: "Proje kopyalandı.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <PageLayout>
      <PageHeader title={archive ? "Proje Arşivi" : "Proje Listesi"}>
        {!archive && (
          <Button
            leftIcon={<FiPlus />}
            size="sm"
            colorScheme="pink"
            onClick={() => navigate("new")}
          >
            Yeni Ekle
          </Button>
        )}
      </PageHeader>
      <Card topMargin>
        {!projects ? (
          <LoadingIndicator />
        ) : (
          <ClientTable
            data={projects}
            columns={columns}
            tableName="pms-projects"
          />
        )}
      </Card>
    </PageLayout>
  );
}
