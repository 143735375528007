import {
  Button,
  Input,
  DrawerBody,
  DrawerFooter,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useSWRConfig } from "swr";
import { useApi } from "../../contexts/ApiContext";
import FormContainer from "../../components/Form/FormContainer";
import StandardFormControl from "../../components/Form/StandardFormControl";
import { regexEmail } from "../../util/regex";

export function NewMSForm({ closeDrawer }) {
  const toast = useToast();
  const { mysteryShopperApi } = useApi();
  const { mutate } = useSWRConfig();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      password: "Pass1923",
    },
  });

  async function onSubmit(data) {
    try {
      await mysteryShopperApi.createOneByAdmin(data);
      mutate("/mystery-shoppers/application-status/pending");
      toast({
        title: "Başarılı",
        description: "Yeni gizli müşteri eklendi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      closeDrawer();
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  return (
    <>
      <DrawerBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContainer p={2}>
            <Stack direction={"column"} spacing="24px">
              <StandardFormControl errors={errors} name="name" label="Ad Soyad">
                <Input
                  id="name"
                  placeholder="Ad Soyad"
                  {...register("name", {
                    required: "Gerekli alan",
                    minLength: {
                      value: 4,
                      message: "En az 4 karakter gerekli.",
                    },
                  })}
                />
              </StandardFormControl>

              <StandardFormControl errors={errors} name="email" label="E-posta">
                <Input
                  id="email"
                  placeholder="abc@company.com"
                  {...register("email", {
                    required: "Gerekli alan",
                    pattern: {
                      value: regexEmail,
                      message: "Geçersiz e-posta",
                    },
                  })}
                />
              </StandardFormControl>

              <StandardFormControl
                errors={errors}
                name="password"
                label="Şifre"
              >
                <Input
                  id="password"
                  isDisabled
                  // placeholder="Parola"
                  {...register("password", {
                    required: "Gerekli alan",
                  })}
                />
              </StandardFormControl>
            </Stack>
          </FormContainer>
        </form>
      </DrawerBody>
      <DrawerFooter>
        <Button variant="outline" mr={3} onClick={closeDrawer}>
          Vazgeç
        </Button>
        <Button
          colorScheme={"pink"}
          isLoading={isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          Kaydet
        </Button>
      </DrawerFooter>
    </>
  );
}
