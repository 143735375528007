import {
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Avatar,
  Button,
  FormControl,
  FormErrorMessage,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { RequiredFileInput } from "./Form";
import { useForm } from "react-hook-form";
import { useUiContext } from "../contexts/UiContext";
import { useApi } from "../contexts/ApiContext";
import { useSWRConfig } from "swr";

export function UserAvatarAlert({ userId, picture }) {
  const AVATAR_SOURCE = "/public/images/user";
  const { closeAlertBox } = useUiContext();
  const toast = useToast();
  const { mutate } = useSWRConfig();
  const { fileUploadApi } = useApi();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      await fileUploadApi.uploadAvatarForUser(userId, data);
      mutate(`/users/${userId}`);
      toast({
        title: "Başarılı",
        description: "Profil resmi kaydedildi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      closeAlertBox();
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <AlertDialogContent>
      <AlertDialogHeader fontSize="lg" fontWeight="bold" alignSelf={"center"}>
        {picture ? "Profil Resmini Değiştir" : "Profil Resmi Ekle"} 📷
      </AlertDialogHeader>

      <form onSubmit={handleSubmit(onSubmit)}>
        <AlertDialogBody>
          <Stack direction={"column"} align={"center"} spacing="5">
            <Avatar
              size={"2xl"}
              src={picture && `${AVATAR_SOURCE}/${picture}`}
            />
            <FormControl isInvalid={errors.avatar}>
              <RequiredFileInput name="avatar" register={register} />
              <FormErrorMessage>{errors.avatar?.message}</FormErrorMessage>
            </FormControl>
          </Stack>
          <AlertDialogFooter marginTop={4}>
            <Button onClick={closeAlertBox}>Vazgeç</Button>
            <Button
              colorScheme="green"
              ml={3}
              isLoading={isSubmitting}
              type="submit"
            >
              Kaydet
            </Button>
          </AlertDialogFooter>
        </AlertDialogBody>
      </form>
    </AlertDialogContent>
  );
}
