import {
  Button,
  Input,
  Textarea,
  Stack,
  Radio,
  useToast,
  Box,
} from "@chakra-ui/react";
import FormContainer from "../../../components/Form/FormContainer";
import FormFooter from "../../../components/Form/FormFooter";
import SelectWrapper from "../../../components/Form/SelectWrapper";
import StandardFormControl from "../../../components/Form/StandardFormControl";
import RadioFormControl from "../../../components/Form/RadioFormControl";
import { LanguageMultiSelect, MaskedDateInput } from "../../../components/Form";
import { useApi } from "../../../contexts/ApiContext";
import { useForm } from "react-hook-form";

export function MSPersonalInfoForm({ ms }) {
  const toast = useToast();
  const { mysteryShopperApi } = useApi();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      birthDate: ms.birthDate,
      birthPlace: ms.birthPlace,
      gender: ms.gender,
      maritalStatus: ms.maritalStatus,
      education: ms.education,
      occupation: ms.occupation,
      hasPassport:
        ms.hasPassport !== undefined && (ms.hasPassport ? "true" : "false"),
      ableToTravel: ms.ableToTravel,
      hasDriversLicense:
        ms.hasDriversLicense !== undefined &&
        (ms.hasDriversLicense ? "true" : "false"),
      hasCar: ms.hasCar !== undefined && (ms.hasCar ? "true" : "false"),
      hasKids: ms.hasKids !== undefined && (ms.hasKids ? "true" : "false"),
      foreignLanguages: ms.foreignLanguages,
      previousMsExperience: ms.previousMsExperience,
    },
  });

  const onSubmit = async (data) => {
    try {
      // function cleanObjectFromEmptyStrings(obj) {
      //   let returnObj = {};
      //   for (const prop in obj) {
      //     if (obj[prop] !== "") {
      //       returnObj[prop] = obj[prop];
      //     }
      //   }
      //   return returnObj;
      // }

      await mysteryShopperApi.updateOne(ms._id, data);
      toast({
        title: "Başarılı",
        description: "Bilgileriniz güncellendi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer>
        <Stack direction={{ base: "column", md: "row" }} spacing="24px">
          <StandardFormControl
            errors={errors}
            name="birthDate"
            label="Doğum Tarihi"
          >
            <MaskedDateInput name="birthDate" control={control} />
          </StandardFormControl>

          <StandardFormControl
            errors={errors}
            name="birthPlace"
            label="Doğum Yeri"
          >
            <Input
              id={"birthPlace"}
              {...register("birthPlace", { required: "Gerekli alan" })}
            />
          </StandardFormControl>
        </Stack>

        <Stack direction={{ base: "column", md: "row" }} spacing="24px">
          <RadioFormControl
            control={control}
            errors={errors}
            name="gender"
            label="Cinsiyet"
          >
            <Radio value="female">Kadın</Radio>
            <Radio value="male">Erkek</Radio>
          </RadioFormControl>

          <RadioFormControl
            control={control}
            errors={errors}
            name="maritalStatus"
            label="Medeni Durumunuz"
          >
            <Radio value="single">Bekar</Radio>
            <Radio value="married">Evli</Radio>
          </RadioFormControl>
        </Stack>

        <Stack direction={{ base: "column", md: "row" }} spacing="24px">
          <StandardFormControl
            errors={errors}
            name="education"
            label="Eğitim Durumunuz"
          >
            <SelectWrapper
              register={{
                ...register("education", { required: "Gerekli alan" }),
              }}
              data={[
                { value: "İlkokul", text: "İlkokul" },
                { value: "Ortaokul", text: "Ortaokul" },
                { value: "Lise", text: "Lise" },
                { value: "Üniversite", text: "Üniversite" },
                { value: "Yüksek Lisans", text: "Yüksek Lisans" },
                { value: "Doktora", text: "Doktora" },
              ]}
            />
          </StandardFormControl>

          <StandardFormControl
            errors={errors}
            name="occupation"
            label="Mesleğiniz"
          >
            <Input
              id={"occupation"}
              {...register("occupation", { required: "Gerekli alan" })}
            />
          </StandardFormControl>
        </Stack>

        <Stack direction={{ base: "column", md: "row" }} spacing="24px">
          <RadioFormControl
            control={control}
            errors={errors}
            name="hasPassport"
            label="Pasaportunuz var mı?"
          >
            <Radio value={"true"}>Evet</Radio>
            <Radio value={"false"}>Hayır</Radio>
          </RadioFormControl>

          <StandardFormControl
            errors={errors}
            name="ableToTravel"
            label="Seyahat Durumunuz"
          >
            <SelectWrapper
              register={{
                ...register("ableToTravel", { required: "Gerekli alan" }),
              }}
              data={[
                { value: "no", text: "Seyahat edemem" },
                { value: "domestic", text: "Yurtiçi" },
                { value: "international", text: "Yurtdışı" },
              ]}
            />
          </StandardFormControl>
        </Stack>

        <Stack direction={{ base: "column", md: "row" }} spacing="24px">
          <RadioFormControl
            control={control}
            errors={errors}
            name="hasDriversLicense"
            label="Ehliyetiniz var mı?"
          >
            <Radio value={"true"}>Evet</Radio>
            <Radio value={"false"}>Hayır</Radio>
          </RadioFormControl>

          <RadioFormControl
            control={control}
            errors={errors}
            name="hasCar"
            label="Arabanız var mı?"
          >
            <Radio value={"true"}>Evet</Radio>
            <Radio value={"false"}>Hayır</Radio>
          </RadioFormControl>
        </Stack>

        <Stack direction={{ base: "column", md: "row" }} spacing="24px">
          <Box flexGrow={0.9}>
            <RadioFormControl
              control={control}
              errors={errors}
              name="hasKids"
              label="Çocuğunuz var mı?"
            >
              <Radio value={"true"}>Evet</Radio>
              <Radio value={"false"}>Hayır</Radio>
            </RadioFormControl>
          </Box>

          <Box flexGrow={1}>
            <LanguageMultiSelect name="foreignLanguages" control={control} />
          </Box>
        </Stack>

        <StandardFormControl
          errors={errors}
          name="previousMsExperience"
          label="Daha önce hiç anket veya gizli müşteri ziyareti yaptınız mı?
              Yaptınızsa hangi firma ve projelerde?"
        >
          <Textarea
            variant={"filled"}
            id="previousMsExperience"
            {...register(
              "previousMsExperience"
              //  { required: "Gerekli alan", }
            )}
          />
        </StandardFormControl>

        <FormFooter>
          <Button colorScheme={"pink"} isLoading={isSubmitting} type="submit">
            Gönder
          </Button>
        </FormFooter>
      </FormContainer>
    </form>
  );
}
