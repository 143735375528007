class ProjectBreakdownApi {
  constructor(fetcher) {
    this.fetcher = fetcher;
  }

  async createOneForProject(projectId, data) {
    const res = await this.fetcher.post(
      `projects/${projectId}/project-breakdowns`,
      data
    );
    return res.data.data;
  }

  async updateOne(id, data) {
    const res = await this.fetcher.put(`project-breakdowns/${id}`, data);
    return res.data.data;
  }

  async deleteOne(id) {
    const res = await this.fetcher.delete(`project-breakdowns/${id}`);
    return res.data.data;
  }
}

export default ProjectBreakdownApi;
