import {
  Button,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogBody,
} from "@chakra-ui/react";
import { useState } from "react";
import { useUiContext } from "../../contexts/UiContext";

export default function DeleteConfirmAlert({ name, onDelete }) {
  const [isDeleting, setIsDeleting] = useState(false);
  const { closeAlertBox } = useUiContext();

  async function handleDelete() {
    setIsDeleting(true);
    await onDelete();
    setIsDeleting(false);
    closeAlertBox();
  }

  return (
    <AlertDialogContent>
      <AlertDialogHeader fontSize="lg" fontWeight="bold">
        {name} sil?
      </AlertDialogHeader>

      <AlertDialogBody>Emin misiniz? Bu işlem geri alınamaz!</AlertDialogBody>

      <AlertDialogFooter>
        <Button onClick={closeAlertBox} isDisabled={isDeleting}>
          Vazgeç
        </Button>
        <Button
          colorScheme="red"
          onClick={handleDelete}
          ml={3}
          isLoading={isDeleting}
        >
          Sil
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  );
}
