import { Box, Flex, VStack } from "@chakra-ui/react";
import Card from "../../../../components/Layout/Card";
import ItemDetail from "../../../../components/ItemDetail";
import { format } from "date-fns";

export function ClientVisitInfo({ visit, projectBreakdowns }) {
  const {
    project,
    location,
    visitDate,
    // plannedStartDate,
    // plannedEndDate,
    additionalBreakdowns,
  } = visit;

  if (!projectBreakdowns) {
    return null;
  }

  return (
    <Card p={4} mt={2}>
      <VStack spacing={4} align={"start"}>
        <Flex width={"100%"} justify={"space-between"}>
          <ItemDetail
            medium
            width={"350px"}
            px={2}
            label="Proje"
            value={project?.name || "---"}
          />
          <ItemDetail
            medium
            width={"350px"}
            px={2}
            label="Lokasyon"
            value={location.name}
          />
          <ItemDetail
            medium
            width={"350px"}
            px={2}
            label="Ziyaret Tarihi"
            value={
              visitDate ? format(new Date(visitDate), "dd-MM-yyyy") : "---"
            }
          />
        </Flex>

        <AdditionalBreakdowns
          projectBreakdowns={projectBreakdowns}
          additionalBreakdowns={additionalBreakdowns}
        />
      </VStack>
    </Card>
  );
}

function AdditionalBreakdowns({ projectBreakdowns, additionalBreakdowns }) {
  const numberOfRows = Math.ceil(projectBreakdowns.length / 3);
  const nestedProjectBreakdowns = [];
  for (let i = 0; i < numberOfRows; i++) {
    // for out of range breakdown => undefined
    nestedProjectBreakdowns.push([
      projectBreakdowns[i * 3],
      projectBreakdowns[i * 3 + 1],
      projectBreakdowns[i * 3 + 2],
    ]);
  }

  const selectedOptions = additionalBreakdowns.map(
    (breakdown) => breakdown.selectedOption
  );

  return nestedProjectBreakdowns.map((row, i) => (
    <Flex key={i} width={"100%"} justify={"space-between"}>
      {row.map((breakdown, j) => {
        if (!breakdown) {
          return <Box key={j} width={"350px"} px={2} />;
        }
        return (
          <ItemDetail
            key={breakdown._id}
            medium
            width={"350px"}
            px={2}
            label={breakdown.name}
            value={
              breakdown.options.find((option) =>
                selectedOptions.includes(option._id)
              )?.optionText ?? "---"
            }
          />
        );
      })}
    </Flex>
  ));
}
