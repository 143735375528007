import { Route } from "react-router-dom";
import {
  CompanyIndexPage,
  CompanyUpsertContainer,
  CompanyDetailPage,
  PersonnelListComp,
  LocationListComp,
} from "../pages/crm/company";
import { SectorIndexPage, SectorUpsertContainer } from "../pages/crm/sector";

export const crmRoutes = (
  <Route path="crm">
    <Route index element={<CompanyIndexPage />} />
    <Route path="sectors">
      <Route index element={<SectorIndexPage />} />
      <Route path="new" element={<SectorUpsertContainer />} />
      <Route path=":id/edit" element={<SectorUpsertContainer />} />
    </Route>
    <Route path="companies">
      <Route index element={<CompanyIndexPage />} />
      <Route path="new" element={<CompanyUpsertContainer />} />
      <Route path=":id/edit" element={<CompanyUpsertContainer />} />
      <Route path=":id" element={<CompanyDetailPage />}>
        <Route index element={<PersonnelListComp />} />
        <Route path="personnel" element={<PersonnelListComp />} />
        <Route path="locations" element={<LocationListComp />} />
      </Route>
    </Route>
  </Route>
);
