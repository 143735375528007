export const visitStatus = {
  pending: {
    tr: "Taslak",
    en: "Draft",
  },
  ms_confirmed: {
    tr: "GM Onaylı",
    en: "MS Confirmed",
  },
  waiting_approval_admin: {
    tr: "Yönetici Onayı Bekliyor",
    en: "Waiting Approval",
  },
  waiting_approval_fm: {
    tr: "SÇM Onayı Bekliyor",
    en: "Waiting Approval",
  },
  fm_approved: {
    tr: "SÇM Onaylandı",
    en: "Approved by FM",
  },
  fm_rejected: {
    tr: "SÇM Reddedildi",
    en: "Rejected by FM",
  },
  approved: {
    tr: "Onaylandı",
    en: "Approved",
  },
  rejected: {
    tr: "Reddedildi",
    en: "Rejected",
  },
  published: {
    tr: "Yayınlandı",
    en: "Published",
  },
};

export const projectStatus = {
  draft: {
    tr: "Taslak",
    en: "Draft",
  },
  inProgress: {
    tr: "Devam Etmekte",
    en: "In Progress",
  },
  completed: {
    tr: "Tamamlanmış",
    en: "Completed",
  },
};

export const countryNames = {
  TR: { tr: "Türkiye", en: "Turkey" },
  AZ: { tr: "Azerbaycan", en: "Azerbaijan" },
  GE: { tr: "Gürcistan", en: "Georgia" },
  GR: { tr: "Yunanistan", en: "Greece" },
  DE: { tr: "Almanya", en: "Germany" },
  NL: { tr: "Hollanda", en: "Netherlands" },
};
