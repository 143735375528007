import { Outlet } from "react-router-dom";
import NavBar from "../components/NavBar";
import { useUiContext } from "../contexts/UiContext";

function Application() {
  const { alertBox, drawer, bigModal } = useUiContext();
  return (
    <>
      {alertBox()}
      {drawer()}
      {bigModal()}
      <NavBar />
      <Outlet />
    </>
  );
}

export default Application;
