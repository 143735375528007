import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react";
import { FiPlus, FiMoreHorizontal } from "react-icons/fi";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import PageHeader from "../../../components/Layout/PageHeader";
import PageLayout from "../../../components/Layout/PageLayout";
import ClientTable from "../../../components/Table/ClientTable";
import { useApi } from "../../../contexts/ApiContext";
import { useUiContext } from "../../../contexts/UiContext";
import DeleteConfirmAlert from "../../../components/Alert/DeleteConfirmAlert";
import LoadingIndicator from "../../../components/LoadingIndicator";
import Card from "../../../components/Layout/Card";

export function SectorIndexPage() {
  const navigate = useNavigate();
  const toast = useToast();
  const { sectorApi } = useApi();
  const { data: sectors, mutate } = useSWR("/sectors");
  const { showAlert, closeAlertBox } = useUiContext();

  const columns = useMemo(
    () => [
      {
        Header: "Sektör Adı",
        accessor: "name",
      },
      {
        Header: "",
        accessor: "_id",
        isNumeric: true,
        Cell: ({ cell }) => (
          <Menu>
            <MenuButton
              variant="ghost"
              size="sm"
              as={IconButton}
              icon={<FiMoreHorizontal />}
            />
            <MenuList>
              <MenuItem
                onClick={() => {
                  navigate(`${cell.row.values._id}/edit`);
                }}
              >
                Düzenle
              </MenuItem>
              <MenuItem
                onClick={() => {
                  showAlert(
                    <DeleteConfirmAlert
                      name={"Sector"}
                      onDelete={() => deleteRow(cell.row.values._id)}
                    />
                  );
                }}
              >
                Sil
              </MenuItem>
            </MenuList>
          </Menu>
        ),
      },
    ],
    []
  );

  const deleteRow = async (id) => {
    try {
      await sectorApi.deleteOne(id);
      mutate();
      toast({
        title: "Başarılı",
        description: "Firma silindi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <PageLayout>
      <PageHeader title="Sektörler">
        <Button
          leftIcon={<FiPlus />}
          size="sm"
          colorScheme="pink"
          onClick={() => navigate("new")}
        >
          Yeni Ekle
        </Button>
      </PageHeader>
      <Card topMargin>
        {!sectors ? (
          <LoadingIndicator />
        ) : (
          <ClientTable data={sectors} columns={columns} />
        )}
      </Card>
    </PageLayout>
  );
}
