import { Skeleton, Stack } from "@chakra-ui/react";

export default function LoadingIndicator() {
  return (
    <>
      <Stack marginTop={4}>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    </>
  );
}
