import { Box, Flex, Tag, TagLabel, Text, VStack } from "@chakra-ui/react";
import Card from "../../../../components/Layout/Card";
import ItemDetail from "../../../../components/ItemDetail";
import { format } from "date-fns";

export function VisitInfo({ visit, projectBreakdowns, projectGoals }) {
  const {
    status,
    // project,
    location,
    shopper,
    fieldManager,
    visitDate,
    plannedStartDate,
    plannedEndDate,
    scenario,
    latestRejectionReason,
    additionalBreakdowns,
    visitGoals,
    msVisitScore,
    msVisitScoreComment,
  } = visit;

  if (!projectBreakdowns || !projectGoals) {
    return null;
  }
  const showRejectionReason =
    latestRejectionReason?.body &&
    status !== "approved" &&
    status !== "published" &&
    status !== "fm_approved";

  return (
    <Box my={2}>
      {showRejectionReason && (
        <Card
          p={4}
          mt={4}
          borderWidth={2}
          borderColor={"red"}
          borderStyle={"dashed"}
        >
          <VStack align={"stretch"} px={2} spacing={1}>
            <Text fontSize={"sm"} fontWeight={"bold"}>
              Reddedilme Sebebi
            </Text>
            <Text fontSize={"md"}>{`${latestRejectionReason?.body}`}</Text>
            <Text
              as={"i"}
              fontSize={"md"}
              color={"gray.600"}
            >{`${latestRejectionReason?.author.name}`}</Text>
          </VStack>
        </Card>
      )}

      <Card p={4} pb={2} mt={showRejectionReason && 4}>
        <Flex width={"100%"} justify={"space-between"}>
          <Box width={"1050px"} px={2}>
            <Text fontSize={"sm"} fontWeight={"bold"} mb={2}>
              Proje Amacı
            </Text>
            <GoalTags visitGoals={visitGoals} projectGoals={projectGoals} />
          </Box>
        </Flex>
      </Card>

      <Card p={4} mt={4}>
        <VStack spacing={4} align={"start"}>
          <Flex width={"100%"} justify={"space-between"}>
            {/* <ItemDetail
              medium
              width={"350px"}
              px={2}
              label="Proje"
              value={project?.name || "---"}
            /> */}
            <ItemDetail
              medium
              width={"350px"}
              px={2}
              label="Lokasyon"
              value={location.name}
            />
            <ItemDetail
              medium
              width={"350px"}
              px={2}
              label="Saha Çalışanı"
              value={fieldManager?.name || "---"}
            />
            <ItemDetail
              medium
              width={"350px"}
              px={2}
              label="Gizli Müşteri"
              value={shopper?.name || "---"}
            />
          </Flex>

          <Flex width={"100%"} justify={"space-between"}>
            <ItemDetail
              medium
              width={"350px"}
              px={2}
              label="Planlanan Başlangıç Tarihi"
              value={
                plannedStartDate
                  ? format(new Date(plannedStartDate), "dd-MM-yyyy")
                  : "---"
              }
            />
            <ItemDetail
              medium
              width={"350px"}
              px={2}
              label="Planlanan Bitiş Tarihi"
              value={
                plannedEndDate
                  ? format(new Date(plannedEndDate), "dd-MM-yyyy")
                  : "---"
              }
            />
            <ItemDetail
              medium
              width={"350px"}
              px={2}
              label="Ziyaret Tarihi"
              value={
                visitDate ? format(new Date(visitDate), "dd-MM-yyyy") : "---"
              }
            />
          </Flex>

          <AdditionalBreakdowns
            projectBreakdowns={projectBreakdowns}
            additionalBreakdowns={additionalBreakdowns}
          />
        </VStack>
      </Card>
      <Card p={4} mt={4}>
        <Flex width={"100%"}>
          <ItemDetail
            medium
            width={"1050px"}
            px={2}
            label="Ziyaret Senaryosu"
            value={scenario || "---"}
          />
        </Flex>
      </Card>
      {msVisitScore && (
        <Card p={4} mt={4}>
          <VStack align={"stretch"} px={2} spacing={1}>
            <Text fontSize={"sm"} fontWeight={"bold"}>
              Gizli Müşteri Ziyaret Puanı
            </Text>
            {/* <Text fontSize={"md"}>{`Puan: ${msVisitScore}`}</Text> */}
            <Text fontSize={"md"}>
              Puan:{" "}
              <Box as="span" color={"pink.700"} fontWeight={"bold"}>
                {msVisitScore}
              </Box>
            </Text>

            <Text fontSize={"md"}>{`Yorum: ${
              msVisitScoreComment.body || "---"
            }`}</Text>
            <Text
              as={"i"}
              fontSize={"md"}
              color={"gray.600"}
            >{`${msVisitScoreComment.author.name}`}</Text>
          </VStack>
        </Card>
      )}
    </Box>
  );
}

function AdditionalBreakdowns({ projectBreakdowns, additionalBreakdowns }) {
  const numberOfRows = Math.ceil(projectBreakdowns.length / 3);
  const nestedProjectBreakdowns = [];
  for (let i = 0; i < numberOfRows; i++) {
    // for out of range breakdown => undefined
    nestedProjectBreakdowns.push([
      projectBreakdowns[i * 3],
      projectBreakdowns[i * 3 + 1],
      projectBreakdowns[i * 3 + 2],
    ]);
  }

  const selectedOptions = additionalBreakdowns.map(
    (breakdown) => breakdown.selectedOption
  );

  return nestedProjectBreakdowns.map((row, i) => (
    <Flex key={i} width={"100%"} justify={"space-between"}>
      {row.map((breakdown, j) => {
        if (!breakdown) {
          return <Box key={j} width={"350px"} px={2} />;
        }
        return (
          <ItemDetail
            key={breakdown._id}
            medium
            width={"350px"}
            px={2}
            label={breakdown.name}
            value={
              breakdown.options.find((option) =>
                selectedOptions.includes(option._id)
              )?.optionText ?? "---"
            }
          />
        );
      })}
    </Flex>
  ));
}

export function GoalTags({ visitGoals, projectGoals }) {
  const projectGoalsObject = projectGoals?.reduce((acc, goal) => {
    const { _id, name } = goal;
    return {
      [_id]: name,
      ...acc,
    };
  }, {});

  if (!visitGoals?.length) {
    return "---";
  }

  return visitGoals.map((goal) => (
    <Tag
      key={goal}
      size={"md"}
      borderRadius="full"
      variant="outline"
      colorScheme="pink"
      mr={2}
      mb={2}
    >
      <TagLabel>{projectGoalsObject?.[goal]}</TagLabel>
    </Tag>
  ));
}
