export function getFirstName(fullName) {
  if (typeof fullName !== "string") {
    return "";
  }
  const names = fullName.split(" ");
  if (names.length > 2) {
    return names[1];
  }
  return names[0];
}

export function getRoleLabel(role) {
  switch (role) {
    case "admin":
      return "Admin";
    case "fieldMgr":
      return "Saha Çalışanı";
    case "companyMgr":
      return "Şirket Müdürü";
    case "locationMgr":
      return "Şube/Bölge Müdürü";
    case "ms":
      return "Gizli Müşteri";
    default:
      return "";
  }
}

export function getHowDidYouHearLabel(
  howDidYouHearAboutUs,
  howDidYouHearDetail
) {
  const labels = {
    "google-ads": "Google reklamları",
    "social-media": "Sosyal medya",
    tanidik: "Tanıdık aracılığı",
    other: "Diğer",
  };

  if (howDidYouHearAboutUs === "tanidik" || howDidYouHearAboutUs === "other") {
    return (
      labels[howDidYouHearAboutUs] +
      `${howDidYouHearDetail ? ` - ${howDidYouHearDetail}` : ""}`
    );
  } else {
    return labels[howDidYouHearAboutUs];
  }
}

export function getTravelLabel(ableToTravel) {
  const labels = {
    no: "Edemez",
    domestic: "Yurtiçi",
    international: "Yurtdışı",
  };
  return ableToTravel ? labels[ableToTravel] : "";
}

export function getMaritalStatusLabel(maritalStatus) {
  const label =
    maritalStatus === "single"
      ? "Bekar"
      : maritalStatus === "married"
      ? "Evli"
      : "";
  return label;
}

export function getBooleanLabel(value) {
  const label = value === true ? "Var" : value === false ? "Yok" : "";
  return label;
}

export function getQuestionTypeLabel(questionType) {
  if (typeof questionType !== "string") {
    return "";
  }
  const labels = {
    singleSelect: "Tek Seçimli",
    multiSelect: "Çok Seçimli",
    freeText: "Serbest Metin",
  };
  return labels[questionType] ?? "";
}

export function formatHtmlWithChakra(html) {
  // Heading lg - h2
  // font-size: var(--chakra-fontSizes-3xl);
  // font-family: var(--chakra-fonts-heading);
  // font-weight: var(--chakra-fontWeights-bold);
  // line-height: 1.2;

  // Heading md - h2
  // font-size: var(--chakra-fontSizes-xl);
  // font-family: var(--chakra-fonts-heading);
  // font-weight: var(--chakra-fontWeights-bold);
  // line-height: 1.2;

  // Heading sm - h2
  // font-size: var(--chakra-fontSizes-md);
  // font-family: var(--chakra-fonts-heading);
  // font-weight: var(--chakra-fontWeights-bold);
  // line-height: 1.2;

  // Unordered List
  // list-style-type: initial;
  // margin-inline-start: 1em;

  // Ordered List
  // list-style-type: decimal;
  // margin-inline-start: 1em;
  return html
    .replaceAll(
      "<h1>",
      "<h2 style='font-size: var(--chakra-fontSizes-3xl);font-family: var(--chakra-fonts-heading);font-weight: var(--chakra-fontWeights-bold);line-height: 1.2;'>"
    ) // heading lg
    .replaceAll(
      "<h2>",
      "<h2 style='font-size: var(--chakra-fontSizes-xl);font-family: var(--chakra-fonts-heading);font-weight: var(--chakra-fontWeights-bold);line-height: 1.2;'>"
    ) // heading md
    .replaceAll(
      "<h3>",
      "<h2 style='font-size: var(--chakra-fontSizes-md);font-family: var(--chakra-fonts-heading);font-weight: var(--chakra-fontWeights-bold);line-height: 1.2;'>"
    ) // heading sm
    .replaceAll("</h1>", "</h2>")
    .replaceAll("</h3>", "</h2>")
    .replaceAll(
      "<ul>",
      "<ul style='list-style-type: initial;margin-inline-start: 2em;'>"
    ) // ul
    .replaceAll(
      "<ol>",
      "<ol style='list-style-type: decimal;margin-inline-start: 2em;'>"
    ); // ol
}
