import Card from "../../../../components/Layout/Card";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Button,
  Heading,
  Flex,
} from "@chakra-ui/react";
import { FiPrinter } from "react-icons/fi";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import { convertAnswersArrayIntoAnObject } from "../../../accMs/msSurvey/msSurveyContainer";
import { VisitQuestion } from "./visitQuestions";

// TODO: if visit status = pending || ms_confirmed || approved || published, show current visit survey.
// Else show survey form, where admin/fieldMgr can overwrite answers!
export function VisitSurvey({ survey, visitName }) {
  const printDiv = useRef();
  const handlePrint = useReactToPrint({
    content: () => printDiv.current,
    documentTitle: visitName,
  });

  if (!survey) return <LoadingIndicator />;

  const answers = convertAnswersArrayIntoAnObject(survey);
  const questionForm = survey.questionForm;

  return (
    <Box>
      <Flex justify={"end"}>
        <Button
          mb={4}
          size={"sm"}
          colorScheme={"blue"}
          variant={"outline"}
          leftIcon={<FiPrinter />}
          onClick={handlePrint}
        >
          Anketi Yazdır
        </Button>
      </Flex>
      <div ref={printDiv}>
        <>
          <Accordion
            defaultIndex={Array.from(
              { length: questionForm?.length },
              (_, i) => i
            )}
            allowMultiple
          >
            {questionForm?.map((mainCat) => {
              return (
                <Card bg={"gray.200"} mt={8}>
                  <AccordionItem key={mainCat._id} border={0}>
                    
                      <AccordionButton
                        p={5}
                        bg={"gray.300"}
                        borderRadius={"xl"}
                        _expanded={{ bg: "gray.400", color: "white" }}
                      >
                        <Box as="span" flex="1" textAlign="left">
                          <Heading size="md"> {mainCat.name}</Heading>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
               

                    <AccordionPanel pb={2}>
                      {mainCat.questions.map((question, i) => (
                        <VisitQuestion
                          key={question._id}
                          question={question}
                          answer={answers[question._id]}
                          index={i}
                        />
                      ))}
                      {mainCat.subCategories?.map((subCat) => (
                        <Card bg={"gray.100"} mt={4} key={subCat._id}>
                          <Heading size="md" color="gray.600">
                            {subCat.name}
                          </Heading>
                          {subCat.questions.map((question, j) => (
                            <VisitQuestion
                              key={question._id}
                              question={question}
                              answer={answers[question._id]}
                              index={j}
                            />
                          ))}
                        </Card>
                      ))}
                    </AccordionPanel>
                  </AccordionItem>
                </Card>
              );
            })}
          </Accordion>
        </>
      </div>
    </Box>
  );
}
