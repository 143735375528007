import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  DrawerHeader,
  useToast,
} from "@chakra-ui/react";
import PageHeader from "../../../components/Layout/PageHeader";
import { FiPlus, FiMoreHorizontal } from "react-icons/fi";
import { useMemo } from "react";
import ClientTable from "../../../components/Table/ClientTable";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { useApi } from "../../../contexts/ApiContext";
import { useUiContext } from "../../../contexts/UiContext";
import DeleteConfirmAlert from "../../../components/Alert/DeleteConfirmAlert";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { NewPersonnelForm, PersonnelUpdateComp } from "./";
import { getRoleLabel } from "../../../util/stringUtils";
import Card from "../../../components/Layout/Card";

export function PersonnelListComp() {
  const toast = useToast();
  const { id: companyId } = useParams();
  const { companyMgrApi, locationMgrApi } = useApi();
  const { showAlert, closeAlertBox, showDrawer, closeDrawer } = useUiContext();
  const { data: personnel, mutate } = useSWR(
    `/companies/${companyId}/personnel`
  );

  const columns = useMemo(
    () => [
      {
        Header: "AD SOYAD",
        accessor: "name",
      },
      {
        Header: "E-POSTA",
        accessor: "email",
      },
      {
        Header: "TELEFON",
        accessor: "phone",
      },
      {
        Header: "DURUM",
        accessor: (row) => (row.status === "active" ? "aktif" : "pasif"),
      },
      {
        Header: "ROL",
        accessor: "role",
        Cell: ({ cell }) => getRoleLabel(cell.value),
      },
      {
        Header: "LOKASYON",
        accessor: (row) =>
          row.location ? row.location.map((loc) => loc.name).join("/") : "",
      },
      {
        Header: "",
        accessor: "_id",
        isNumeric: true,
        Cell: ({ cell }) => (
          <Menu>
            <MenuButton
              variant="ghost"
              size="sm"
              as={IconButton}
              icon={<FiMoreHorizontal />}
            />
            <MenuList>
              <MenuItem
                onClick={() => {
                  showDrawer(
                    <>
                      <DrawerHeader>
                        Kullanıcı: {cell.row.values.name} Düzenle{" "}
                      </DrawerHeader>
                      <PersonnelUpdateComp
                        id={cell.row.values._id}
                        role={cell.row.values.role}
                        closeDrawer={closeDrawer}
                      />
                    </>
                  );
                }}
              >
                Düzenle
              </MenuItem>
              <MenuItem
                onClick={() => {
                  showAlert(
                    <DeleteConfirmAlert
                      name={"Firmaya ait kullanıcı"}
                      onDelete={() =>
                        deleteRow(cell.row.values._id, cell.row.values.role)
                      }
                    />
                  );
                }}
              >
                Sil
              </MenuItem>
            </MenuList>
          </Menu>
        ),
      },
    ],
    []
  );

  const deleteRow = async (id, role) => {
    try {
      if (role === "locationMgr") {
        await locationMgrApi.deleteOne(id);
      } else {
        await companyMgrApi.deleteOne(id);
      }
      toast({
        title: "Başarılı",
        description: "Firmaya ait olan lokasyon silindi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    mutate();
  };

  return (
    <>
      <Card>
        <PageHeader subheader inBox title={`Kullanıcılar`}>
          <Button
            leftIcon={<FiPlus />}
            size="sm"
            colorScheme="orange"
            onClick={() => {
              showDrawer(
                <>
                  <DrawerHeader>Yeni Kullanıcı Ekle</DrawerHeader>
                  <NewPersonnelForm
                    companyId={companyId}
                    closeDrawer={closeDrawer}
                  />
                </>
              );
            }}
          >
            Yeni Ekle
          </Button>
        </PageHeader>

        {!personnel ? (
          <LoadingIndicator />
        ) : (
          <ClientTable data={personnel} columns={columns} />
        )}
      </Card>
    </>
  );
}
