import MSProfileContainer from "../accMs/msProfile/msProfileContainer";
import UserProfileContainer from "./userProfile/userProfileContainer";
import { useAuth } from "../../contexts/AuthContext";

function UserProfilePage() {
  const { isMS, getCurrentUserId } = useAuth();

  if (isMS()) {
    return <MSProfileContainer id={getCurrentUserId()} />;
  } else {
    return <UserProfileContainer id={getCurrentUserId()} />;
  }
}

export default UserProfilePage;
