import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Image,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useToast,
} from "@chakra-ui/react";
import { CloseIcon, NotAllowedIcon } from "@chakra-ui/icons";
import { useState } from "react";
import useSWR from "swr";
import { RequiredFileInput } from "../../../components/Form";
import { useForm } from "react-hook-form";
import { useUiContext } from "../../../contexts/UiContext";
import { useApi } from "../../../contexts/ApiContext";

export function SurveyImageModal({ visitId }) {
  const IMAGE_SOURCE = "/public/images/visit";
  const { closeModal } = useUiContext();
  const toast = useToast();
  const { fileUploadApi } = useApi();
  const { data: visit, mutate: mutateVisit } = useSWR(`/visits/${visitId}`);
  const [isDeletingImg, setIsDeletingImg] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      await fileUploadApi.uploadImageForVisit(visitId, data);
      mutateVisit();
      toast({
        title: "Başarılı",
        description: "Resim kaydedildi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      // closeModal();
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  async function deleteImage(fileName) {
    setIsDeletingImg(true);
    await fileUploadApi.deleteImageForVisit(visitId, fileName);
    mutateVisit();
    setIsDeletingImg(false);
  }

  return (
    <ModalContent>
      <ModalHeader alignSelf={"center"}>{"Ziyaret Resimleri"} 📷</ModalHeader>
      <ModalBody>
        <Flex wrap={"wrap"} justify={"center"}>
          {!visit?.images || visit?.images.length === 0 ? (
            <Box
              position={"relative"}
              border={"1px"}
              borderColor={"gray.200"}
              borderRadius={"md"}
              padding={1}
              marginBottom={4}
              marginLeft={2}
              marginRight={2}
            >
              <NotAllowedIcon
                boxSize={{ base: "130px", md: "210px" }}
                color={"gray.200"}
              />
            </Box>
          ) : (
            visit?.images.map((src) => (
              <Box
                key={src}
                position={"relative"}
                border={"1px"}
                borderColor={"gray.200"}
                borderRadius={"md"}
                padding={1}
                marginBottom={4}
                marginLeft={2}
                marginRight={2}
              >
                <Image
                  src={`${IMAGE_SOURCE}/${src}`}
                  boxSize={{ base: "130px", md: "210px" }}
                  objectFit={"cover"}
                />
                <IconButton
                  colorScheme={"red"}
                  size={"xs"}
                  isRound={true}
                  variant={"solid"}
                  aria-label="Sil"
                  icon={<CloseIcon fontSize={"xx-small"} />}
                  onClick={() => deleteImage(src)}
                  isLoading={isDeletingImg}
                  position={"absolute"}
                  top={-2}
                  right={-2}
                />
              </Box>
            ))
          )}
        </Flex>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={errors.image}>
            <RequiredFileInput name="image" register={register} />
            <FormErrorMessage>{errors.image?.message}</FormErrorMessage>
          </FormControl>
          <ModalFooter>
            <Button onClick={closeModal}>Vazgeç</Button>
            <Button
              colorScheme="green"
              ml={3}
              isLoading={isSubmitting}
              type="submit"
            >
              Ekle
            </Button>
          </ModalFooter>
        </form>
      </ModalBody>
    </ModalContent>
  );
}
