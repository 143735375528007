import { useApiAuthFetch } from "./FetchContext";
import { SWRConfig } from "swr";

// wrapper for Global SWR config
export function SWRConfigProvider({ children }) {
  const apiAuthFetch = useApiAuthFetch();

  return (
    <SWRConfig
      value={{
        fetcher: (url) => {
          console.log("---  SWRConfigProvider:fetcher:url", url);
          return apiAuthFetch.get(url).then((res) => res.data.data);
        },
        // refreshInterval: 3000,
        shouldRetryOnError: false,
        // errorRetryInterval: 5000,
        // errorRetryCount: 5,
        // onError: (err) => {
        //   throw err;
        //   console.log("SWRConfigProvider:onError", err);
        // },
      }}
    >
      {children}
    </SWRConfig>
  );
}
