import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Stack,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import FormContainer from "../../../components/Form/FormContainer";
import FormFooter from "../../../components/Form/FormFooter";
import StandardFormControl from "../../../components/Form/StandardFormControl";
import {
  CityDistrictCombo,
  CityDistrictMultiSelect,
} from "../../../components/Form";
import { useApi } from "../../../contexts/ApiContext";
import { regexPassword } from "../../../util/regex";

// TODO: phone regex
export function MSContactInfoForm({ ms, mutateMs }) {
  const toast = useToast();
  const { mysteryShopperApi } = useApi();
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name: ms.name,
      email: ms.email,
      password: "",
      phone: ms.phone,
      city: ms.city,
      district: ms.district,
      address: ms.address,
      frequentlyVisitedDistricts: ms.frequentlyVisitedDistricts.map(
        (dist) => `${dist.city}-${dist.district}`
      ),
    },
  });

  const onSubmit = async (data) => {
    try {
      // format data for frequentlyVisitedDistricts
      const serverData = { ...data };
      serverData.frequentlyVisitedDistricts =
        data.frequentlyVisitedDistricts.map((dist) => {
          const city = dist.split("-")[0];
          const district = dist.split("-")[1];
          return { city, district };
        });
      // If password is empty don't send it!
      if (data.password === "") {
        const { password, ...serverDataWithoutPassword } = serverData;
        await mysteryShopperApi.updateOne(ms._id, serverDataWithoutPassword);
        mutateMs();
      } else {
        await mysteryShopperApi.updateOne(ms._id, serverData);
        mutateMs();
      }
      toast({
        title: "Başarılı",
        description: "Bilgileriniz güncellendi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer>
        <Stack direction={{ base: "column", md: "row" }} spacing="24px">
          <StandardFormControl
            errors={errors}
            name="name"
            label={
              <Tooltip
                label="Kimlik belgenizde belirtildiği şekilde yazmanızı rica ederiz.
              Bu bilgi banka ödemelerinde de kullanılacaktır."
                placement="right-end"
              >
                Ad Soyad ℹ️
              </Tooltip>
            }
          >
            <Input
              id={"name"}
              {...register("name", { required: "Gerekli alan" })}
            />
          </StandardFormControl>

          <FormControl>
            <FormLabel htmlFor="email">E-Posta</FormLabel>
            <Input id="email" isDisabled {...register("email")} />
          </FormControl>
        </Stack>

        <Stack direction={{ base: "column", md: "row" }} spacing="24px">
          <StandardFormControl
            errors={errors}
            name="password"
            label={
              <Tooltip
                label="Şifrenizi değiştirmek istemiyorsanız lütfen boş bırakınız."
                placement="right-end"
              >
                Şifre ℹ️
              </Tooltip>
            }
          >
            <Input
              id={"password"}
              type="password"
              {...register("password", {
                validate: function (pass) {
                  if (pass === "" || regexPassword.test(pass)) {
                    return true;
                  }
                  return "En az 8 karakter giriniz ya da boş bırakınız";
                },
              })}
            />
          </StandardFormControl>

          <StandardFormControl errors={errors} name="phone" label="Telefon">
            <Input
              id={"phone"}
              {...register("phone", { required: "Gerekli alan" })}
            />
          </StandardFormControl>
        </Stack>

        <CityDistrictCombo
          control={control}
          setValue={setValue}
          errors={errors}
          register={register}
        />

        <StandardFormControl errors={errors} name="address" label="Adres">
          <Textarea
            variant={"filled"}
            id="address"
            {...register("address", { required: "Gerekli alan" })}
          />
        </StandardFormControl>

        <CityDistrictMultiSelect
          name={"frequentlyVisitedDistricts"}
          control={control}
          rules={{ required: "İl - İlçe ekleyiniz" }}
        />

        <FormFooter>
          <Button colorScheme={"pink"} isLoading={isSubmitting} type="submit">
            Gönder
          </Button>
        </FormFooter>
      </FormContainer>
    </form>
  );
}
