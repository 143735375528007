import {
  Button,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogBody,
  Input,
  useToast,
  VStack,
  Textarea,
} from "@chakra-ui/react";
import StandardFormControl from "../../../components/Form/StandardFormControl";
import { useForm } from "react-hook-form";
import { useApi } from "../../../contexts/ApiContext";

export function VisitScenarioUpsertAlert({
  scenario = {},
  projectId,
  onClose,
  mutateScenarios,
}) {
  const toast = useToast();
  const { visitScenarioApi } = useApi();

  const { id, title, description } = scenario;

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      title: title ?? "",
      description: description ?? "",
    },
  });

  const onSubmit = async (data) => {
    let description = "";
    try {
      if (id) {
        await visitScenarioApi.updateOne(id, data);
        description = "Senaryo başarıyla düzenlendi.";
      } else {
        await visitScenarioApi.createOneForProject(projectId, data);
        description = "Yeni senaryo başarıyla eklendi.";
      }
      toast({
        title: "Başarılı",
        description,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      mutateScenarios();
      onClose();
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <AlertDialogContent>
      <AlertDialogHeader fontSize="lg" fontWeight="bold">
        {id ? "Senaryo Düzenle" : "Senaryo Ekle"}
      </AlertDialogHeader>

      <form onSubmit={handleSubmit(onSubmit)}>
        <AlertDialogBody>
          <VStack spacing={4}>
            <StandardFormControl errors={errors} name="title" label="Senaryo">
              <Input
                id={"title"}
                placeholder="Senaryo"
                {...register("title", { required: "Gerekli alan" })}
              />
            </StandardFormControl>

            <StandardFormControl
              errors={errors}
              name="description"
              label="Açıklama"
            >
              <Textarea
                variant={"filled"}
                id={"description"}
                {...register("description", { required: "Gerekli alan" })}
              />
            </StandardFormControl>
          </VStack>
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button onClick={onClose}>Vazgeç</Button>
          <Button
            colorScheme="pink"
            ml={3}
            isLoading={isSubmitting}
            type="submit"
          >
            Kaydet
          </Button>
        </AlertDialogFooter>
      </form>
    </AlertDialogContent>
  );
}
