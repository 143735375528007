import { useState } from "react";
import { Question } from "./question";

export function QuestionList({ questions, mutateQuestionForm, formQuestions }) {
  // questions are from the same category. formQuestions include all questions in the form.
  const [movingQuestionId, setMovingQuestionId] = useState(null);
  return questions.map((question, index) => {
    const formIndex = formQuestions.findIndex((q) => q._id === question._id);

    const childIndices = {}; // {id: index, ...}
    question.childQuestions?.forEach((child) => {
      const childIndex = formQuestions.findIndex((q) => q._id === child._id);
      childIndices[child._id] = childIndex;
    });

    const parentIndex = formQuestions.findIndex(
      (q) => q._id === question.skipQuestionIf?.parentQuestion
    );

    // list of questions that can be added as children
    const childrenListForSkipCriteria = formQuestions
      .map((q, i) => {
        // include neither child, nor parent questions
        if (
          i > formIndex &&
          !q.skipQuestionIf?.parentQuestion &&
          q.childQuestions?.length === 0
        ) {
          return {
            _id: q._id,
            questionText: `${i + 1}. Soru`,
          };
        } else {
          return null;
        }
      })
      .filter((q) => q);

    return (
      <Question
        key={question._id}
        question={question}
        formIndex={formIndex}
        firstWithinCat={index === 0}
        lastWithinCat={index === questions.length - 1}
        childIndices={childIndices}
        parentIndex={parentIndex}
        childrenListForSkipCriteria={childrenListForSkipCriteria}
        mutateQuestionForm={mutateQuestionForm}
        movingQuestionId={movingQuestionId}
        setMovingQuestionId={setMovingQuestionId}
      />
    );
  });
}
