import { Box, Button, Stack, Text, Textarea } from "@chakra-ui/react";
import { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useUiContext } from "../../contexts/UiContext";
import { useApi } from "../../contexts/ApiContext";
import DeleteConfirmAlert from "../Alert/DeleteConfirmAlert";
import { FiX, FiEdit3 } from "react-icons/fi";
import { EditCommentForm } from "./editCommentForm";

export function CommentCard({ comment, mutateComments, ...rest }) {
  const { getCurrentUserId } = useAuth();
  const { showAlert, closeAlertBox } = useUiContext();
  const { commentApi } = useApi();
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  async function deleteComment(id) {
    setIsDeleting(true);
    await commentApi.deleteOne(id);
    mutateComments();
    setIsDeleting(false);
  }

  return (
    <Box
      p={4}
      shadow="sm"
      borderWidth="1px"
      borderRadius="md"
      backgroundColor={"white"}
      {...rest}
    >
      {isEditing ? (
        <EditCommentForm
          comment={comment}
          mutateComments={mutateComments}
          setIsEditing={setIsEditing}
        />
      ) : (
        <Stack direction={"column"} spacing={4}>
          <Stack direction={"row"} spacing={8} justifyContent={"space-between"}>
            <Text>{comment.body}</Text>
            <Text as="i" flexShrink={0}>
              {comment.author.name}
            </Text>
          </Stack>
          {getCurrentUserId() === comment.author._id && (
            <Stack direction="row" alignSelf={"flex-end"}>
              <Button
                size="sm"
                borderRadius={"xl"}
                colorScheme={"gray"}
                onClick={() => setIsEditing(true)}
              >
                <FiEdit3 />
              </Button>
              <Button
                size="sm"
                borderRadius={"xl"}
                colorScheme={"pink"}
                isLoading={isDeleting}
                onClick={() => {
                  showAlert(
                    <DeleteConfirmAlert
                      name={"Not"}
                      onDelete={() => deleteComment(comment._id)}
                    />
                  );
                }}
              >
                <FiX />
              </Button>
            </Stack>
          )}
        </Stack>
      )}
    </Box>
  );
}
