import useSWR from "swr";
import { Navigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

export function MSDashboard({ id }) {
  const toast = useToast();
  const { data: ms, error } = useSWR(`/mystery-shoppers/${id}`);

  if (error) {
    toast({
      title: "Bir hata oluştu.",
      status: "error",
    });
  }

  if (!ms) {
    return null;
  }

  if (ms?.applicationStatus !== "approved") {
    return <Navigate to="profile" />;
  }

  return <Navigate to="ms/visits" />;
}
