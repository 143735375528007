import { Navigate } from "react-router";
import { useAuth } from "../contexts/AuthContext";
import FmProjectListPage from "./accFm/fmProjectListPage";
import { MSDashboard } from "./accMs/msDashboard";
import { LocationManagerVisitListPage } from "./accLm/lmVisitListPage";

export default function MainPage() {
  const { authState, getCurrentUserId } = useAuth();
  const {
    user: { role },
  } = authState;

  if (role === "admin") {
    return <Navigate to={"pms/projects"} />;
  }
  if (role === "ms") {
    return <MSDashboard id={getCurrentUserId()} />;
  }
  if (role === "fieldMgr") {
    return <FmProjectListPage />;
  }
  if (role === "companyMgr") {
    return <Navigate to={"client"} />;
  }
  if (role === "locationMgr") {
    return <LocationManagerVisitListPage />;
  }
}
