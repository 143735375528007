import {
  Button,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogBody,
  FormControl,
  Input,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useApi } from "../../../contexts/ApiContext";

export default function GoalUpsertAlert({
  goal = {},
  projectId,
  onClose,
  mutateGoals,
}) {
  const toast = useToast();
  const { goalApi } = useApi();

  const { id, name } = goal;

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name: name ?? "",
    },
  });

  const onSubmit = async (data) => {
    let description = "";
    try {
      if (id) {
        await goalApi.updateOne(id, data);
        description = "Proje amacı başarıyla düzenlendi.";
      } else {
        await goalApi.createOneForProject(projectId, data);
        description = "Yeni amaç başarıyla eklendi.";
      }
      toast({
        title: "Başarılı",
        description,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      mutateGoals();
      onClose();
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <AlertDialogContent>
      <AlertDialogHeader fontSize="lg" fontWeight="bold">
        {id ? "Amacı Düzenle" : "Yeni Amaç Ekle"}
      </AlertDialogHeader>

      <form onSubmit={handleSubmit(onSubmit)}>
        <AlertDialogBody>
          <FormControl isInvalid={errors.name}>
            {/* <FormLabel htmlFor="name">Proje Amacı</FormLabel> */}
            <Input
              id="name"
              placeholder="Proje Amacı"
              {...register("name", {
                required: "Gerekli alan",
                minLength: {
                  value: 3,
                  message: "En az 3 karakter gerekli.",
                },
              })}
            />
            <FormErrorMessage>
              {errors.name && errors.name.message}
            </FormErrorMessage>
          </FormControl>
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button onClick={onClose}>Vazgeç</Button>
          <Button
            colorScheme="pink"
            ml={3}
            isLoading={isSubmitting}
            type="submit"
          >
            Kaydet
          </Button>
        </AlertDialogFooter>
      </form>
    </AlertDialogContent>
  );
}
