import { Route, Routes } from "react-router-dom";
import {
  ApiContextProvider,
  AuthContextProvider,
  FetchContextProvider,
  SWRConfigProvider,
  UiContextProvider,
} from "./contexts";
import { RequireAuth, RequireAdmin, RequireRole } from "./components/RBAC";
import {
  crmRoutes,
  pmsRoutes,
  msmRoutes,
  settingsRoutes,
  mysteryShopperRoutes,
  fieldManagerRoutes,
  companyManagerRoutes,
  locationManagerRoutes,
} from "./routes";
import { LoginPage, MysteryShopperSignupPage } from "./pages/auth";
import Application from "./pages/application";
import MainPage from "./pages/main";
import UserProfilePage from "./pages/profile/userProfilePage";
import { NotFound } from "./pages/404";

import "react-datepicker/dist/react-datepicker.css";
import "./components/DatePicker/date-picker.css";
import "./theme/main.css";

function App() {
  return (
    <AuthContextProvider>
      <FetchContextProvider>
        <ApiContextProvider>
          <SWRConfigProvider>
            <UiContextProvider>
              <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route
                  path="/ms/signup"
                  element={<MysteryShopperSignupPage />}
                />
                {/* main application routes - authentication required */}
                <Route
                  path="ina"
                  element={
                    <RequireAuth>
                      <Application />
                    </RequireAuth>
                  }
                >
                  <Route index element={<MainPage />} />
                  <Route path="profile" element={<UserProfilePage />} />
                  {/* Admin Pages */}
                  <Route element={<RequireAdmin />}>
                    {crmRoutes}
                    {pmsRoutes}
                    {msmRoutes}
                    {settingsRoutes}
                  </Route>
                  {/* Mystery-Shopper Pages */}
                  <Route element={<RequireRole requiredRole={"ms"} />}>
                    {mysteryShopperRoutes}
                  </Route>
                  {/* Field-Manager Pages */}
                  <Route element={<RequireRole requiredRole={"fieldMgr"} />}>
                    {fieldManagerRoutes}
                  </Route>
                  {/* Company-Manager Pages */}
                  <Route element={<RequireRole requiredRole={"companyMgr"} />}>
                    {companyManagerRoutes}
                  </Route>
                  {/* Location-Manager Pages */}
                  <Route element={<RequireRole requiredRole={"locationMgr"} />}>
                    {locationManagerRoutes}
                  </Route>
                </Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
            </UiContextProvider>
          </SWRConfigProvider>
        </ApiContextProvider>
      </FetchContextProvider>
    </AuthContextProvider>
  );
}

export default App;
