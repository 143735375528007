import useSWR from "swr";
import PageHeader from "../../components/Layout/PageHeader";
import PageLayout from "../../components/Layout/PageLayout";
import Card from "../../components/Layout/Card";
import { VisitTable } from "../reporting/visitTable";

export function LocationManagerVisitListPage() {
  const { data: visits } = useSWR(`/location-manager/visits`);

  return (
    <PageLayout>
      <Card mt={12}>
        <PageHeader inBox title="Ziyaret Listesi" />
        <VisitTable visits={visits} lm />
      </Card>
    </PageLayout>
  );
}
