import {
  Button,
  FormControl,
  FormLabel,
  Select,
  SimpleGrid,
  Center,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { useController } from "react-hook-form";
import useSWR from "swr";

function DistrictTag({ label, onClick }) {
  return (
    <Tag size="lg" borderRadius="full" variant="outline" colorScheme="pink">
      <TagLabel fontSize={"sm"}>{label}</TagLabel>
      <TagCloseButton onClick={onClick} />
    </Tag>
  );
}

export function CityDistrictMultiSelect({ name, control, rules }) {
  // UI state
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  // value = ["Istanbul-Kadikoy", "Antalya-Demre", ...]
  const {
    field: { value, onChange },
    fieldState: { invalid, error },
  } = useController({ name, control, rules });
  const [selectedDistricts, setSelectedDistricts] = useState(value);

  const { data: cities } = useSWR(`/formdata/cities`);
  const districts = cities?.find((c) => c.name === city)?.districts;

  function handleChangeCity(city) {
    setCity(city);
    setDistrict("");
  }

  function handleChangeDistrict(district) {
    setDistrict(district);
  }

  const newDistrict = `${city}-${district}`;

  function addValue() {
    if (
      !selectedDistricts.includes(newDistrict) &&
      !selectedDistricts.includes(`${city}-Tüm ilçeler`) && // all districts is already added
      city !== "" &&
      district !== ""
    ) {
      if (district === "Tüm ilçeler") {
        // UI update
        setSelectedDistricts((prevState) => {
          return [
            ...prevState.filter((d) => d.split("-")[0] !== city),
            newDistrict,
          ];
        });
        // value update
        onChange([
          ...value.filter((d) => d.split("-")[0] !== city),
          newDistrict,
        ]);
      } else {
        setSelectedDistricts((prevState) => [...prevState, newDistrict]); // UI update
        onChange([...value, newDistrict]); // value update
      }
    }
  }

  function removeValue(district) {
    setSelectedDistricts((prevState) =>
      prevState.filter((d) => d !== district)
    ); // UI update
    onChange(value.filter((d) => d !== district)); // value update
  }

  return (
    <>
      <Stack direction={{ base: "column", md: "row" }} spacing="24px">
        <FormControl isInvalid={invalid}>
          <FormLabel>Sık ziyaret ettiğiniz il</FormLabel>
          {!cities ? (
            // empty select as placeholder
            <Select variant={"filled"} placeholder="Lütfen seçiniz" />
          ) : (
            <Select
              placeholder="Lütfen seçiniz"
              variant={"filled"}
              name="city"
              value={city}
              onChange={(e) => handleChangeCity(e.target.value)}
            >
              {cities.map(({ name }) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </Select>
          )}
        </FormControl>

        <FormControl isInvalid={invalid}>
          <FormLabel>Sık ziyaret ettiğiniz ilçe</FormLabel>
          {!districts ? (
            // empty select as placeholder
            <Select variant={"filled"} placeholder="Lütfen seçiniz" />
          ) : (
            <Select
              placeholder="Lütfen seçiniz"
              variant={"filled"}
              name="district"
              value={district}
              onChange={(e) => handleChangeDistrict(e.target.value)}
            >
              {["Tüm ilçeler", ...districts].map((dist) => (
                <option key={dist} value={dist}>
                  {dist}
                </option>
              ))}
            </Select>
          )}
        </FormControl>
      </Stack>

      <Stack direction="row" spacing="24px">
        <Button size={"sm"} onClick={addValue} colorScheme="green">
          <AddIcon />
        </Button>
        {selectedDistricts.length > 0 && (
          <SimpleGrid
            columns={{ base: "1", md: "3" }}
            spacing="8px"
            border={"2px"}
            borderColor="gray.200"
            borderRadius={"lg"}
            p={4}
          >
            {selectedDistricts.map((dist) => (
              <DistrictTag
                key={dist}
                label={`${dist.split("-")[0].slice(0, 12)}-${
                  dist.split("-")[1]
                }`}
                onClick={() => removeValue(dist)}
              />
            ))}
          </SimpleGrid>
        )}
        <Center color={"red.500"}>{error?.message}</Center>
      </Stack>
    </>
  );
}
