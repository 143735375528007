import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Stack,
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { useWatch } from "react-hook-form";
import SelectWrapper from "./SelectWrapper";

function ControlledDetailField({ setValue, control, errors, register }) {
  const howDidYouHearAboutUs = useWatch({
    control,
    name: "howDidYouHearAboutUs",
  });

  const notInitialRender = useRef(false);
  useEffect(() => {
    // Do not change howDidYouHearDetail to "" at initial render. Keep the default value!
    if (notInitialRender.current) {
      setValue("howDidYouHearDetail", "");
    } else {
      notInitialRender.current = true;
    }
  }, [howDidYouHearAboutUs]);

  const isOtherOrTanidik =
    howDidYouHearAboutUs === "other" || howDidYouHearAboutUs === "tanidik";

  return (
    <FormControl
      isInvalid={errors.howDidYouHearDetail}
      hidden={!isOtherOrTanidik}
    >
      {/* <FormLabel color={!isOtherOrTanidik && "transparent"}> */}
      <FormLabel>
        {howDidYouHearAboutUs === "tanidik"
          ? "Ad Soyad (Tanıdık)"
          : "Belirtiniz (Diğer)"}
      </FormLabel>
      <Input
        isDisabled={!isOtherOrTanidik}
        id={"howDidYouHearDetail"}
        {...register(
          "howDidYouHearDetail"
          // {
          //   required: isOtherOrTanidik && "Gerekli alan",
          // }
        )}
      />
      <FormErrorMessage>{errors.howDidYouHearDetail?.message}</FormErrorMessage>
    </FormControl>
  );
}

export function HowDidYouHear({
  errors,
  register,
  control,
  setValue,
  direction,
}) {
  return (
    <Stack
      direction={direction ? direction : { base: "column", md: "row" }}
      spacing="24px"
    >
      <FormControl isInvalid={errors.howDidYouHearAboutUs}>
        <FormLabel htmlFor={"howDidYouHearAboutUs"}>
          Bizi nereden duydunuz?
        </FormLabel>

        <SelectWrapper
          register={{
            ...register("howDidYouHearAboutUs", {
              required: "Gerekli alan",
            }),
          }}
          data={[
            {
              value: "tanidik",
              text: "Tanıdık aracılığı ile (Belirtiniz)",
            },
            { value: "google-ads", text: "Google reklamlari" },
            { value: "social-media", text: "Sosyal medya" },
            { value: "other", text: "Diğer (Belirtiniz)" },
          ]}
        />

        <FormErrorMessage>
          {errors.howDidYouHearAboutUs?.message}
        </FormErrorMessage>
      </FormControl>

      <ControlledDetailField
        setValue={setValue}
        control={control}
        errors={errors}
        register={register}
      />
    </Stack>
  );
}
