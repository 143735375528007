import { crmCompanyUrl } from "./apiFactory";
const crmCompanyMgrUrl = "/company-managers";

class CompanyMgrApi {
  constructor(fetcher) {
    this.fetcher = fetcher;
  }

  async createOneForCompany(companyId, data) {
    const res = await this.fetcher.post(
      `${crmCompanyUrl}/${companyId}/company-managers`,
      data
    );
    return res.data.data;
  }

  async updateOne(id, data) {
    const res = await this.fetcher.put(`${crmCompanyMgrUrl}/${id}`, data);
    return res.data.data;
  }

  async deleteOne(id) {
    const res = await this.fetcher.delete(`${crmCompanyMgrUrl}/${id}`);
    return res.data.data;
  }
}

export default CompanyMgrApi;
