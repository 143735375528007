import { Box, Heading, Text } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";

const notifications = {
  trainingCompleted: {
    heading: "Başvurunuz Onay Sürecinde",
    text: "İlginiz için teşekkür ederiz. En kısa sürede size geri döneceğiz.",
  },
  denied: {
    heading: "Başvurunuz Reddedilmiştir",
    text: "İlginiz için teşekkür ederiz.",
  },
};

export function MSNotificationPage({ status }) {
  return (
    <Box textAlign="center" py={10} px={6}>
      <InfoIcon boxSize={"50px"} color={"pink.400"} />
      <Heading as="h2" size="xl" mt={6} mb={2}>
        {notifications[status].heading}
      </Heading>
      <Text color={"gray.500"}>{notifications[status].text}</Text>
    </Box>
  );
}
