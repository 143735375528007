import { useEffect, useRef } from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Stack,
  Select,
} from "@chakra-ui/react";
import { useWatch } from "react-hook-form";
import useSWR from "swr";
import SelectWrapper from "./SelectWrapper";

function ControlledDistrictField({
  setValue,
  control,
  errors,
  register,
  cities,
}) {
  const city = useWatch({
    control,
    name: "city",
  });

  const notInitialRender = useRef(false);
  useEffect(() => {
    // Do not change district to "" at initial render. Keep the default value!
    if (notInitialRender.current) {
      setValue("district", "");
    } else {
      notInitialRender.current = true;
    }
  }, [city]);

  const districts = cities?.find((c) => c.name === city)?.districts;

  return (
    <FormControl isInvalid={errors.district}>
      <FormLabel>İlçe</FormLabel>
      {!districts ? (
        // empty select as placeholder
        <Select variant={"filled"} placeholder="Lütfen seçiniz" />
      ) : (
        <SelectWrapper
          register={{
            ...register("district", { required: "Gerekli alan" }),
          }}
          data={districts.map((district) => ({
            value: district,
            text: district,
          }))}
        />
      )}
      <FormErrorMessage>{errors.district?.message}</FormErrorMessage>
    </FormControl>
  );
}

export function CityDistrictCombo({
  control,
  setValue,
  errors,
  register,
  direction,
  cityLabel,
}) {
  const { data: cities } = useSWR(`/formdata/cities`);

  return (
    // <Stack direction={{ base: "column", md: "row" }} spacing="24px">
    <Stack
      direction={direction ? direction : { base: "column", md: "row" }}
      spacing="24px"
    >
      <FormControl isInvalid={errors.city}>
        <FormLabel>{cityLabel ? cityLabel : "Yaşadığınız İl"}</FormLabel>
        {!cities ? (
          // empty select as placeholder
          <Select variant={"filled"} placeholder="Lütfen seçiniz" />
        ) : (
          <SelectWrapper
            register={{
              ...register("city", { required: "Gerekli alan" }),
            }}
            data={cities.map((city) => ({
              value: city.name,
              text: city.name,
            }))}
          />
        )}
        <FormErrorMessage>{errors.city?.message}</FormErrorMessage>
      </FormControl>

      <ControlledDistrictField
        control={control}
        setValue={setValue}
        errors={errors}
        register={register}
        cities={cities}
      />
    </Stack>
  );
}
