import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { FiPlus } from "react-icons/fi";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useApi } from "../../contexts/ApiContext";

export function NewCommentForm({ forMS, forVisit, id, mutateComments }) {
  const toast = useToast();
  const { commentApi } = useApi();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues: {
      body: "",
    },
  });

  const onSubmit = async (data) => {
    try {
      if (forMS) {
        await commentApi.createOneForMs(id, data);
      } else if (forVisit) {
        await commentApi.createOneForVisit(id, data);
      }
      mutateComments();
      // toast({
      //   title: "Başarılı",
      //   description: "Yeni not başarıyla eklendi.",
      //   status: "success",
      //   duration: 3000,
      //   isClosable: true,
      // });
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ body: "" });
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack mt={4} direction="column" align={"flex-start"} spacing={4}>
        <FormControl isInvalid={errors.body}>
          {/* <FormLabel htmlFor="body">Yeni Yorum Ekle</FormLabel> */}
          <Textarea
            shadow={"sm"}
            placeholder={`${
              forMS ? "Gizli müşteri" : "Ziyaret"
            } ile ilgili yeni not...`}
            id="body"
            {...register("body", {
              required: "Gerekli alan",
            })}
          />
          <FormErrorMessage>{errors.body?.message}</FormErrorMessage>
        </FormControl>

        <Button
          leftIcon={<FiPlus />}
          size="sm"
          colorScheme="green"
          isLoading={isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          Not Ekle
        </Button>
      </Stack>
    </form>
  );
}
