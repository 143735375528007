import {
  Button,
  FormControl,
  FormLabel,
  Input,
  DrawerBody,
  DrawerFooter,
  FormErrorMessage,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useSWRConfig } from "swr";
import { useApi } from "../../../contexts/ApiContext";
import FormContainer from "../../../components/Form/FormContainer";
import { CityDistrictCombo } from "../../../components/Form";
import SelectWrapper from "../../../components/Form/SelectWrapper";
import { countryNames } from "../../../util/enums";
const COUNTRIES = ["TR", "AZ", "GE", "GR", "DE", "NL"];

// TODO: phone regex
export default function LocationUpsertForm({
  companyId,
  closeDrawer,
  location = {},
  mutateLocation,
}) {
  const toast = useToast();
  const { locationApi } = useApi();
  const { mutate } = useSWRConfig();

  const {
    _id: id = "",
    name = "",
    phone = "",
    // latitude = "",
    // longtitude = "",
    // mapLink = "",
    country = "TR",
    city = "",
    district = "",
    // streetAddress="",
    addressDescription = "",
  } = location;

  const {
    handleSubmit,
    register,
    setValue,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name,
      phone,
      // latitude,
      // longtitude,
      // mapLink,
      country,
      city,
      district,
      // streetAddress,
      addressDescription,
    },
  });

  let toastDescription = "";
  const onSubmit = async (data) => {
    try {
      // non-empty object -> update location
      if (Object.keys(location).length !== 0) {
        await locationApi.updateOne(id, data);
        mutate(`/companies/${companyId}/locations`);
        mutateLocation();
        toastDescription = "Lokasyon düzenlendi.";
      } else {
        await locationApi.createOneForCompany(companyId, data);
        mutate(`/companies/${companyId}/locations`);
        toastDescription = "Yeni lokasyon başarıyla eklendi.";
      }
      toast({
        title: "Başarılı",
        description: toastDescription,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    closeDrawer();
  };

  const countryWatch = watch("country");
  const notInitialRender = useRef(false);
  useEffect(() => {
    if (notInitialRender.current) {
      setValue("city", "");
      setValue("district", "");
    } else {
      notInitialRender.current = true;
    }
  }, [countryWatch]);

  return (
    <>
      <DrawerBody>
        <FormContainer p={2}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction={["column"]} spacing="24px">
              <FormControl isInvalid={errors.name}>
                <FormLabel htmlFor="name">Lokasyon</FormLabel>
                <Input
                  id="name"
                  placeholder="Lokasyon Adı"
                  {...register("name", {
                    required: "Gerekli alan",
                    minLength: {
                      value: 4,
                      message: "En az 4 karakter gerekli.",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.phone}>
                <FormLabel htmlFor="phone">Telefon</FormLabel>
                <Input
                  id="phone"
                  placeholder="Telefon"
                  {...register("phone")}
                />
                <FormErrorMessage>
                  {errors.phone && errors.phone.message}
                </FormErrorMessage>
              </FormControl>
              {/* <FormControl isInvalid={errors.latitude}>
                <FormLabel htmlFor="latitude">Latitude</FormLabel>
                <Input
                  id="latitude"
                  placeholder="Latitude"
                  {...register("latitude")}
                />
                <FormErrorMessage>
                  {errors.latitude && errors.latitude.message}
                </FormErrorMessage>
              </FormControl> */}
              {/* <FormControl isInvalid={errors.longtitude}>
                <FormLabel htmlFor="longtitude">Longtitude</FormLabel>
                <Input
                  id="longtitude"
                  placeholder="longtitude"
                  {...register("longtitude")}
                />
                <FormErrorMessage>
                  {errors.longtitude && errors.longtitude.message}
                </FormErrorMessage>
              </FormControl> */}
              {/* <FormControl isInvalid={errors.mapLink}>
                <FormLabel htmlFor="mapLink">Harita Linki</FormLabel>
                <Input
                  id="mapLink"
                  placeholder="mapLink"
                  {...register("mapLink")}
                />
                <FormErrorMessage>
                  {errors.mapLink && errors.mapLink.message}
                </FormErrorMessage>
              </FormControl> */}

              <FormControl isInvalid={errors.country}>
                <FormLabel>Ülke</FormLabel>
                <SelectWrapper
                  register={{
                    ...register("country", { required: "Gerekli alan" }),
                  }}
                  data={COUNTRIES.map((country) => ({
                    value: country,
                    text: countryNames[country].tr,
                  }))}
                />
                <FormErrorMessage>{errors.country?.message}</FormErrorMessage>
              </FormControl>

              {countryWatch === "TR" ? (
                <CityDistrictCombo
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  register={register}
                  direction={"column"}
                  cityLabel="İl"
                />
              ) : (
                <>
                  <FormControl isInvalid={errors.city}>
                    <FormLabel htmlFor="city">İl</FormLabel>
                    <Input
                      id="city"
                      placeholder="İl"
                      {...register("city", { required: "Gerekli alan" })}
                    />
                    <FormErrorMessage>
                      {errors.city && errors.city.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.district}>
                    <FormLabel htmlFor="district">İlçe</FormLabel>
                    <Input
                      id="district"
                      placeholder="İlçe"
                      {...register("district", { required: "Gerekli alan" })}
                    />
                    <FormErrorMessage>
                      {errors.district && errors.district.message}
                    </FormErrorMessage>
                  </FormControl>
                </>
              )}

              <FormControl isInvalid={errors.addressDescription}>
                <FormLabel htmlFor="addressDescription">
                  Adres Açıklaması
                </FormLabel>
                <Input
                  id="addressDescription"
                  placeholder="Adres detayı"
                  {...register("addressDescription")}
                />
                <FormErrorMessage>
                  {errors.addressDescription &&
                    errors.addressDescription.message}
                </FormErrorMessage>
              </FormControl>
            </Stack>
          </form>
        </FormContainer>
      </DrawerBody>
      <DrawerFooter>
        <Button variant="outline" mr={3} onClick={closeDrawer}>
          Vazgeç
        </Button>
        <Button
          colorScheme={"pink"}
          isLoading={isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          Kaydet
        </Button>
      </DrawerFooter>
    </>
  );
}
