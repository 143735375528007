import { Outlet, useParams } from "react-router-dom";
import useSWR from "swr";
import {
  FiBarChart,
  FiDownloadCloud,
  FiMessageCircle,
  FiMapPin,
  FiPieChart,
  FiLayers,
  FiMonitor,
  FiUsers,
} from "react-icons/fi";
import SplitPageWithSideNav from "../../components/SplitPageWithSideNav";

export function ClientProjectDetail() {
  //get breakdownlist
  const { projectId } = useParams();
  const { data: project } = useSWR(`/projects/${projectId}`);

  const { data: breakdowns } = useSWR(
    `/projects/${projectId}/project-breakdowns`
  );

  const LinkItems = [
    {
      name: project?.name ?? "Proje Anasayfa",
      icon: FiMonitor,
      to: "dashboard",
      divider: true,
    },
    {
      name: "Ziyaretler",
      icon: FiLayers,
      to: `visits`,
      divider: true,
    },
    {
      name: "Isı Haritası",
      icon: FiPieChart,
      to: "heat-map-report",
    },
    {
      name: "Lokasyon Raporları",
      icon: FiMapPin,
      to: "location-based-report",
    },
    {
      name: "Hizmet Kırılım Raporu",
      icon: FiPieChart,
      to: "category-based-report",
    },
  ];

  breakdowns?.map((breakdown) => {
    LinkItems.push({
      name: breakdown.name,
      icon: FiBarChart,
      to: `breakdown-based-report/${breakdown._id}`,
    });
  });
  LinkItems.push(
    {
      name: "Personel Raporu",
      icon: FiUsers,
      to: "personnel-report",
    },
    {
      name: "Anektod Raporu",
      icon: FiMessageCircle,
      to: "anecdote-report",
      divider: true,
    },
    {
      name: "Export Data",
      icon: FiDownloadCloud,
      to: "export-report",
    }
  );
  return (
    <SplitPageWithSideNav linkItems={LinkItems}>
      <Outlet />
    </SplitPageWithSideNav>
  );
}
