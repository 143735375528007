import { AlertDialog, AlertDialogOverlay } from "@chakra-ui/react";

export default function AlertBox({ isOpen, onClose, children }) {
  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <AlertDialogOverlay>{children}</AlertDialogOverlay>
    </AlertDialog>
  );
}
