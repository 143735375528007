import { useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import PageHeader from "../../../components/Layout/PageHeader";
import PageLayout from "../../../components/Layout/PageLayout";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { UserUpdateForm, FieldMgrUpdateComp } from "./";
import Card from "../../../components/Layout/Card";
export function UserDetailPage() {
  const params = useParams();
  const { data: user, error, mutate } = useSWR(`/users/${params.id}`);
  const toast = useToast();

  if (error) {
    toast({
      title: "Bir hata oluştu.",
      status: "error",
    });
  }

  return (
    <PageLayout>
      <PageHeader title={"Kullanıcı Düzenle"} backUrl={".."} />
      <Card topMargin>
      {!user ? (
        <LoadingIndicator />
      ) : user.role === "fieldMgr" ? (
        <FieldMgrUpdateComp id={user._id} />
      ) : (
        <UserUpdateForm
          user={user}
          mutateUser={mutate}
          showStatus
          disablePassword
        />
      )}
      </Card>
    </PageLayout>
  );
}
