import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ModalContent,
  ModalHeader,
  useToast,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useSWR from "swr";
import { useApi } from "../../../contexts/ApiContext";
import { useUiContext } from "../../../contexts/UiContext";
import { FiPlus, FiMoreHorizontal } from "react-icons/fi";
import PageHeader from "../../../components/Layout/PageHeader";
import PageLayout from "../../../components/Layout/PageLayout";
import Card from "../../../components/Layout/Card";
import ClientTable from "../../../components/Table/ClientTable";
import DeleteConfirmAlert from "../../../components/Alert/DeleteConfirmAlert";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { Scale } from "../../../components/Scale";
import { PlanUpsertContainer } from "./planUpsertContainer";
import { VisitStatusTag } from "./visitStatusTag";
import { useAuth } from "../../../contexts/AuthContext";

export function PlanIndexPage() {
  const toast = useToast();
  const { showAlert, closeAlertBox, showModal, closeModal } = useUiContext();
  const navigate = useNavigate();
  const { isAdmin, isFieldMgr } = useAuth();
  const { id: projectId } = useParams();
  const { data: visits, mutate } = useSWR(`/projects/${projectId}/visits`);
  const { data: project } = useSWR(`/projects/${projectId}`);
  const { visitApi } = useApi();
  const columns = useMemo(
    () => [
      {
        Header: "ZİYARET",
        accessor: "name",
        Cell: ({ cell }) => (
          <Link to={`${cell.row.original._id}`}>{cell.row.values.name}</Link>
        ),
      },
      {
        Header: "Lokasyon",
        accessor: "location.name",
      },
      {
        Header: "SAHA ÇALIŞANI",
        accessor: "fieldManager.name",
      },
      {
        Header: "GİZLİ MÜŞTERİ",
        accessor: "shopper.name",
      },
      {
        Header: "GM PUANI",
        accessor: "msVisitScore",
        Cell: ({ cell }) => {
          const score = cell.row.values.msVisitScore;
          return score ? <Scale value={score} /> : null;
        },
      },
      {
        Header: "Durum",
        accessor: "status",
        Cell: ({ cell }) => (
          <VisitStatusTag status={cell.row.values.status} size={"sm"} />
        ),
      },
      {
        Header: "",
        accessor: "_id",
        isNumeric: true,
        Cell: ({ cell }) => {
          const { status, _id, name } = cell.row.values;
          return (
            <Menu>
              <MenuButton
                variant="ghost"
                size="sm"
                as={IconButton}
                icon={<FiMoreHorizontal />}
              />
              {(isAdmin() || isFieldMgr()) && status === "pending" ? (
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      showModal(
                        <ModalContent>
                          <ModalHeader>Ziyaret: {name}</ModalHeader>
                          <PlanUpsertContainer
                            id={_id}
                            projectId={projectId}
                            closeModal={closeModal}
                            mutateVisits={mutate}
                          />
                        </ModalContent>
                      );
                    }}
                  >
                    Düzenle
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      showAlert(
                        <DeleteConfirmAlert
                          name={"Proje"}
                          onDelete={() => deleteRow(_id)}
                        />
                      );
                    }}
                  >
                    Sil
                  </MenuItem>
                </MenuList>
              ) : (
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      navigate(`${_id}`);
                    }}
                  >
                    Detay
                  </MenuItem>
                </MenuList>
              )}
            </Menu>
          );
        },
      },
    ],
    []
  );

  const deleteRow = async (id) => {
    try {
      await visitApi.deleteOne(id);
      mutate();
      toast({
        title: "Başarılı",
        description: "Firma silindi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <PageLayout>
      <PageHeader title="Proje Ziyaret Planı" backUrl={isFieldMgr() && "/ina"}>
        {!project?.isArchived && (
          <Button
            leftIcon={<FiPlus />}
            size="sm"
            variant="outline"
            colorScheme="teal"
            onClick={() => {
              showModal(
                <ModalContent>
                  <ModalHeader>Yeni Ziyaret Ekle</ModalHeader>
                  <PlanUpsertContainer
                    projectId={projectId}
                    closeModal={closeModal}
                    mutateVisits={mutate}
                  />
                </ModalContent>
              );
            }}
          >
            Yeni Ekle
          </Button>
        )}
      </PageHeader>
      <Card topMargin>
        {!visits ? (
          <LoadingIndicator />
        ) : (
          <ClientTable
            data={visits}
            columns={columns}
            tableName="pms-project-plan"
          />
        )}
      </Card>
    </PageLayout>
  );
}
