import Api from "./api";
import LocationApi from "./locationApi";
import CompanyMgrApi from "./companyMgrApi";
import LocationMgrApi from "./locationMgrApi";
import MysteryShopperApi from "./mysteryShopperApi";
import VisitApi from "./visitApi";
import CommentApi from "./commentApi";
import FormApi from "./formApi";
import SurveyApi from "./surveyApi";
import PersonnelTypeApi from "./personnelTypeApi";
import GoalApi from "./goalApi";
import ProjectBreakdownApi from "./projectBreakdownApi";
import FileUploadApi from "./fileUploadApi";
import VisitScenarioApi from "./visitScenarioApi";
import ProjectApi from "./projectApi";
import VisitInviteApi from "./visitInviteApi";

export const crmCompanyUrl = "/companies";
const crmSectorUrl = "/sectors";
const adminUrl = "/admins";
const userUrl = "/users";
const fieldMgrUrl = "/field-managers";

export default function apiFactory(fetcher) {
  const sectorApi = new Api(crmSectorUrl, fetcher);
  const companyApi = new Api(crmCompanyUrl, fetcher);
  const adminApi = new Api(adminUrl, fetcher);
  const userApi = new Api(userUrl, fetcher); // userApi does not have createOne endpoint!
  const fieldMgrApi = new Api(fieldMgrUrl, fetcher);

  const locationApi = new LocationApi(fetcher);
  const companyMgrApi = new CompanyMgrApi(fetcher);
  const locationMgrApi = new LocationMgrApi(fetcher);
  const mysteryShopperApi = new MysteryShopperApi(fetcher);
  const visitApi = new VisitApi(fetcher);
  const commentApi = new CommentApi(fetcher);
  const formApi = new FormApi(fetcher);
  const surveyApi = new SurveyApi(fetcher);
  const personnelTypeApi = new PersonnelTypeApi(fetcher);
  const goalApi = new GoalApi(fetcher);
  const fileUploadApi = new FileUploadApi(fetcher);
  const projectBreakdownApi = new ProjectBreakdownApi(fetcher);
  const visitScenarioApi = new VisitScenarioApi(fetcher);
  const projectApi = new ProjectApi(fetcher);
  const visitInviteApi = new VisitInviteApi(fetcher);

  return {
    sectorApi,
    companyApi,
    locationApi,
    companyMgrApi,
    locationMgrApi,
    projectApi,
    mysteryShopperApi,
    adminApi,
    userApi,
    fieldMgrApi,
    visitApi,
    commentApi,
    formApi,
    surveyApi,
    personnelTypeApi,
    goalApi,
    fileUploadApi,
    projectBreakdownApi,
    visitScenarioApi,
    visitInviteApi,
  };
}
