import { Box, Image, Text } from "@chakra-ui/react";
import useSWR from "swr";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import Card from "../../components/Layout/Card";
import { Fragment, useState } from "react";

export  function VisitPerformanceTable({ visitId }) {
  //Burada bu visit icin answer datasini alabiliriz. oradan hesaplanan degeri gosteririz.
  const { data } = useSWR(`/getVisitReport/${visitId}`);
  return (
    <>
      {data && (
        <TableContainer>
          <Table variant="simple" size="lg">
            <Thead>
              <Tr>
                <Th rowSpan={2}>
                  <Image src="/beymen.png" height={33} />
                </Th>
                <Th>Ziyaret Puanı</Th>
                <Th>Lokasyon Ortalaması</Th>
                <Th>Proje Ortalaması</Th>
              </Tr>
              <Tr>
                <Th>
                  %{data.visitPerformance.performance} -{" "}
                  {data.visitPerformance.totalScore} /{" "}
                  {data.visitPerformance.totalMaxScore}
                </Th>
                <Th>
                  %{data.locationPerformance.performance} -{" "}
                  {data.locationPerformance.totalScore} /{" "}
                  {data.locationPerformance.totalMaxScore}
                </Th>
                <Th>
                  %{data.projectPerformance.performance} -{" "}
                  {data.projectPerformance.totalScore} /{" "}
                  {data.projectPerformance.totalMaxScore}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.mainCategoryList.map((mainCat) => {
                const visitCategory = data.visitPerformance.categoryScores.find(
                  (x) => x.category === mainCat._id
                );
                const locationCategory =
                  data.locationPerformance.categoryScores.find(
                    (x) => x.category === mainCat._id
                  );
                const projectCategory =
                  data.projectPerformance.categoryScores.find(
                    (x) => x.category === mainCat._id
                  );
                return (
                  <Fragment key={mainCat._id}>
                    <Tr bgColor={"gray.100"} fontWeight={900} color={"gray.900"} height={"28"} fontSize={"xl"}>
                      <Td>{mainCat.name}</Td>
                      <Td>{reportTableCell(visitCategory)}</Td>
                      <Td>{reportTableCell(locationCategory)}</Td>
                      <Td>{reportTableCell(projectCategory)}</Td>
                    </Tr>
                    {mainCat.subCategories.length > 0 &&
                      mainCat.subCategories.map((subCat) => {
                        const visitSubCategory =
                          data.visitPerformance.categoryScores.find(
                            (x) => x.category === subCat._id
                          );
                        const locationSubCategory =
                          data.locationPerformance.categoryScores.find(
                            (x) => x.category === subCat._id
                          );
                        const projectSubCategory =
                          data.projectPerformance.categoryScores.find(
                            (x) => x.category === subCat._id
                          );
                        return (
                          <Tr key={subCat._id}>
                            <Td>--{subCat.name}</Td>
                            <Td>{reportTableCell(visitSubCategory)}</Td>
                            <Td>{reportTableCell(locationSubCategory)}</Td>
                            <Td>{reportTableCell(projectSubCategory)}</Td>
                          </Tr>
                        );
                      })}
                  </Fragment>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      <Box mt={4}>
        <Card>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Personel Tipi</Th>
                  <Th>Performans</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data &&
                  data.personnelPerformanceData?.map((pRow) => (
                    <Tr key={pRow.personnelType}>
                      <Td>{pRow.personnelType}</Td>
                      <Td>
                        <>
                          <Text fontSize="2xl"> {"%" + pRow.performance} </Text>
                          <Text fontSize="xs">
                            {pRow.totalPersonnelScore +
                              " / " +
                              pRow.totalMaxPersonnelScore}
                          </Text>
                        </>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    </>
  );
}

const reportTableCell = (data) => {
  return (
    <>
      <Text fontSize="2xl"> {data.maxScore === 0 ? "_": "%"+  data.performance } </Text>
      <Text fontSize="xs">{data.maxScore !=0 && data.score + " / " + data.maxScore}</Text>
    </>
  );
};
