import { Route } from "react-router-dom";
import {
  MSVisitIndexPage,
  MsVisitListPage,
  MsVisitDetailsPage,
  MsVisitFinancialsPage,
  MsVisitInvitesPage,
} from "../pages/accMs/msVisit";
import { MSSurveyContainer } from "../pages/accMs/msSurvey/msSurveyContainer";
import PlayGround from "../PlayGround";

export const mysteryShopperRoutes = (
  <>
    <Route path="ms/visits" element={<MSVisitIndexPage />}>
      <Route index element={<MsVisitListPage status="pending" />} />
      <Route path="invites" element={<MsVisitInvitesPage />} />
      <Route path="pending" element={<MsVisitListPage status="pending" />} />
      <Route path="rejected" element={<MsVisitListPage status="rejected" />} />
      <Route path="approved" element={<MsVisitListPage status="approved" />} />
      <Route
        path="in-progress"
        element={<MsVisitListPage status="inProgress" />}
      />
      <Route path="financials" element={<div>MS Financials Page</div>} />
      <Route path=":id" element={<MsVisitDetailsPage />} />
      <Route path=":id/survey" element={<MSSurveyContainer />} />
      <Route path=":id/financials" element={<MsVisitFinancialsPage />} />
    </Route>
    <Route path="play" element={<PlayGround />} />
  </>
);
