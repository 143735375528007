import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useToast,
  Container,
  SimpleGrid,
  Avatar,
  AvatarGroup,
  IconProps,
  Icon,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { Navigate, useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from "../../contexts/AuthContext";
import { useApi } from "../../contexts/ApiContext";
import { regexEmail, regexPassword } from "../../util/regex";

export function MysteryShopperSignupPage() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const { isAuthenticated } = useAuth();
  const { mysteryShopperApi } = useApi();
  const avatars = [
    {
      name: "Ryan Florence",
      url: "https://bit.ly/ryan-florence",
    },
    {
      name: "Segun Adebayo",
      url: "https://bit.ly/code-beast",
    },
    {
      name: "Kent Dodds",
      url: "https://bit.ly/kent-c-dodds",
    },
    {
      name: "Prosper Otemuyiwa",
      url: "https://bit.ly/prosper-baba",
    },
    {
      name: "Christian Nwamba",
      url: "https://bit.ly/code-beast",
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  async function onSubmit(data) {
    try {
      await mysteryShopperApi.createOne(data);
      toast({
        title: "Başvurunuz alındı.",
        description:
          "Devam etmek için size gönderdiğimiz e-postayı doğrulayınız.",
        status: "success",
        duration: 10000,
      });
      navigate("/");
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        status: "error",
      });
    }
  }

  if (isAuthenticated()) {
    return <Navigate to="/ina" />;
  }

  return (
    <>
      <Box position={"relative"}>
        <Container
          as={SimpleGrid}
          maxW={"7xl"}
          columns={{ base: 1, md: 2 }}
          spacing={{ base: 10, lg: 32 }}
          py={{ base: 10, sm: 20, lg: 20, xl: 56 }}
        >
          <Stack spacing={{ base: 10, md: 20 }}>
            <Heading
              lineHeight={1.1}
              mt={{ base: 10, lg: 20 }}
              fontSize={{ base: "3xl", sm: "4xl", md: "5xl", lg: "6xl" }}
            >
              Gizli müşteri adayları{" "}
              <Text
                as={"span"}
                bgGradient="linear(to-r, red.400,pink.400)"
                bgClip="text"
              >
                &
              </Text>{" "}
              araştırma görevlileri
            </Heading>
            <Stack direction={"row"} spacing={4} align={"center"}>
              <AvatarGroup>
                {avatars.map((avatar) => (
                  <Avatar
                    key={avatar.name}
                    name={avatar.name}
                    src={avatar.url}
                    size={"md"}
                    position={"relative"}
                    zIndex={2}
                    _before={{
                      content: '""',
                      width: "full",
                      height: "full",
                      rounded: "full",
                      transform: "scale(1.125)",
                      bgGradient: "linear(to-bl, red.400,pink.400)",
                      position: "absolute",
                      zIndex: -1,
                      top: 0,
                      left: 0,
                    }}
                  />
                ))}
              </AvatarGroup>
              <Text
                fontFamily={"heading"}
                fontSize={{ base: "4xl", md: "6xl" }}
              >
                +
              </Text>
              <Flex
                align={"center"}
                justify={"center"}
                fontFamily={"heading"}
                fontSize={{ base: "sm", md: "lg" }}
                bg={"gray.800"}
                color={"white"}
                rounded={"full"}
                width={["44px", "60px"]}
                height={["44px", "60px"]}
                position={"relative"}
                _before={{
                  content: '""',
                  width: "full",
                  height: "full",
                  rounded: "full",
                  transform: "scale(1.125)",
                  bgGradient: "linear(to-bl, orange.400,yellow.400)",
                  position: "absolute",
                  zIndex: -1,
                  top: 0,
                  left: 0,
                }}
              >
                SEN
              </Flex>
            </Stack>
          </Stack>
          <Stack
            bg={"gray.200"}
            rounded={"xl"}
            p={{ base: 4, sm: 6, md: 8 }}
            spacing={{ base: 8 }}
            maxW={{ lg: "lg" }}
          >
            <Stack spacing={4}>
              <Heading
                color={"gray.800"}
                lineHeight={1.1}
                fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
              >
                Takımımıza katılın{" "}
                <Text
                  as={"span"}
                  bgGradient="linear(to-r, red.400,pink.400)"
                  bgClip="text"
                >
                  !
                </Text>
              </Heading>
              <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
                Yeni gizli müşteriler arıyoruz! Ekibimize katılmak için
                başvuruda bulununuz.
              </Text>
            </Stack>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={4}>
                <FormControl isInvalid={errors.name}>
                  {/* <FormLabel htmlFor="name">Ad Soyad</FormLabel> */}
                  <Input
                    placeholder="Ad Soyad"
                    type="text"
                    id="name"
                    {...register("name", {
                      required: "Ad soyad giriniz",
                    })}
                  />
                  <FormErrorMessage>
                    {errors.name && errors.name.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.email}>
                  {/* <FormLabel htmlFor="email">E-Posta</FormLabel> */}
                  <Input
                    placeholder="E-Posta"
                    type="email"
                    id="email"
                    {...register("email", {
                      required: "E-posta giriniz",
                      pattern: {
                        value: regexEmail,
                        message: "Geçersiz e-posta",
                      },
                    })}
                  />
                  <FormErrorMessage>
                    {errors.email && errors.email.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl id="password" isInvalid={errors.password}>
                  <InputGroup>
                    <Input
                      placeholder="Şifre"
                      type={showPassword ? "text" : "password"}
                      {...register("password", {
                        required: "Şifre giriniz",
                        pattern: {
                          value: regexPassword,
                          message: "Şifreniz en az 8 karakter olmalı",
                        },
                      })}
                    />
                    <InputRightElement h={"full"}>
                      <Button
                        variant={"ghost"}
                        onClick={() =>
                          setShowPassword((showPassword) => !showPassword)
                        }
                      >
                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    {errors.password && errors.password.message}
                  </FormErrorMessage>
                </FormControl>{" "}
                <Stack spacing={10} pt={2}>
                  <Button
                    fontFamily={"heading"}
                    mt={8}
                    isLoading={isSubmitting}
                    type="submit"
                    w={"full"}
                    bgGradient="linear(to-r, red.400,pink.400)"
                    color={"white"}
                    _hover={{
                      bgGradient: "linear(to-r, red.400,pink.400)",
                      boxShadow: "xl",
                    }}
                  >
                    Kayıt Ol
                  </Button>
                </Stack>
                <Stack pt={1}>
                  <Text align={"right"}>
                    <Link to={"/"}>
                      <Text color={"gray.400"}>Kaydınız varsa</Text>
                    </Link>
                  </Text>
                </Stack>
              </Stack>
            </form>
            <Box as={"form"} mt={10}>
              <Stack spacing={4}>
                {/* <Button fontFamily={'heading'} bg={'gray.200'} color={'gray.800'}>
                Upload CV
              </Button> */}
              </Stack>
            </Box>
            form
          </Stack>
        </Container>
        <Blur
          position={"absolute"}
          top={-10}
          left={-10}
          style={{ filter: "blur(70px)" }}
        />
      </Box>
    </>

    // <Flex minH={"100vh"} align={"center"} justify={"center"} bg={"gray.50"}>
    //   <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
    //     <Stack align={"center"}>
    //       <Heading fontSize={"3xl"} textAlign={"center"}>
    //         Yeni Gizli Müşteri
    //       </Heading>
    //       <Text fontSize={"lg"} color={"gray.600"}>
    //         🔥 Başvuru formunu doldurunuz
    //       </Text>
    //     </Stack>
    //     <Box rounded={"lg"} bg={"white"} boxShadow={"lg"} p={8} w={"lg"}>
    //       <form onSubmit={handleSubmit(onSubmit)}>
    //         <Stack spacing={4}>
    //           <FormControl isInvalid={errors.name}>
    //             <FormLabel htmlFor="name">Ad Soyad</FormLabel>
    //             <Input
    //               type="text"
    //               id="name"
    //               {...register("name", {
    //                 required: "Ad soyad giriniz",
    //               })}
    //             />
    //             <FormErrorMessage>
    //               {errors.name && errors.name.message}
    //             </FormErrorMessage>
    //           </FormControl>
    //           <FormControl isInvalid={errors.email}>
    //             <FormLabel htmlFor="email">E-Posta</FormLabel>
    //             <Input
    //               type="email"
    //               id="email"
    //               {...register("email", {
    //                 required: "E-posta giriniz",
    //                 pattern: {
    //                   value: regexEmail,
    //                   message: "Geçersiz e-posta",
    //                 },
    //               })}
    //             />
    //             <FormErrorMessage>
    //               {errors.email && errors.email.message}
    //             </FormErrorMessage>
    //           </FormControl>
    //           <FormControl id="password" isInvalid={errors.password}>
    //             <FormLabel>Şifre</FormLabel>
    //             <InputGroup>
    //               <Input
    //                 type={showPassword ? "text" : "password"}
    //                 {...register("password", {
    //                   required: "Şifre giriniz",
    //                   pattern: {
    //                     value: regexPassword,
    //                     message: "Şifreniz en az 8 karakter olmalı",
    //                   },
    //                 })}
    //               />
    //               <InputRightElement h={"full"}>
    //                 <Button
    //                   variant={"ghost"}
    //                   onClick={() =>
    //                     setShowPassword((showPassword) => !showPassword)
    //                   }
    //                 >
    //                   {showPassword ? <ViewIcon /> : <ViewOffIcon />}
    //                 </Button>
    //               </InputRightElement>
    //             </InputGroup>
    //             <FormErrorMessage>
    //               {errors.password && errors.password.message}
    //             </FormErrorMessage>
    //           </FormControl>{" "}
    //           <Stack spacing={10} pt={2}>
    //             <Button
    //               type="submit"
    //               colorScheme={"blue"}
    //               variant={"solid"}
    //               isLoading={isSubmitting}
    //               // loadingText="Yükleniyor"
    //             >
    //               Gönder
    //             </Button>
    //           </Stack>
    //           <Stack pt={3}>
    //             <Text align={"center"}>
    //               <Link to={"/"}>
    //                 <Text  color={"blue.400"}>
    //                   👉 Kaydınız varsa
    //                 </Text>
    //               </Link>
    //             </Text>
    //           </Stack>
    //         </Stack>
    //       </form>
    //     </Box>
    //   </Stack>
    // </Flex>
  );
}

export const Blur = (props) => {
  return (
    <Icon
      width={useBreakpointValue({ base: "100%", md: "40vw", lg: "30vw" })}
      zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="71" cy="61" r="111" fill="#F56565" />
      <circle cx="244" cy="106" r="139" fill="#ED64A6" />
      <circle cy="291" r="139" fill="#ED64A6" />
      <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
      <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" />
      <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" />
      <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
    </Icon>
  );
};
