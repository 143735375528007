import { apiPublicFetch } from "../util/fetch";

const msmMysteryShopperUrl = "/mystery-shoppers";

class MysteryShopperApi {
  constructor(fetcher) {
    this.fetcher = fetcher;
  }

  async updateOne(id, data) {
    const res = await this.fetcher.put(`${msmMysteryShopperUrl}/${id}`, data);
    return res.data.data;
  }

  async deleteOne(id) {
    const res = await this.fetcher.delete(`${msmMysteryShopperUrl}/${id}`);
    return res.data.data;
  }

  // using public (no auth) fetcher and different url for new MS signup!
  async createOne(data) {
    const res = await apiPublicFetch.post(`/mystery-shoppers/signup`, data);
    return res.data.data;
  }

  // admin can also create an MS directly
  async createOneByAdmin(data) {
    const res = await this.fetcher.post(`${msmMysteryShopperUrl}`, data);
    return res.data.data;
  }

  async registerApplication(id, data) {
    const res = await this.fetcher.put(
      `${msmMysteryShopperUrl}/register/${id}`,
      data
    );
    return res.data.data;
  }

  async registerTraining(id) {
    const res = await this.fetcher.put(`${msmMysteryShopperUrl}/${id}`, {
      applicationStatus: "trainingCompleted",
    });
    return res.data.data;
  }
}

export default MysteryShopperApi;
