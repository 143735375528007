import {
  Button,
  FormControl,
  FormLabel,
  Select,
  SimpleGrid,
  Center,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { useController } from "react-hook-form";
import useSWR from "swr";
import { useLanguageLabels } from "../../hooks/useLanguageLabels";

function LanguageTag({ label, onClick }) {
  return (
    <Tag size="md" borderRadius="full" variant="outline" colorScheme="pink">
      <TagLabel>{label}</TagLabel>
      <TagCloseButton onClick={onClick} />
    </Tag>
  );
}

export function LanguageMultiSelect({ name, control }) {
  // UI state
  const [language, setLanguage] = useState("");
  // language = "en"
  // value = ["en", "de", ...]
  const {
    field: { value, onChange },
    fieldState: { invalid, error },
  } = useController({ name, control });

  const [selectedLanguages, setSelectedLanguages] = useState(value);

  const { data: languages, error: swrError } = useSWR(`/formdata/languages`);
  const labels = useLanguageLabels();
  // labels = {"en": "ingilizce", etc...}

  function handleChangeLanguage(lang) {
    setLanguage(lang);
  }

  function addValue() {
    if (!selectedLanguages.includes(language) && language !== "") {
      setSelectedLanguages((prevState) => [...prevState, language]); // UI update
      onChange([...value, language]); // value update
    }
  }

  function removeValue(lang) {
    setSelectedLanguages((prevState) => prevState.filter((l) => l !== lang)); // UI update
    onChange(value.filter((l) => l !== lang)); // value update
  }

  return (
    <Stack direction={"column"} spacing="12px">
      <FormControl isInvalid={invalid}>
        <FormLabel>Yabancı Diller</FormLabel>
        {!languages ? (
          // empty select as placeholder
          <Select variant={"filled"} placeholder="Lütfen seçiniz" />
        ) : (
          <Select
            placeholder="Lütfen seçiniz"
            variant={"filled"}
            name="language"
            value={language}
            onChange={(e) => handleChangeLanguage(e.target.value)}
          >
            {languages.map(({ value, label_tr }) => (
              <option key={value} value={value}>
                {label_tr}
              </option>
            ))}
          </Select>
        )}
      </FormControl>

      <Stack direction="row" spacing="12px">
        <Button onClick={addValue} colorScheme="green" size="sm"> 
          <AddIcon  />
        </Button>
        {selectedLanguages.length > 0 && (
          <SimpleGrid
            // columns={{ base: "3", md: "5" }}
            columns={"2"}
            spacing="8px"
            border={"2px"}
            borderColor="gray.200"
            borderRadius={"lg"}
            p={4}
          >
            {selectedLanguages.map((lang) => (
              <LanguageTag
                key={lang}
                label={labels?.[lang]}
                onClick={() => removeValue(lang)}
              />
            ))}
          </SimpleGrid>
        )}
        <Center color={"red.500"}>{error?.message}</Center>
      </Stack>
    </Stack>
  );
}
