import { Box, Text, Stack, Flex, Center, Heading } from "@chakra-ui/react";
import {
  FiBarChart,
  FiDownloadCloud,
  FiMessageCircle,
  FiMapPin,
  FiPieChart,
  FiLayers,
  FiMonitor,
  FiUsers,
} from "react-icons/fi";

export default function StatCard({
  type,
  topMargin,
  children,
  label,
  value,
  icon,
  size,
  ...props
}) {
  return (
    <Box
      bg="gray.50"
      borderRadius="xl"
      borderWidth="2px"
      borderColor="gray.300"
      borderStyle={"dashed"}
      mt={topMargin && 6}
      p={4}
      w={"100%"}
      {...props}
    >
      <Flex>
        <Center>{icon}</Center>
        <Box ml={4}>
          <Text>{label}</Text>
          <Heading as="h3" size={size || "md"} color="gray.600">
            {value}
          </Heading>{" "}
        </Box>
      </Flex>
    </Box>
  );
}
