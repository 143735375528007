const visitScenarioUrl = "/visit-scenarios";

class VisitScenarioApi {
  constructor(fetcher) {
    this.fetcher = fetcher;
  }

  async createOneForProject(projectId, data) {
    const res = await this.fetcher.post(
      `projects/${projectId}/visit-scenarios`,
      data
    );
    return res.data.data;
  }

  async updateOne(id, data) {
    const res = await this.fetcher.put(`${visitScenarioUrl}/${id}`, data);
    return res.data.data;
  }

  async deleteOne(id) {
    const res = await this.fetcher.delete(`${visitScenarioUrl}/${id}`);
    return res.data.data;
  }
}

export default VisitScenarioApi;
