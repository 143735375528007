import { MenuList, MenuItem } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function MSMenuList({
  cell,
  handlePassive,
  handleActive,
  handleApprove,
  handleDeny,
}) {
  const navigate = useNavigate();

  const detailMenuItem = (
    <MenuItem
      key="0"
      onClick={() => {
        navigate(`/ina/msm/mystery-shoppers/${cell.row.values._id}`);
      }}
    >
      Detay
    </MenuItem>
  );
  const passiveMenuItem = (
    <MenuItem key="1" onClick={() => handlePassive(cell.row.values._id)}>
      Pasife Al
    </MenuItem>
  );

  const activeMenuItem = (
    <MenuItem key="2" onClick={() => handleActive(cell.row.values._id)}>
      Aktive Et
    </MenuItem>
  );
  const approveMenuItem = (
    <MenuItem key="3" onClick={() => handleApprove(cell.row.values._id)}>
      Onayla
    </MenuItem>
  );
  const denyMenuItem = (
    <MenuItem key="4" onClick={() => handleDeny(cell.row.values._id)}>
      Reddet
    </MenuItem>
  );

  let list = [];
  const { status, applicationStatus } = cell.row.original;

  switch (applicationStatus) {
    case "approved":
      list = [detailMenuItem, passiveMenuItem];
      break;
    case "trainingCompleted":
      list = [detailMenuItem, approveMenuItem, denyMenuItem];
      break;
    case "denied":
      list = [detailMenuItem, approveMenuItem];
      break;
    default:
      list = [detailMenuItem];
  }

  if (status === "inactive") {
    list = [detailMenuItem, activeMenuItem];
  }

  return <MenuList>{list}</MenuList>;
}

// In case we want to add Confimation Alert
// const passiveMenuItem = (
//   <MenuItem
//     onClick={
//       () => makeItPassive(cell.row.values._id)
//       // { showAlert(
//       //   <ConfirmAlert
//       //     header="Gizli Müşteri"
//       //     body="Gizli müşteriyi pasif listeye almak istiyor musunuz?"
//       //     buttonLabel="Pasife Al"
//       //     onAction={() => makeItPassive(cell.row.values._id)}
//       //   />
//       // );}
//     }
//   >
//     Pasife Al
//   </MenuItem>
// );

// const activeMenuItem = (
//   <MenuItem
//     onClick={
//       () => makeItActive(cell.row.values._id)
//       // { showAlert(
//       //   <ConfirmAlert
//       //     header="Gizli Müşteri"
//       //     body="Gizli müşteriyi tekrar aktive etmek istiyor musunuz?"
//       //     buttonLabel="Aktive Et"
//       //     onAction={() => makeItActive(cell.row.values._id)}
//       //   />
//       // );}
//     }
//   >
//     Aktive Et
//   </MenuItem>
// );
// const approveMenuItem = (
//   <MenuItem
//     onClick={
//       () => approveApplication(cell.row.values._id)
//       // { showAlert(
//       //   <ConfirmAlert
//       //     header="Gizli Müşteri Başvurusu"
//       //     body="Başvuruyu onaylıyor musunuz?"
//       //     buttonLabel="Onayla"
//       //     onAction={() => approveApplication(cell.row.values._id)}
//       //   />
//       // );}
//     }
//   >
//     Onayla
//   </MenuItem>
// );
// const denyMenuItem = (
//   <MenuItem
//     onClick={
//       () => denyApplication(cell.row.values._id)
//       // { showAlert(
//       //   <ConfirmAlert
//       //     header="Gizli Müşteri Başvurusu"
//       //     body="Başvuruyu reddetmek istiyor musunuz?"
//       //     buttonLabel="Reddet"
//       //     onAction={() => denyApplication(cell.row.values._id)}
//       //   />
//       // );}
//     }
//   >
//     Reddet
//   </MenuItem>
// );
