import { Heading, Stack, Text, useToast } from "@chakra-ui/react";
import PageLayout from "../../../components/Layout/PageLayout";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { UserUpdateForm } from "../../settings/users";
import { getFirstName } from "../../../util/stringUtils";
import useSWR from "swr";
import Card from "../../../components/Layout/Card";

export default function UserProfileContainer({ id }) {
  const { data: user, error, mutate } = useSWR(`/users/${id}`);
  const toast = useToast();
  if (error) {
    toast({
      title: "Bir hata oluştu.",
      status: "error",
    });
  }

  return (
    <PageLayout>
      {!user ? (
        <LoadingIndicator />
      ) : (
        <Stack>
          <Stack my={6} spacing={2} textAlign={"center"}>
            <Heading>
              {getFirstName(user?.name)}, Profil Sayfana Hoşgeldin ✋
            </Heading>
            <Text color={"gray.600"} fontSize={"xl"}>
              Bilgilerini bu sayfada güncelleyebilirsin.
            </Text>
          </Stack>
          <Card topMargin>
            <UserUpdateForm user={user} mutateUser={mutate} />
          </Card>
        </Stack>
      )}
    </PageLayout>
  );
}
