import { Box, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import { FiCircle, FiCheckCircle } from "react-icons/fi";

export function MultiSelectAnswer({ choices, answer }) {
  return (
    <VStack align={"normal"} spacing={1} width={"100%"}>
      {choices.map((choice) => {
        const {
          _id: choiceId,
          choiceText,
          score,
          personnelScore,
          descriptionText,
        } = choice;
        return (
          <Box key={choiceId}>
            <Flex
              // align={"center"}
              justify={"space-between"}
              fontSize={"lg"}
              color={"blue.700"}
            >
              <HStack>
                {answer?.selectedMultiChoices?.includes(choiceId) ? (
                  <FiCheckCircle />
                ) : (
                  <FiCircle />
                )}
                <Text ml="2"> {choiceText}</Text>
              </HStack>
              <Text>
                {score}/{personnelScore || "-"}
              </Text>
            </Flex>
            <VStack px={6} align={"start"} spacing={0}>
              <Text color={"gray.500"}>{descriptionText}</Text>
              <Text color={"blue.700"}>
                {answer?.choiceDescriptions?.[choiceId]}
              </Text>
            </VStack>
          </Box>
        );
      })}
    </VStack>
  );
}
