import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import useSWR from "swr";
import { Logo } from "./Svgs";
import { UserAvatarAlert } from "./UserAvatarAlert";
import { useAuth } from "../contexts/AuthContext";
import { useUiContext } from "../contexts/UiContext";

export default function NavBar() {
  const AVATAR_SOURCE = "/public/images/user";
  const { showAlert } = useUiContext();
  const { isOpen, onToggle } = useDisclosure();
  const { getCurrentUserId, logout, isAdmin } = useAuth();
  const { data: user } = useSWR(`/users/${getCurrentUserId()}`);
  // only admin can see nav links
  const showNavLinks = isAdmin();

  return (
    <Box>
      <Flex
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          {showNavLinks && (
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={5} h={5} />
                )
              }
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
          )}
        </Flex>

        <Flex
          px={8}
          flex={{ base: 1 }}
          justify={{ base: "center", md: "start" }}
        >
          <RouterLink to="/ina">
            <Logo />
          </RouterLink>
          {showNavLinks && (
            <Flex display={{ base: "none", md: "flex" }} ml={10}>
              <DesktopNav />
            </Flex>
          )}
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
          <Menu>
            <MenuButton
              as={Button}
              rounded={"full"}
              variant={"link"}
              cursor={"pointer"}
              minW={0}
            >
              <Avatar
                size={"sm"}
                src={user?.picture && `${AVATAR_SOURCE}/${user.picture}`}
              />
            </MenuButton>
            <MenuList>
              <MenuItem>
                <RouterLink to="/ina/profile">{user?.name}</RouterLink>
              </MenuItem>
              <MenuItem
                onClick={() =>
                  showAlert(
                    <UserAvatarAlert
                      userId={user?._id}
                      picture={user?.picture}
                    />
                  )
                }
              >
                Profil Resmi
              </MenuItem>
              <MenuItem onClick={() => logout()}>Çıkış</MenuItem>
            </MenuList>
          </Menu>
        </Stack>
      </Flex>

      {showNavLinks && (
        <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse>
      )}
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue("gray.500", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Stack direction={"row"} spacing={6} style={{ marginTop: "3px" }}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                // as={RouterLink}
                // to={navItem.href ?? "#"}
                p={2}
                fontWeight={800}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <Link
      as={RouterLink}
      to={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("pink.50", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "pink.400" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{
            opacity: "100%",
            transform: "translateX(0)",
          }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"pink.400"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          // mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link as={RouterLink} key={child.label} py={1} to={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: "Crm",
    children: [
      {
        label: "Firma Listesi",
        subLabel: "Firma ekle, sil, güncelle",
        href: "/ina/crm/companies",
      },
      {
        label: "Sektörler",
        subLabel: "Firma sektörleri",
        href: "/ina/crm/sectors",
      },
    ],
  },
  {
    label: "Proje Yönetimi",
    children: [
      {
        label: "Gizli Müşteri Projeleri",
        subLabel: "Sistemde tanımlı olan gizli müşteri projeleri",
        href: "/ina/pms/projects",
      },
      {
        label: "Proje Arşivi",
        subLabel: "Arşivlenmiş gizli müşteri projeleri",
        href: "/ina/pms/projects/archive",
      },
    ],
  },
  {
    label: "GM Yönetimi",
    children: [
      {
        label: "GM Listesi",
        subLabel: "Aktif gizli müşteriler",
        href: "/ina/msm/mystery-shoppers/active",
      },
      {
        label: "Başvurular",
        subLabel: "Onay bekeleyen gizli müşteri başvuruları",
        href: "/ina/msm/mystery-shoppers/pending-approval",
      },
    ],
  },
  {
    label: "Ayarlar",
    children: [
      {
        label: "Kullanici Ayarlari",
        subLabel: "Kullanici Ekle / Cikar",
        href: "/ina/settings/users",
      },
    ],
  },
];
