import {
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import PageHeader from "../../components/Layout/PageHeader";
import PageLayout from "../../components/Layout/PageLayout";
import LoadingIndicator from "../../components/LoadingIndicator";
import {
  MSFieldMgrTab,
  MSFinancialsTab,
  MSInfoTab,
  MSNotesTab,
  MSVisitsTab,
} from "./msDetails";
import { MSStatusTag } from "./msStatusTag";
import { MSActionButtonsList } from "./msActionButtonsList";
import { useMSStatusActions } from "../../hooks/useMsStatusActions";

export function MysteryShopperDetailPage() {
  const params = useParams();
  const toast = useToast();
  const {
    isLoading,
    makeMsActive,
    makeMsPassive,
    approveMsApplication,
    denyMsApplication,
  } = useMSStatusActions();

  const {
    data: ms,
    error,
    mutate: mutateMs,
  } = useSWR(`/mystery-shoppers/${params.id}`);

  if (error) {
    toast({
      title: "Sunucu hatası",
      description: "Lütfen tekrar deneyin.",
      status: "error",
      duration: 3000,
      // isClosable: true,
    });
  }

  if (!ms) {
    return <LoadingIndicator />;
  }

  const isMSApproved = ms?.applicationStatus === "approved";

  return (
    <PageLayout>
      <PageHeader
        title={
          <>
            {ms?.name}{" "}
            <MSStatusTag
              status={ms?.status}
              applicationStatus={ms?.applicationStatus}
              size={"sm"}
            />{" "}
          </>
        }
        // backUrl=".."
      >
        {isLoading ? (
          <Spinner
            thickness="4px"
            speed="0.75s"
            emptyColor="gray.200"
            color="pink.500"
            size="lg"
          />
        ) : (
          <MSActionButtonsList
            ms={ms}
            handlePassive={makeMsPassive}
            handleActive={makeMsActive}
            handleApprove={approveMsApplication}
            handleDeny={denyMsApplication}
          />
        )}
      </PageHeader>

      <Tabs mt={8}>
        {isMSApproved ? (
          <TabList>
            <Tab>Bilgiler</Tab>
            <Tab>Ziyaretler</Tab>
            <Tab>Finansallar</Tab>
            {ms.status === "active" && <Tab>Saha Çalışanı</Tab>}
            <Tab>Notlar</Tab>
          </TabList>
        ) : (
          <TabList>
            <Tab>Bilgiler</Tab>
            <Tab>Notlar</Tab>
          </TabList>
        )}

        <TabPanels>
          <TabPanel>
            <MSInfoTab ms={ms} />
          </TabPanel>
          {isMSApproved && (
            <TabPanel>
              <MSVisitsTab msId={ms?._id} />
            </TabPanel>
          )}
          {isMSApproved && (
            <TabPanel>
              <MSFinancialsTab ms={ms} />
            </TabPanel>
          )}
          {isMSApproved && ms.status === "active" && (
            <TabPanel>
              <MSFieldMgrTab ms={ms} mutateMs={mutateMs} />
            </TabPanel>
          )}
          <TabPanel>
            <MSNotesTab msId={ms?._id} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageLayout>
  );
}
