import { Box } from "@chakra-ui/react";
import { formatHtmlWithChakra } from "../../../util/stringUtils";

export function QuestionBox({ questionText, skipQuestion }) {
  return (
    <Box
      dangerouslySetInnerHTML={{
        __html: formatHtmlWithChakra(questionText),
      }}
      sx={skipQuestion && { filter: "opacity(50%)" }}
      marginBottom="4"
    />
  );
}
