import { useMemo } from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import PageHeader from "../../components/Layout/PageHeader";
import PageLayout from "../../components/Layout/PageLayout";
import ClientTable from "../../components/Table/ClientTable";
import LoadingIndicator from "../../components/LoadingIndicator";
import Card from "../../components/Layout/Card";
import { ProjectStatusTag } from "../pms/project/projectStatusTag";

export function ClientProjectListPage() {
  const { data: projects } = useSWR("/projects");

  const columns = useMemo(
    () => [
      {
        Header: "Proje Adi",
        accessor: "name",
        Cell: ({ cell }) => (
          <Link to={`project/${cell.row.original._id}/dashboard`}>
            {cell.row.values.name}
          </Link>
        ),
      },
      {
        Header: "Durum",
        accessor: "status",
        Cell: ({ cell }) => (
          <ProjectStatusTag status={cell.row.values.status} size={"sm"} />
        ),
      },
    ],
    []
  );

  return (
    <PageLayout>
      <PageHeader title="Proje Listesi"></PageHeader>
      <Card mt={4}>
        {!projects ? (
          <LoadingIndicator />
        ) : (
          <ClientTable data={projects} columns={columns} />
        )}
      </Card>
    </PageLayout>
  );
}
