import { Outlet } from "react-router-dom";
import SplitPageWithSideNav from "../../../components/SplitPageWithSideNav";

import {
  FiUser,
  FiEdit,
  FiTool,
  FiCheckSquare,
  FiDollarSign,
  FiInbox,
} from "react-icons/fi";
import useSWR from "swr";
import { ChatIcon } from "@chakra-ui/icons";

export function MSVisitIndexPage() {
  const { data: invites } = useSWR("/mystery-shopper/visit-invites");
  const { data: visits } = useSWR("/mystery-shopper/visits");
  const pendingVisits = visits?.filter((v) => v.status === "ms_confirmed");
  const inProgressVisits = visits?.filter(
    (v) =>
      v.status === "waiting_approval_admin" ||
      v.status === "waiting_approval_fm" ||
      v.status === "fm_approved"
  );
  const rejectedVisits = visits?.filter(
    (v) => v.status === "rejected" || v.status === "fm_rejected"
  );
  const approvedVisits = visits?.filter(
    (v) => v.status === "approved" || v.status === "published"
  );

  const LinkItems = [
    { name: "Profil Sayfası", icon: FiUser, to: "/ina/profile" },
    { name: "Finansal Durum", icon: FiDollarSign, to: "financials" },
    {
      name: "Ziyaret Davetleri",
      icon: FiInbox,
      to: "invites",
      quantity: invites?.length > 0 && invites.length,
      divider: true,
    },
    {
      name: "Bekleyen Ziyaretler",
      icon: FiEdit,
      to: "pending",
      quantity: pendingVisits?.length > 0 && pendingVisits.length,
    },
    {
      name: "Onay Bekleyenler",
      icon: ChatIcon,
      to: "in-progress",
      quantity: inProgressVisits?.length > 0 && inProgressVisits.length,
    },
    {
      name: "Revize Gerekenler",
      icon: FiTool,
      to: "rejected",
      quantity: rejectedVisits?.length > 0 && rejectedVisits.length,
    },
    {
      name: "Tamamlananlar",
      icon: FiCheckSquare,
      to: "approved",
      quantity: approvedVisits?.length > 0 && approvedVisits.length,
    },
  ];

  return (
    <SplitPageWithSideNav linkItems={LinkItems}>
      <Outlet />
    </SplitPageWithSideNav>
  );
}
