const goalUrl = "/goals";

class GoalApi {
  constructor(fetcher) {
    this.fetcher = fetcher;
  }

  async createOneForProject(projectId, data) {
    const res = await this.fetcher.post(`projects/${projectId}/goals`, data);
    return res.data.data;
  }

  async updateOne(id, data) {
    const res = await this.fetcher.put(`${goalUrl}/${id}`, data);
    return res.data.data;
  }

  async deleteOne(id) {
    const res = await this.fetcher.delete(`${goalUrl}/${id}`);
    return res.data.data;
  }
}

export default GoalApi;
