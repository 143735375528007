import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
} from "@chakra-ui/react";
import SelectWrapper from "./SelectWrapper";

import useSWR from "swr";

export function BankSelect({ errors, register }) {
  const { data: banks, error } = useSWR(`/formdata/banks`);
  return (
    <FormControl isInvalid={errors.bankName}>
      <FormLabel>Banka</FormLabel>
      {!banks ? (
        // empty select as placeholder
        <Select variant={"filled"} placeholder="Lütfen seçiniz" />
      ) : (
        <SelectWrapper
          register={{
            ...register("bankName", { required: "Gerekli alan" }),
          }}
          data={banks.map((bank) => ({
            value: bank.name,
            text: bank.name,
          }))}
        />
      )}
      <FormErrorMessage>{errors.bankName?.message}</FormErrorMessage>
    </FormControl>
  );
}
