import { Outlet } from "react-router-dom";
import SplitPageWithSideNav from "../../components/SplitPageWithSideNav";
import {
  FiUsers,
  FiUserCheck,
  FiThumbsDown,
  FiArchive,
  FiLayers,
  FiFileText,
} from "react-icons/fi";
import useSWR from "swr";

export function MysteryShopperIndexPage() {
  const { data: activeMS } = useSWR("/mystery-shoppers/status/active");
  const { data: deniedMS } = useSWR(
    "/mystery-shoppers/application-status/denied"
  );
  const { data: pendingApprovalMS } = useSWR(
    "/mystery-shoppers/application-status/trainingCompleted"
  );
  const { data: inactiveMS } = useSWR("/mystery-shoppers/status/inactive");
  const { data: newApplicantsMS } = useSWR(
    "/mystery-shoppers/application-status/pending"
  );
  // applicationFormCompleted is skipped for now (no training)
  // const { data: pendingEducationMS } = useSWR(
  //   "/mystery-shoppers/application-status/applicationFormCompleted"
  // );

  const LinkItems = [
    {
      name: "Aktif GM Listesi",
      icon: FiUsers,
      to: "active",
      // quantity: activeMS?.length > 0 && activeMS.length,
    },
    {
      name: "Onay Bekleyenler",
      icon: FiUserCheck,
      to: "pending-approval",
      quantity: pendingApprovalMS?.length > 0 && pendingApprovalMS.length,
    },
    {
      name: "Yeni Başvurular",
      icon: FiFileText,
      to: "new-applicants",
      quantity: newApplicantsMS?.length > 0 && newApplicantsMS.length,
    },
    {
      name: "Red Verilenler",
      icon: FiThumbsDown,
      to: "denied",
      quantity: deniedMS?.length > 0 && deniedMS.length,
    },
    { name: "Raporlar", icon: FiLayers, to: "reports" },
    {
      name: "Pasif GM Listesi",
      icon: FiArchive,
      to: "inactive",
      quantity: inactiveMS?.length > 0 && inactiveMS.length,
    },
  ];

  return (
    <SplitPageWithSideNav linkItems={LinkItems}>
      <Outlet />
    </SplitPageWithSideNav>
  );
}
