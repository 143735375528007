import {
  Button,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogBody,
  useToast,
  Stack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import StandardFormControl from "../../../../components/Form/StandardFormControl";
import SelectWrapper from "../../../../components/Form/SelectWrapper";
import { useApi } from "../../../../contexts/ApiContext";

export function SkipQuestionAddAlert({
  parentId,
  choices,
  index,
  childrenListForSkipCriteria,
  onClose,
  mutateQuestionForm,
}) {
  const toast = useToast();
  const { formApi } = useApi();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      childQuestion: "",
      choice: "",
    },
  });

  const onSubmit = async (data) => {
    try {
      const { childQuestion, choice } = data;
      await formApi.addSkipQuestionCriteria(childQuestion, {
        parentQuestion: parentId,
        choice,
      });
      toast({
        title: "Başarılı",
        description: "Yeni kriter başarıyla eklendi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      mutateQuestionForm();
      onClose();
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <AlertDialogContent>
      <AlertDialogHeader fontSize="lg" fontWeight="bold">
        {`${index + 1}. Soruya Atlama Kriteri Ekle`}
      </AlertDialogHeader>

      <form onSubmit={handleSubmit(onSubmit)}>
        <AlertDialogBody>
          <Stack direction={"column"} spacing="16px">
            <StandardFormControl
              errors={errors}
              name="choice"
              label="Hangi şık seçiliyse:"
            >
              <SelectWrapper
                register={{
                  ...register("choice", { required: "Gerekli alan" }),
                }}
                data={choices?.map((c) => ({
                  value: c._id,
                  text: c.choiceText,
                }))}
              />
            </StandardFormControl>
            <StandardFormControl
              errors={errors}
              name="childQuestion"
              label="Kaçıncı soruyu:"
            >
              <SelectWrapper
                register={{
                  ...register("childQuestion", { required: "Gerekli alan" }),
                }}
                data={childrenListForSkipCriteria.map((q) => ({
                  value: q._id,
                  text: q.questionText,
                }))}
              />
            </StandardFormControl>
          </Stack>
          <AlertDialogFooter marginTop={4}>
            <Button onClick={onClose}>Vazgeç</Button>
            <Button
              colorScheme="green"
              ml={3}
              isLoading={isSubmitting}
              type="submit"
            >
              Soruyu Atla
            </Button>
          </AlertDialogFooter>
        </AlertDialogBody>
      </form>
    </AlertDialogContent>
  );
}
