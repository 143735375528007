import { useToast } from "@chakra-ui/react";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ClientTable from "../../../components/Table/ClientTable";
import { Scale } from "../../../components/Scale";
import { visitStatus } from "../../../util/enums";
import Card from "../../../components/Layout/Card";
export function MSVisitsTab({ msId }) {
  const toast = useToast();
  const { data: visits, error } = useSWR(`/mystery-shoppers/${msId}/visits`);

  if (error) {
    toast({
      title: "Sunucu hatası",
      description: "Lütfen tekrar deneyin.",
      status: "error",
      duration: 3000,
      // isClosable: true,
    });
  }

  const columns = useMemo(
    () => [
      {
        Header: "ZİYARET",
        accessor: "name",
        Cell: ({ cell }) => {
          const { _id, project } = cell.row.original;
          return (
            <Link to={`/ina/pms/projects/${project._id}/project-plan/${_id}`}>
              {cell.row.values.name}
            </Link>
          );
        },
      },
      {
        Header: "FİRMA",
        accessor: "project.company.name",
      },
      {
        Header: "LOKASYON",
        accessor: "location.name",
      },
      {
        Header: "SAHA ÇALIŞANI",
        accessor: "fieldManager.name",
      },
      {
        Header: "DURUM",
        accessor: "status",
        Cell: ({ cell }) => <>{visitStatus[cell.row.values.status].tr}</>,
      },
      {
        Header: "PUAN",
        accessor: "msVisitScore",
        Cell: ({ cell }) => {
          const score = cell.row.values.msVisitScore;
          return score ? <Scale value={score} /> : null;
        },
      },
    ],
    []
  );

  if (!visits) {
    return <LoadingIndicator />;
  }

  return (
    <Card>
      <ClientTable data={visits} columns={columns} />;
    </Card>
  )
}
