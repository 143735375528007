import { Button, Container, VStack, Heading, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export function NotFound() {
  const navigate = useNavigate();
  return (
    <Container maxW={"3xl"}>
      <VStack textAlign={"center"} py={{ base: 20, md: 36 }} spacing={12}>
        <Heading
          fontWeight={600}
          fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
          lineHeight={"110%"}
        >
          404 <br />
          <Text as={"span"} color={"green.500"}>
            Sayfa bulunamadı 🤷‍♂️
          </Text>
        </Heading>
        <Button
          colorScheme={"green"}
          rounded={"full"}
          px={8}
          onClick={() => navigate("/")}
        >
          Ana Sayfa
        </Button>
      </VStack>
    </Container>
  );
}
