import { Button, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import useSWR from "swr";
import { useApi } from "../../../contexts/ApiContext";
import FormContainer from "../../../components/Form/FormContainer";
import FormFooter from "../../../components/Form/FormFooter";
import StandardFormControl from "../../../components/Form/StandardFormControl";
import SelectWrapper from "../../../components/Form/SelectWrapper";
import Card from "../../../components/Layout/Card";

export function MSFieldMgrTab({ ms, mutateMs }) {
  const toast = useToast();
  const { mysteryShopperApi } = useApi();
  const { data: fieldManagers, error: serverError } = useSWR("/field-managers");
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      fieldMgr: ms.fieldMgr?._id,
    },
  });

  async function onSubmit(data) {
    try {
      await mysteryShopperApi.updateOne(ms._id, data);
      mutateMs();
      toast({
        title: "Başarılı",
        description: "Bilgiler güncellendi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  if (!fieldManagers) {
    return null;
  }

  if (serverError) {
    toast({
      title: "Sunucu hatası",
      description: "Lütfen tekrar deneyin.",
      status: "error",
      duration: 3000,
      // isClosable: true,
    });
  }

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormContainer>
          <StandardFormControl
            errors={errors}
            name="fieldMgr"
            label="Saha Çalışanı"
          >
            <SelectWrapper
              register={{
                ...register("fieldMgr"),
              }}
              data={fieldManagers?.map((mgr) => ({
                value: mgr._id,
                text: mgr.name,
              }))}
            />
          </StandardFormControl>
          <FormFooter>
            <Button colorScheme={"pink"} isLoading={isSubmitting} type="submit">
              Güncelle
            </Button>
          </FormFooter>
        </FormContainer>
      </form>
    </Card>
  );
}
