import Select from "react-select";
import useSWR from "swr";
import { useState } from "react";
import ReportChart from "../../components/Reporting/ReportChart";
import { Box, Flex } from "@chakra-ui/react";
import PageHeader from "../../components/Layout/PageHeader";
import PageLayout from "../../components/Layout/PageLayout";
import { useParams } from "react-router";
import Card from "../../components/Layout/Card";

export function LocationBasedReportPage() {
  const { projectId } = useParams();

  const { data: categories } = useSWR(`/projects/${projectId}/categories`);

  const { data: breakdowns } = useSWR(
    `/projects/${projectId}/project-breakdowns`
  );

  const [category, setCategory] = useState(false);
  const [breakdown, setBreakdown] = useState({});
  const categoryOptions = categories?.map((category) => ({
    value: category._id,
    label: category.name,
  }));

  const onSelectCategory = ({ value }) => {
    setCategory(value);
  };

  const onSelectBreakDown = ({ breakdownId, value }) => {
    setBreakdown((curr) => ({ ...curr, [breakdownId]: value }));
  };

  return (
    <PageLayout>
      <Card mt={8} type="white">
        <PageHeader inBox title="Lokasyon Raporları" backUrl=".." />
        <Flex gap={4}>
          <Box w="100%">
            <Select
              placeholder={"Hizmet Kategorisi"}
              options={categoryOptions}
              onChange={onSelectCategory}
            />
          </Box>
          {breakdowns?.map((breakdown) => {
            return (
              <Box w="100%" key={breakdown._id}>
                <Select
                  placeholder={breakdown.name}
                  name={breakdown._id}
                  options={breakdown.options.map((option) => ({
                    value: option._id,
                    label: option.optionText,
                    breakdownId: breakdown._id,
                  }))}
                  onChange={onSelectBreakDown}
                />
              </Box>
            );
          })}
        </Flex>
      </Card>
      <ReportChart
        reportType={"location-based"}
        category={category}
        breakdown={breakdown}
        projectId={projectId}
      />
    </PageLayout>
  );
}
