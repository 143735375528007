import { useToast } from "@chakra-ui/react";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import { CompanyUpsertPage } from "./companyUpsertPage";
import LoadingIndicator from "../../../components/LoadingIndicator";

export function CompanyUpsertContainer() {
  let { id } = useParams();
  const toast = useToast();

  const {
    data: company,
    error,
    mutate,
  } = useSWR(id ? `/companies/${id}` : null);
  const { data: sectors } = useSWR("/sectors");

  if (error) {
    toast({
      title: "Bir hata oluştu.",
      description: "Lütfen tekrar deneyin.",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
    return null;
  }

  if (id && !company) {
    return <LoadingIndicator />;
  }

  return (
    <CompanyUpsertPage
      company={company}
      sectors={sectors}
      mutateCompany={mutate}
    />
  );
}
