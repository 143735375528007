import { useToast } from "@chakra-ui/react";
import useSWR from "swr";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { PersonnelUpdateForm } from "./personnelUpdateForm";

export function PersonnelUpdateComp({ id, role, closeDrawer }) {
  let url = "";
  if (role === "companyMgr") {
    url = `/company-managers/${id}`;
  } else {
    url = `/location-managers/${id}`;
  }
  const { data: personnel, error, mutate } = useSWR(url);
  const toast = useToast();

  if (error) {
    toast({
      title: "Bir hata oluştu.",
      status: "error",
    });
  }

  if (!personnel) {
    return <LoadingIndicator />;
  }

  return (
    <PersonnelUpdateForm
      personnel={personnel}
      mutatePersonnel={mutate}
      closeDrawer={closeDrawer}
    />
  );
}
