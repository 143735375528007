import {
  Button,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogBody,
} from "@chakra-ui/react";
import { useUiContext } from "../../contexts/UiContext";
import { useState } from "react";

export default function ConfirmAlert({
  header,
  body,
  buttonLabel,
  onAction,
  buttonColorScheme,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { closeAlertBox } = useUiContext();

  async function handleAction() {
    setIsSubmitting(true);
    await onAction();
    setIsSubmitting(false);
    closeAlertBox();
  }

  return (
    <AlertDialogContent>
      <AlertDialogHeader fontSize="lg" fontWeight="bold">
        {header}
      </AlertDialogHeader>
      <AlertDialogBody>{body}</AlertDialogBody>
      <AlertDialogFooter>
        <Button onClick={closeAlertBox} isDisabled={isSubmitting}>
          Iptal
        </Button>
        <Button
          colorScheme={buttonColorScheme ?? "red"}
          onClick={handleAction}
          ml={3}
          isLoading={isSubmitting}
        >
          {buttonLabel}
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  );
}
