import { Outlet, useParams } from "react-router-dom";
import {
  FiUsers,
  FiPlay,
  FiFileText,
  FiBarChart,
  FiCalendar,
  FiDollarSign,
  FiFilm,
  FiLayers,
  FiTarget,
  FiAnchor,
} from "react-icons/fi";
import SplitPageWithSideNav from "../../../components/SplitPageWithSideNav";
import useSWR from "swr";

export function ProjectDetailsPage() {
  const { id } = useParams();
  const { data: project } = useSWR(`/projects/${id}`);

  const LinkItems = [
    {
      name: project?.name ?? "Proje Anasayfa",
      icon: FiBarChart,
      to: "project-dashboard",
      divider: true,
    },
    {
      name: "Soru Formu",
      icon: FiFileText,
      to: "formbuilder",
    },
    {
      name: "Proje Amaçları",
      icon: FiTarget,
      to: "goals",
    },
    {
      name: "Personel Tipleri",
      icon: FiUsers,
      to: "personnel-types",
      divider: true,
    },
    {
      name: "Ziyaret Planı",
      icon: FiCalendar,
      to: "project-plan",
    },
    {
      name: "Ziyaret Senaryoları",
      icon: FiFilm,
      to: "visit-scenarios",
    },
    {
      name: "Ziyaret Kırılımları",
      icon: FiAnchor,
      to: "project-breakdowns",
      divider: true,
    },
    {
      name: "Proje Sonuç Ekranı",
      icon: FiPlay,
      to: "project-result",
    },
    {
      name: "Raporlar",
      icon: FiLayers,
      to: "project-reports",
    },
    {
      name: "Fınansallar",
      icon: FiDollarSign,
      to: "project-finance",
    },
  ];

  return (
    <SplitPageWithSideNav linkItems={LinkItems} firstItemAlwaysBold>
      <Outlet />
    </SplitPageWithSideNav>
  );
}
