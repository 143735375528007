import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import useSWR from "swr";
import { formatChartData } from "../../util/reporting";
import Card from "../Layout/Card";
import PageHeader from "../Layout/PageHeader";
import { VisitTable } from "../../pages/reporting/visitTable";

export default function ReportChart({
  category,
  breakdown,
  baseBreakdownId,
  reportType,
  projectId,
  location,
  dashboard,
}) {
  let queryParam = "";
  if (reportType === "breakdown-based") {
    queryParam += `baseBreakdownId=${baseBreakdownId}&`;
  }

  if (category) {
    queryParam = queryParam + `category=${category}&`;
  }

  if (location) {
    queryParam = queryParam + `location=${location}&`;
  }

  //ADD BREAKDOWN SELECTIONS
  const breakDownLength = Object.keys(breakdown).length;
  if (breakDownLength > 0) {
    let breakdownQuery = "breakdowns=";
    Object.values(breakdown).forEach((value, index) => {
      if (index + 1 < breakDownLength) {
        breakdownQuery += value + "-";
      } else {
        breakdownQuery += value;
      }
    });
    queryParam = queryParam + breakdownQuery;
  }

  const { data, error } = useSWR(`/${reportType}/${projectId}?${queryParam}`);
  const formattedChartData = data && formatChartData(data);
  if (!data) return <h1>Loading...</h1>;
  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;

    return (
      <g>
        <text
          x={x + width / 2}
          y={y - radius}
          fill="#000"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={10}
        >
          {"%" + value}
        </text>
        {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" /> */}
      </g>
    );
  };

  return (
    <>
      <Card mt={4}>
        <PageHeader inBox title="Performans" />
        <ResponsiveContainer width="100%" aspect={2.6 / 1.0}>
          <BarChart
            data={formattedChartData}
            margin={{ top: 20, left: -20, right: 0, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey={"label"}
              style={{
                fontSize: "14px",
              }}
            />
            <YAxis
              domain={[0, 100]}
              style={{
                fontSize: "13px",
              }}
            />
            <Tooltip />
            <Legend />
            <Bar dataKey="performance" stackId="a" fill="#d95229">
              <LabelList
                dataKey="performance"
                content={renderCustomizedLabel}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Card>
      {!dashboard && (
        <>
          <Card mt={4}>
            <PageHeader inBox title="Personel Performans" />

            <ResponsiveContainer width="100%" aspect={3.0 / 1.0}>
              <BarChart
                data={formattedChartData}
                margin={{ top: 20, left: -20, right: 0, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey={"label"}
                  style={{
                    fontSize: "14px",
                  }}
                />
                <YAxis
                  domain={[0, 100]}
                  style={{
                    fontSize: "13px",
                  }}
                />
                <Tooltip />
                <Legend />

                {data?.personnelTypes.map((per) => {
                  return (
                    <Bar dataKey={per.name.toString()} key={per.name} fill={per.color}>
                      {/* <LabelList
                        dataKey="performance"
                        content={renderCustomizedLabel}
                      /> */}
                    </Bar>
                  );
                })}
              </BarChart>
            </ResponsiveContainer>
          </Card>
          <Card mt={4}>
            <PageHeader inBox title="İlgili Ziyaret Listesi" />
            <VisitTable visits={data.relatedVisits} />
          </Card>
        </>
      )}
    </>
  );
}
