import { Button, Heading, Flex, HStack } from "@chakra-ui/react";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

export default function PageHeader({
  title,
  backUrl,
  children,
  subheader,
  inBox,
  icon,
  size = "md",
  color
}) {
  const navigate = useNavigate();

  return (
    <>
      <Flex justify={"space-between"} pb={inBox ? 3 : 0} align={"center"}>
      {icon && 
        <Heading color={"blackAlpha.700"} size={size ? size : (subheader ? "sm" : "md")} pr={2} pb={1} >  
          {icon}
        </Heading>
      }

        <Heading color={color || "blackAlpha.700"} size={size ? size : (subheader ? "sm" : "md")}>
          {title}
        </Heading>
        <HStack>
          {backUrl && (
            <Button
              leftIcon={<FiArrowLeft />}
              size="sm"
              onClick={() => navigate(backUrl)}
            >
              Geri Dön
            </Button>
          )}
          {children}
        </HStack>
      </Flex>
    </>
  );
}
