import {
  Badge,
  Button,
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Spinner,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  useToast,
  VStack,
  TagRightIcon,
} from "@chakra-ui/react";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CloseIcon,
  EditIcon,
  SmallCloseIcon,
} from "@chakra-ui/icons";
import { FiCircle, FiPlus, FiUser } from "react-icons/fi";
import { useState } from "react";
import useSWR from "swr";
import { QuestionUpsertAlert } from "./questionUpsert";
import { SkipQuestionAddAlert } from "./skipQuestionCriteriaAdd";
import DeleteConfirmAlert from "../../../../components/Alert/DeleteConfirmAlert";
import { useUiContext } from "../../../../contexts/UiContext";
import { useApi } from "../../../../contexts/ApiContext";
import {
  formatHtmlWithChakra,
  getQuestionTypeLabel,
} from "../../../../util/stringUtils";

export function Question({
  question,
  formIndex,
  firstWithinCat,
  lastWithinCat,
  mutateQuestionForm,
  childrenListForSkipCriteria, // possible child questions
  childIndices,
  parentIndex,
  movingQuestionId,
  setMovingQuestionId,
}) {
  const {
    _id,
    project,
    category,
    questionText,
    extraNote,
    questionType,
    personnelType,
    choices,
    skipQuestionIf,
    childQuestions,
  } = question;
  const { formApi } = useApi();
  const toast = useToast();
  const { showAlert, closeAlertBox, showModal, closeModal } = useUiContext();
  const [isDeletingSkipCriteria, setIsDeletingSkipCriteria] = useState(false);
  // Instead of getting the personnelType name for each question, I am getting all of them and filter
  // const { data: personnelTypeData } = useSWR(
  //   personnelType ? `/personneltypes/${personnelType}` : null
  // );
  // const personnelTypeLabel = personnelTypeData?.name;
  const { data: personnelTypesData } = useSWR(
    personnelType ? `projects/${project}/personneltypes` : null
  );
  const personnelTypeLabel = personnelTypesData?.find(
    (type) => type._id === personnelType
  ).name;

  async function editQuestion(question) {
    showModal(
      <QuestionUpsertAlert
        question={question}
        projectId={project}
        onClose={closeModal}
        mutateQuestionForm={mutateQuestionForm}
      />
    );
  }

  async function deleteQuestion() {
    try {
      await formApi.deleteQuestion(_id);
      mutateQuestionForm();
      toast({
        title: "Başarılı",
        description: "Soru silindi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  async function moveQuestion(move) {
    // you cannot move a child question above its parent
    if (question.skipQuestionIf?.parentQuestion && move === "up") {
      if (formIndex - parentIndex < 2) return null;
    }
    // you cannot move a parent question below its child
    if (question.childQuestions?.length > 0 && move === "down") {
      const minChildIndex = Object.values(childIndices).reduce((acc, cur) =>
        cur < acc ? cur : acc
      );
      if (minChildIndex - formIndex < 2) return null;
    }
    setMovingQuestionId(_id);
    await formApi.moveQuestionWithinCategory(category, {
      question: _id,
      move, // up or down
    });
    mutateQuestionForm();
    setMovingQuestionId(null);
  }

  async function deleteSkipCriteria(childId) {
    setIsDeletingSkipCriteria(true);
    try {
      await formApi.deleteSkipQuestionCriteria(childId);
      mutateQuestionForm();
      toast({
        title: "Başarılı",
        description: "Kriter silindi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setIsDeletingSkipCriteria(false);
  }

  async function addSkipCriteria() {
    showAlert(
      <SkipQuestionAddAlert
        parentId={_id}
        choices={choices}
        index={formIndex}
        childrenListForSkipCriteria={childrenListForSkipCriteria}
        onClose={closeAlertBox}
        mutateQuestionForm={mutateQuestionForm}
      />
    );
  }

  return (
    <VStack
      align={"flex-start"}
      bg={"white"}
      p={4}
      marginY={4}
      border={"1px"}
      borderRadius={"md"}
      borderColor={"gray.200"}
      ml={4}
    >
      <Flex width="100%" justify={"space-between"} align={"center"}>
        <HStack>
          <Tag variant="outline" colorScheme={"orange"}>
            {getQuestionTypeLabel(questionType)}
          </Tag>
          {personnelType && (
            <Tag variant="outline" colorScheme={"purple"}>
              <TagLeftIcon as={FiUser} />
              {personnelTypeLabel}
            </Tag>
          )}
        </HStack>
        <HStack>
          {questionType === "singleSelect" &&
            !skipQuestionIf?.parentQuestion && (
              <Button
                rightIcon={<FiPlus color="green" />}
                colorScheme="teal"
                variant="outline"
                aria-label={"Soru Atlama"}
                size="xs"
                onClick={addSkipCriteria}
              >
                {"Soru Atlama"}
              </Button>
            )}
          <Button
            rightIcon={<SmallCloseIcon />}
            colorScheme="red"
            // variant="outline"
            aria-label="Sil"
            size="xs"
            onClick={() => {
              showAlert(
                <DeleteConfirmAlert name={"Soru"} onDelete={deleteQuestion} />
              );
            }}
          >
            Sil
          </Button>
          <Button
            rightIcon={<EditIcon />}
            colorScheme="blue"
            aria-label="Düzenle"
            size="xs"
            onClick={() => editQuestion(question)}
          >
            Düzenle
          </Button>
          {!firstWithinCat && (
            <UpOrDownButton
              questionId={_id}
              onClick={() => moveQuestion("up")}
              movingQuestionId={movingQuestionId}
              up
            />
          )}
          {!lastWithinCat && (
            <UpOrDownButton
              questionId={_id}
              onClick={() => moveQuestion("down")}
              movingQuestionId={movingQuestionId}
            />
          )}
        </HStack>
      </Flex>

      <VStack align={"flex-start"} spacing={1}>
        <Heading size="sm">{`${formIndex + 1}. Soru`}</Heading>

        <Box
          dangerouslySetInnerHTML={{
            __html: formatHtmlWithChakra(questionText),
          }}
        />
        {extraNote && (
          <Text fontSize="sm" fontWeight={"light"} color="red">
            {extraNote}
          </Text>
        )}
      </VStack>

      <Divider />
      <VStack align={"stretch"}>
        {choices.map((c) => (
          <VStack key={c._id} align={"start"} spacing={0.5}>
            <Tag variant="outline" justifyContent={"space-between"} minW={"28"}>
              <HStack spacing={1} mr={4}>
                <FiCircle />
                <TagLabel>{c.choiceText}</TagLabel>
              </HStack>
              <Badge>
                {c.score + "/"}
                {c.personnelScore || "X"}
              </Badge>
            </Tag>
            <Text fontSize={"sm"} color={"gray.600"} paddingLeft={1}>
              {c.descriptionText}
            </Text>
          </VStack>
        ))}
      </VStack>

      {childQuestions?.length > 0 && (
        <VStack align={"start"} spacing={"2"}>
          {childQuestions.map((child) => {
            const childId = child._id;
            return (
              <Tag
                key={childId}
                size={"md"}
                variant="outline"
                colorScheme="teal"
              >
                <TagLabel>
                  {
                    choices.find((c) => c._id === child.skipQuestionIf.choice)
                      .choiceText
                  }{" "}
                  seçilirse {childIndices[childId] + 1}. Soruyu atla
                </TagLabel>
                {isDeletingSkipCriteria ? (
                  <Spinner size={"xs"} marginLeft={2.5} />
                ) : (
                  <TagRightIcon
                    as={CloseIcon}
                    boxSize={"2"}
                    onClick={() => deleteSkipCriteria(childId)}
                  />
                )}
              </Tag>
            );
          })}
        </VStack>
      )}
    </VStack>
  );
}

function UpOrDownButton({ questionId, onClick, up, movingQuestionId }) {
  if (movingQuestionId === questionId) {
    return (
      <Button
        size="xs"
        colorScheme="blackAlpha"
        variant="outline"
        isDisabled={true}
      >
        <Spinner size={"xs"} />
      </Button>
    );
  }
  return (
    <IconButton
      colorScheme="blackAlpha"
      size={"xs"}
      variant={"outline"}
      aria-label={up ? "Yukarı çıkar" : "Aşağı indir"}
      icon={up ? <ArrowUpIcon /> : <ArrowDownIcon />}
      onClick={onClick}
      isDisabled={movingQuestionId}
    />
  );
}
