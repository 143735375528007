import useSWR from "swr";

export function useLanguageLabels(localLang = "tr") {
  const { data: languages, error } = useSWR(`/formdata/languages`);

  // labels = {"en": "ingilizce", etc...}
  const labels = languages?.reduce((acc, cur) => {
    return { [cur.value]: cur[`label_${localLang}`], ...acc };
  }, {});

  return labels;
}
