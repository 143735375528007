import {
  Button,
  useToast,
  Stack,
  Textarea,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Flex,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import StandardFormControl from "../../../../components/Form/StandardFormControl";
import SelectWrapper from "../../../../components/Form/SelectWrapper";
import { RichTextInput } from "../../../../components/Form/RichText";
import { PersonnelTypesSelect } from "../../../../components/Form";
import { ChoicesArray } from "./choicesArray";
import { useApi } from "../../../../contexts/ApiContext";
import { regexNoEmptyString } from "../../../../util/regex";

export function QuestionUpsertAlert({
  question,
  projectId,
  categoryId,
  onClose,
  mutateQuestionForm,
}) {
  const toast = useToast();
  const { formApi } = useApi();

  const {
    handleSubmit,
    register,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      questionText: question?.questionText ?? "",
      questionType: question?.questionType ?? "singleSelect", // combobox
      personnelType: question?.personnelType ?? "", // combobox
      extraNote: question?.extraNote ?? "",
      choices:
        question && question.questionType !== "freeText"
          ? question.choices
          : [
              { choiceText: "Evet", score: "1" },
              { choiceText: "Hayır", score: "0" },
            ],
    },
  });

  const onSubmit = async (data) => {
    // empty choices array, if free text
    if (data.questionType === "freeText") {
      data.choices = [];
    }
    // if no personnelType => remove personnelScores from choices (just in case question had a personnelType before and changed later)
    if (data.personnelType === "") {
      data.choices.forEach((choice) => {
        choice.personnelScore = "";
      });
    }

    let description = "";
    try {
      if (question) {
        await formApi.updateQuestion(question._id, data);
        description = "Soru başarıyla düzenlendi.";
      } else {
        if (data.personnelType === "") {
          // for new question, remove personnelType if none selected
          const { personnelType, ...dataWithoutPersonnelType } = data;
          await formApi.createQuestionForProjectForCategory(
            projectId,
            categoryId,
            { ...dataWithoutPersonnelType }
          );
        } else {
          await formApi.createQuestionForProjectForCategory(
            projectId,
            categoryId,
            data
          );
        }
        description = "Yeni soru başarıyla eklendi.";
      }
      toast({
        title: "Başarılı",
        description,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      mutateQuestionForm();
      onClose();
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const questionType = watch("questionType");
  const personnelType = watch("personnelType");

  return (
    <ModalContent>
      <ModalHeader>{question ? "Soru Düzenle" : "Yeni Soru Ekle"}</ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Stack direction={"column"} spacing="16px">
            <RichTextInput
              name="questionText"
              label="Soru Metni"
              control={control}
              rules={{
                validate: (value) =>
                  (value !== "<p>Metin giriniz...</p>" &&
                    regexNoEmptyString.test(
                      value
                        .replaceAll("<p>", "")
                        .replaceAll("</p>", "")
                        .replaceAll("<br>", "")
                    )) ||
                  "Gerekli alan",
              }}
            />
            <Flex gap={"4"}>
              <StandardFormControl
                errors={errors}
                name="questionType"
                label="Soru Tipi"
                isDisabled={question?.childQuestions?.length > 0}
              >
                <SelectWrapper
                  register={{
                    ...register("questionType", { required: "Gerekli alan" }),
                  }}
                  data={[
                    { value: "singleSelect", text: "Tek Seçmeli" },
                    { value: "multiSelect", text: "Çok Seçmeli" },
                    { value: "freeText", text: "Serbest Metin" },
                  ]}
                />
              </StandardFormControl>
              <PersonnelTypesSelect
                errors={errors}
                register={register}
                projectId={projectId}
              />
            </Flex>
            <StandardFormControl
              errors={errors}
              name="extraNote"
              label="İlave not"
            >
              <Textarea
                variant={"filled"}
                id="extraNote"
                {...register("extraNote")}
              />
            </StandardFormControl>
          </Stack>
          <Stack>
            {(questionType === "singleSelect" ||
              questionType === "multiSelect") && (
              <ChoicesArray
                register={register}
                control={control}
                errors={errors}
                personnelType={personnelType}
              />
            )}
          </Stack>
        </ModalBody>
        <ModalFooter marginTop={4}>
          <Button onClick={onClose}>Vazgeç</Button>
          <Button
            colorScheme="green"
            ml={3}
            isLoading={isSubmitting}
            type="submit"
          >
            Kaydet
          </Button>
        </ModalFooter>
      </form>
    </ModalContent>
  );
}
