import axios from "axios";
import { createContext, useContext } from "react";
import { useAuth } from "./AuthContext";
const FetchContext = createContext();

// wrapper for the fetch context provider
export function FetchContextProvider({ children }) {
  // get the token from auth context
  const { authState, logout } = useAuth();
  const { token } = authState;

  // dedicated instance of axios to fetch from fastify api
  const apiAuthFetch = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  // ** CHANGE IT LATER! SEND THE TOKEN WITH COOKIE, DON'T ATTACH IT TO THE HEADER!
  if (token) {
    apiAuthFetch.interceptors.request.use(
      (config) => {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // if you get a 401 response, logout()
    apiAuthFetch.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          logout();
        }
        return Promise.reject(error);
      }
    );
  }

  return (
    <FetchContext.Provider value={apiAuthFetch}>
      {children}
    </FetchContext.Provider>
  );
}

// custom fetch hook
export function useApiAuthFetch() {
  return useContext(FetchContext);
}
