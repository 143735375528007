import {
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Tag,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { FiUserPlus } from "react-icons/fi";
import { useMemo, useState } from "react";
import useSWR from "swr";
import { Link as RouterLink } from "react-router-dom";
import { format } from "date-fns";
import ClientTable from "../../../../components/Table/ClientTable";
import { Scale } from "../../../../components/Scale";
import Card from "../../../../components/Layout/Card";
import { useApi } from "../../../../contexts/ApiContext";
import { useUiContext } from "../../../../contexts/UiContext";
import { NewVisitInvites } from "./newVisitInvites";

export function VisitMsInvites({ visit, mutateVisit }) {
  const [isAddingMS, setIsAddingMS] = useState(false);
  const [isRemovingMS, setIsRemovingMS] = useState(false);
  const {
    data: invites,
    error: invitesError,
    mutate: mutateInvites,
  } = useSWR(`/visits/${visit._id}/visit-invites`);
  const { visitApi } = useApi();
  const { showModal } = useUiContext();
  const toast = useToast();

  async function addMS(shopper) {
    try {
      setIsAddingMS(true);
      await visitApi.updateOne(visit._id, { shopper });
      mutateVisit();
      setIsAddingMS(false);
    } catch (error) {
      setIsAddingMS(false);
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  async function removeMS() {
    try {
      setIsRemovingMS(true);
      await visitApi.updateOne(visit._id, { shopper: "" });
      mutateVisit();
      setIsRemovingMS(false);
    } catch (error) {
      setIsRemovingMS(false);
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "GİZLİ MÜŞTERİ",
        accessor: "ms.name",
        Cell: ({ cell }) => {
          const {
            ms: { _id, name, email },
          } = cell.row.original;
          return (
            <Link
              as={RouterLink}
              to={`/ina/msm/mystery-shoppers/${_id}`}
              isExternal
            >
              <Box>
                <Text fontWeight="bold">{name}</Text>
                <Text fontSize="sm">{email}</Text>
              </Box>
            </Link>
          );
        },
      },
      {
        Header: "PUAN",
        accessor: "ms.averageVisitScore",
        Cell: ({ cell }) => {
          const score = cell.row.values?.["ms.averageVisitScore"];
          return score ? <Scale value={score} /> : null;
        },
      },
      {
        Header: "DAVET TARİHİ",
        accessor: "sentDate",
        Cell: ({ cell }) =>
          format(new Date(cell.row.values.sentDate), "dd-MM-yyyy"),
      },
      {
        Header: "DAVET DURUMU",
        accessor: "status",
        Cell: ({ cell }) => <InviteStatusTag status={cell.row.values.status} />,
      },
      {
        Header: "",
        accessor: "ms._id",
        Cell: ({ cell }) =>
          visit.status === "pending" &&
          cell.row.values.status === "accepted" &&
          visit.shopper?._id !== cell.row.values["ms._id"] && (
            <Button
              size={"xs"}
              colorScheme={"blue"}
              leftIcon={<FiUserPlus />}
              isDisabled={isRemovingMS}
              isLoading={isAddingMS}
              onClick={() => addMS(cell.row.values["ms._id"])}
            >
              GM Ekle
            </Button>
          ),
      },
    ],
    [isAddingMS, isRemovingMS, visit.shopper?._id, visit.status]
  );

  if (!invites) {
    return null;
  }

  return (
    <>
      <Heading size={"md"} mt={2}>
        Seçili Gizli Müşteri
      </Heading>
      <Card maxW="xs" py={4} mt={4} mb={6}>
        <VStack align={"start"} spacing={1}>
          <Text fontSize={"md"} fontWeight={"bold"}>
            {visit.shopper?.name || "---"}
          </Text>
          <Text fontSize={"md"}>{visit.shopper?.email || "---"}</Text>
          {visit.shopper?._id && visit.status === "pending" && (
            <Button
              size={"xs"}
              alignSelf={"end"}
              variant={"outline"}
              colorScheme={"red"}
              leftIcon={<FiUserPlus />}
              isDisabled={isAddingMS}
              isLoading={isRemovingMS}
              onClick={removeMS}
            >
              GM Çıkar
            </Button>
          )}
        </VStack>
      </Card>
      <Card>
        <Flex align={"center"} justify={"space-between"}>
          <Heading size={"md"}>Gizli Müşteri Davetiyeleri</Heading>
          {visit.status === "pending" && (
            <Button
              size="sm"
              colorScheme="green"
              variant="outline"
              onClick={() =>
                showModal(
                  <NewVisitInvites
                    visit={visit}
                    mutateInvites={mutateInvites}
                  />
                )
              }
            >
              Yeni Davet
            </Button>
          )}
        </Flex>
        <ClientTable data={invites} columns={columns} />
      </Card>
    </>
  );
}

export function InviteStatusTag({ status, ...rest }) {
  const colors = {
    pending: "gray",
    accepted: "green",
    rejected: "red",
    expired: "gray",
  };

  const labels = {
    pending: "cevapsız",
    accepted: "kabul",
    rejected: "ret",
    expired: "sonlandı",
  };
  return (
    <Tag colorScheme={colors[status] || "gray"} {...rest}>
      {labels[status]}
    </Tag>
  );
}
