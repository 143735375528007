import { useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ProjectUpsertPage from "./projectUpsertPage";

export function ProjectUpsertContainer() {
  const toast = useToast();
  const { id } = useParams();

  const {
    data: project,
    error: projectError,
    mutate,
  } = useSWR(id ? `/projects/${id}` : null);

  const { data: companies, error: companiesError } = useSWR("/companies");
  const { data: admins, error: adminsError } = useSWR("/admins");

  if (projectError || companiesError || adminsError) {
    toast({
      title: "Bir hata oluştu.",
      description: "Lütfen tekrar deneyin.",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
  }

  if (id && (!project || !companies || !admins)) {
    return <LoadingIndicator />;
  }

  return (
    <ProjectUpsertPage
      project={project}
      mutateProject={mutate}
      companies={companies}
      admins={admins}
    />
  );
}
