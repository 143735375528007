import { Box, Button, Heading, Stack } from "@chakra-ui/react";
import Chart from "react-apexcharts";
import { useParams } from "react-router-dom";
import PageLayout from "../../../components/Layout/PageLayout";
import useSWR from "swr";

export function ProjectResultPage() {
  let { id } = useParams();
  const { data } = useSWR(`/project-raw-data/${id}`);
  const { id: projectId } = useParams();

  
  const umut = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: [
          "Ankara Ankara Ankara",
          "Isntabul Malltepe Avm",
          "Ankara Mallar Akademisi",
          "Bursa",
          1995,
          1996,
          1997,
          1998,
          1999,
        ],
      },
    },
    series: [
      {
        name: "series-1",
        data: [30, 40, 45, 50, 49, 60, 70, 43],
      },
    ],
  };
  const handleDownloadClick = async () => {
    try {
      // make a GET request to the endpoint
      const response = await fetch('http://localhost:3000/api/project-raw-data/'+projectId);
      console.log("asdasdsad",response)
      // get the CSV data from the response
      const csvData = await response.text();

      // create a new Blob object from the CSV data
      const blob = new Blob([csvData], { type: 'text/csv' });

      // create a URL for the Blob object
      const url = URL.createObjectURL(blob);

      // create an anchor element and set its attributes
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = 'data.csv';

      // programmatically click the anchor element to initiate the download
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <>
      <PageLayout>
        <Stack mt={12} spacing="24px">
          <Stack direction={"row"} spacing="24px">
            <Box bg={"white"} p={4} width={"4xl"} pt={6} pb={12}>
              <Heading as="h5" size="sm">
                Proje Sonuc Ekranı - {id}
              </Heading>
              sadf
            </Box>
          </Stack>
        </Stack>
  

        <Box bg={"white"} mt={8} p={4} shadow={"xl"}>
          <Chart
            options={umut.options}
            series={umut.series}
            type="bar"
            height={300}
          />
        </Box>
<br/>
        <Button  onClick={handleDownloadClick}>Download Raw Data</Button>

      </PageLayout>
    </>
  );
}
