class VisitApi {
  constructor(fetcher) {
    this.fetcher = fetcher;
  }

  async createOneForProject(projectId, data) {
    if (data.shopper === "") {
      delete data.shopper;
    }
    if (data.fieldManager === "") {
      delete data.fieldManager;
    }
    const res = await this.fetcher.post(`/projects/${projectId}/visits`, data);
    return res.data.data;
  }

  async updateOne(id, data) {
    const res = await this.fetcher.put(`/visits/${id}`, data);
    return res.data.data;
  }

  async deleteOne(id) {
    const res = await this.fetcher.delete(`/visits/${id}`);
    return res.data.data;
  }

  async actionExecuter(visitId, action, data = {}) {
    const res = await this.fetcher.put(`/visits/${visitId}/${action}`, data);
    return res.data.data;
  }
}

export default VisitApi;
