const questionUrl = "/questions";
const categoryUrl = "/categories";

class FormApi {
  constructor(fetcher) {
    this.fetcher = fetcher;
  }

  async createCategoryForProject(projectId, data) {
    const res = await this.fetcher.post(
      `projects/${projectId}/categories`,
      data
    );
    return res.data.data;
  }

  async updateCategory(id, data) {
    const res = await this.fetcher.put(`${categoryUrl}/${id}`, data);
    return res.data.data;
  }

  async deleteCategory(id) {
    const res = await this.fetcher.delete(`${categoryUrl}/${id}`);
    return res.data.data;
  }

  async createQuestionForProjectForCategory(projectId, categoryId, data) {
    const res = await this.fetcher.post(`projects/${projectId}/questions`, {
      category: categoryId,
      ...data,
    });
    return res.data.data;
  }

  async updateQuestion(id, data) {
    const res = await this.fetcher.put(`${questionUrl}/${id}`, data);
    return res.data.data;
  }

  async deleteQuestion(id) {
    const res = await this.fetcher.delete(`${questionUrl}/${id}`);
    return res.data.data;
  }

  async moveQuestionWithinCategory(categoryId, data) {
    const res = await this.fetcher.put(
      `/categories/${categoryId}/question-order`,
      data
    );
    return res.data.data;
  }

  async addSkipQuestionCriteria(id, data) {
    const res = await this.fetcher.put(
      `${questionUrl}/${id}/add-skip-question`,
      data
    );
    return res.data.data;
  }

  async deleteSkipQuestionCriteria(id) {
    const res = await this.fetcher.put(
      `${questionUrl}/${id}/delete-skip-question`
    );
    return res.data.data;
  }
}

export default FormApi;
