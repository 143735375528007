import { ChatIcon } from "@chakra-ui/icons";
import { Alert, AlertDescription, AlertTitle } from "@chakra-ui/react";

export default function NoData({ text }) {
  return (
    <>
      <Alert
        mt={8}
        status="warning"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="400px"
      >
        <ChatIcon boxSize="50px" mr={0} color="yellow.600" />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          Gösterilecek Veri Yok
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          Eğer bir sorun olduğunu düşünüyorsanız lütfen bizimle iletişime
          geçiniz.
        </AlertDescription>
      </Alert>
    </>
  );
}
