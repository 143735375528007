import { crmCompanyUrl } from "./apiFactory";
export const crmLocationUrl = "/locations";

class LocationApi {
  constructor(fetcher) {
    this.fetcher = fetcher;
  }

  async createOneForCompany(companyId, data) {
    const res = await this.fetcher.post(
      `${crmCompanyUrl}/${companyId}/locations`,
      data
    );
    return res.data.data;
  }

  async updateOne(id, data) {
    const res = await this.fetcher.put(`${crmLocationUrl}/${id}`, data);
    return res.data.data;
  }

  async deleteOne(id) {
    const res = await this.fetcher.delete(`${crmLocationUrl}/${id}`);
    return res.data.data;
  }
}

export default LocationApi;
