import { Tag } from "@chakra-ui/react";
import { visitStatus } from "../../../util/enums";

function getStatusColor(status) {
  const colors = {
    pending: "yellow",
    ms_confirmed: "blue",
    waiting_approval_admin: "purple",
    waiting_approval_fm: "purple",
    approved: "green",
    fm_approved: "green",
    rejected: "red",
    fm_rejected: "red",
    published: "pink",
  };
  return colors[status];
}

export function VisitStatusTag({ status, ...rest }) {
  return (
    <Tag colorScheme={getStatusColor(status)} {...rest}>
      {visitStatus[status]?.tr}
    </Tag>
  );
}
