import { useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { SectorUpsertPage } from "./sectorUpsertPage";
import LoadingIndicator from "../../../components/LoadingIndicator";

export function SectorUpsertContainer() {
  let { id } = useParams();
  const toast = useToast();

  const { data: sector, error, mutate } = useSWR(id ? `/sectors/${id}` : null);

  if (error) {
    toast({
      title: "Bir hata oluştu.",
      description: "Lütfen tekrar deneyin.",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
    return null;
  }

  if (id && !sector) {
    return <LoadingIndicator />;
  }

  return <SectorUpsertPage sector={sector} mutateSector={mutate} />;
}
