import {
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Stack,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../components/Layout/PageHeader";
import PageLayout from "../../../components/Layout/PageLayout";
import FormContainer from "../../../components/Form/FormContainer";
import FormFooter from "../../../components/Form/FormFooter";
import SelectWrapper from "../../../components/Form/SelectWrapper";
import PickDate from "../../../components/DatePicker/PickDate";
import { useApi } from "../../../contexts/ApiContext";
import Card from "../../../components/Layout/Card";
import { ProjectActionButton } from "./projectActionButton";

export default function ProjectUpsertPage({
  project,
  mutateProject,
  companies,
  admins,
}) {
  const navigate = useNavigate();
  const toast = useToast();
  const { projectApi } = useApi();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name: project?.name ?? "",
      company: project?.company._id ?? "", // combobox
      projectManager: project?.projectManager?._id ?? "", // combobox
      contractDate: project?.contractDate ?? "",
      startDate: project?.startDate ?? "",
      endDate: project?.endDate ?? "",
      description: project?.description ?? "",
    },
  });

  const onSubmit = async (data) => {
    try {
      if (project) {
        await projectApi.updateOne(project._id, data);
        mutateProject();
        toast({
          title: "Başarılı",
          description: "Proje başarıyla düzenlendi.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        if (data.projectManager === "") {
          // for new project, remove projectManager if none selected
          const { projectManager, ...dataWithoutProjectManager } = data;
          await projectApi.createOne({ ...dataWithoutProjectManager });
        } else {
          await projectApi.createOne(data);
        }
        toast({
          title: "Başarılı",
          description: "Yeni proje başarıyla eklendi.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      navigate("..");
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <PageLayout>
        <PageHeader
          title={project ? "Proje Düzenle" : "Yeni Proje Ekle"}
          backUrl={".."}
        >
          {project && (
            <ProjectActionButton
              projectId={project._id}
              status={project.status}
              isArchived={project.isArchived}
              mutateProject={mutateProject}
            />
          )}
        </PageHeader>
        <Card topMargin>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
              <Stack direction={["column", "row"]} spacing="24px">
                <FormControl isInvalid={errors.name}>
                  <FormLabel htmlFor="name">Proje Adı</FormLabel>
                  <Input
                    id="name"
                    placeholder="Abc"
                    {...register("name", {
                      required: "Gerekli alan",
                      minLength: {
                        value: 4,
                        message: "En az 4 karakter gerekli.",
                      },
                    })}
                  />
                  <FormErrorMessage>
                    {errors.name && errors.name.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.company}>
                  <FormLabel htmlFor="company">Firma</FormLabel>
                  <SelectWrapper
                    register={{
                      ...register("company", { required: "Gerekli alan" }),
                    }}
                    data={
                      companies &&
                      companies.map((item) => ({
                        value: item._id,
                        text: item.name,
                      }))
                    }
                  />
                  <FormErrorMessage>
                    {errors.company && errors.company.message}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
              <Stack direction={["column", "row"]} spacing="24px">
                <FormControl isInvalid={errors.projectManager}>
                  <FormLabel htmlFor="projectManager">
                    Proje Sorumlusu{" "}
                  </FormLabel>
                  <SelectWrapper
                    register={{
                      ...register("projectManager"),
                    }}
                    data={
                      admins &&
                      admins.map((item) => ({
                        value: item._id,
                        text: item.name,
                      }))
                    }
                  />
                </FormControl>
                <FormControl isInvalid={errors.contractDate}>
                  <FormLabel htmlFor="contractDate">Sözleşme Tarihi</FormLabel>
                  <Controller
                    control={control}
                    name="contractDate"
                    render={({ field }) => <PickDate field={field} />}
                  />
                </FormControl>
              </Stack>
              <Stack direction={["column", "row"]} spacing="24px">
                <FormControl isInvalid={errors.startDate}>
                  <FormLabel htmlFor="startDate">Başlangıç Tarihi</FormLabel>
                  <Controller
                    control={control}
                    name="startDate"
                    render={({ field }) => <PickDate field={field} />}
                  />
                </FormControl>
                <FormControl isInvalid={errors.endDate}>
                  <FormLabel htmlFor="endDate">Bitiş Tarihi</FormLabel>
                  <Controller
                    control={control}
                    name="endDate"
                    render={({ field }) => <PickDate field={field} />}
                  />
                </FormControl>
              </Stack>
              <FormControl isInvalid={errors.description}>
                <FormLabel htmlFor="description">Proje Detayı</FormLabel>
                <Textarea
                  variant={"filled"}
                  id="description"
                  placeholder="Proje detayı"
                  {...register("description")}
                />
              </FormControl>
              <FormFooter>
                <Button
                  colorScheme={"pink"}
                  isLoading={isSubmitting}
                  type="submit"
                >
                  Kaydet
                </Button>
              </FormFooter>
            </FormContainer>
          </form>
        </Card>
      </PageLayout>
    </>
  );
}
