import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useSWRConfig } from "swr";
import { useApi } from "../contexts/ApiContext";

export function useMSStatusActions() {
  const { mutate } = useSWRConfig();
  const { mysteryShopperApi } = useApi();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  async function changeStatus(id, status) {
    try {
      setIsLoading(true);
      await mysteryShopperApi.updateOne(id, { status });
      mutate(`/mystery-shoppers/${id}`);
      mutate("/mystery-shoppers/status/active");
      mutate("/mystery-shoppers/status/inactive");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast({
        title: "Bir hata oluştu.",
        status: "error",
      });
    }
  }

  async function changeApplicationStatus(id, applicationStatus) {
    try {
      setIsLoading(true);
      await mysteryShopperApi.updateOne(id, { applicationStatus });
      mutate(`/mystery-shoppers/${id}`);
      mutate("/mystery-shoppers/application-status/trainingCompleted");
      mutate("/mystery-shoppers/application-status/denied");
      mutate("/mystery-shoppers/status/active");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast({
        title: "Bir hata oluştu.",
        status: "error",
      });
    }
  }

  function makeMsActive(id) {
    changeStatus(id, "active");
  }

  function makeMsPassive(id) {
    changeStatus(id, "inactive");
  }

  function approveMsApplication(id) {
    changeApplicationStatus(id, "approved");
  }

  function denyMsApplication(id) {
    changeApplicationStatus(id, "denied");
  }

  return {
    isLoading,
    makeMsActive,
    makeMsPassive,
    approveMsApplication,
    denyMsApplication,
  };
}
