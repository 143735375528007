import InputMask from "react-input-mask";
import { Input } from "@chakra-ui/react";
import { Controller } from "react-hook-form";

export function MaskedDateInput({ name, control }) {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value },
        // field: { onChange, onBlur, value, name, ref },
        // fieldState: { invalid, isTouched, isDirty, error },
        // formState,
      }) => (
        <InputMask mask="99-99-9999" value={value} onChange={onChange}>
          {(inputProps) => (
            <Input {...inputProps} id={name} placeholder="GG-AA-YYYY" />
          )}
        </InputMask>
      )}
      rules={{
        required: { value: true, message: "Gerekli alan" },
        validate: (value) => {
          const dateArray = value.split("-");
          const [day, month, year] = dateArray;
          const valid =
            new Date(`${year}-${month}-${day}`).getDate() === parseInt(day);
          return valid || "Geçersiz tarih";
        },
      }}
    />
  );
}
