import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  ModalBody,
  ModalFooter,
  FormErrorMessage,
  Select,
  Stack,
  Textarea,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import { useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useApi } from "../../../contexts/ApiContext";
import SelectWrapper from "../../../components/Form/SelectWrapper";
import PickDate from "../../../components/DatePicker/PickDate";
import { GoalMultiSelect } from "../form/category/goalMultiSelect";

export function PlanUpsertComp({
  visit,
  mutate,
  projectId,
  locations,
  shoppers,
  fieldManagers,
  projectBreakdowns,
  scenarios,
  closeModal,
  mutateVisits,
  fm,
}) {
  const toast = useToast();
  const { visitApi } = useApi();
  let scenarioSelectRef = useRef("");

  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      location: visit?.location?._id ?? "",
      shopper: visit?.shopper?._id ?? "",
      fieldManager: fm ? fm : visit?.fieldManager?._id ?? "",
      plannedStartDate: visit?.plannedStartDate ?? "",
      plannedEndDate: visit?.plannedEndDate ?? "",
      visitGoals: visit?.visitGoals ?? [],
      // additionalBreakdowns from server = [ { breakdown: id, selectedOption: optionId }, ...]
      // additionalBreakdowns in form = { id: optionId, ...}
      additionalBreakdowns: visit?.additionalBreakdowns?.reduce((acc, cur) => {
        return {
          [cur.breakdown]: cur.selectedOption,
          ...acc,
        };
      }, {}),
      scenario: visit?.scenario ?? "",
    },
  });

  const watchFM = watch("fieldManager");
  const shoppersForFM =
    watchFM === ""
      ? shoppers
      : shoppers?.filter((ms) => ms.fieldMgr?._id === watchFM);

  const notInitialRender = useRef(false);
  useEffect(() => {
    // Do not change shopper to "" at initial render. Keep the default value!
    if (notInitialRender.current) {
      if (watchFM !== "") setValue("shopper", "");
    } else {
      notInitialRender.current = true;
    }
  }, [watchFM]);

  const onSubmit = async (data) => {
    if (data.additionalBreakdowns) {
      data.additionalBreakdowns = Object.keys(data.additionalBreakdowns).map(
        (breakdownId) => {
          return {
            breakdown: breakdownId,
            selectedOption: data.additionalBreakdowns[breakdownId],
          };
        }
      );
    } else {
      data.additionalBreakdowns = [];
    }
    try {
      if (visit) {
        await visitApi.updateOne(visit._id, data);
        mutate();
        mutateVisits();
        toast({
          title: "Başarılı",
          description: "Ziyaret düzenlendi.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await visitApi.createOneForProject(projectId, data);
        mutateVisits();
        toast({
          title: "Başarılı",
          description: "Yeni ziyaret başarıyla eklendi.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    closeModal();
  };

  return (
    <>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={["column"]} spacing={4}>
            <Stack direction={"column"}>
              <FormControl>
                <FormLabel htmlFor="scenario-template">
                  Senaryo Şablonu
                </FormLabel>
                <Stack direction={"row"}>
                  <Select
                    variant={"filled"}
                    placeholder="Lütfen seçiniz"
                    name="scenario-template"
                    ref={scenarioSelectRef}
                  >
                    {scenarios?.map((scenario) => (
                      <option key={scenario._id} value={scenario.description}>
                        {scenario.title}
                      </option>
                    ))}
                  </Select>
                  <IconButton
                    colorScheme="blue"
                    aria-label="Senaryo şablonu ekle"
                    icon={<CopyIcon />}
                    onClick={() => {
                      setValue("scenario", scenarioSelectRef.current.value);
                    }}
                  />
                </Stack>
              </FormControl>
              <FormControl isInvalid={errors.scenario}>
                <FormLabel htmlFor="scenario">Senaryo</FormLabel>
                <Textarea
                  variant={"filled"}
                  id={"scenario"}
                  {...register("scenario")}
                />
                <FormErrorMessage>
                  {errors.scenario && errors.scenario.message}
                </FormErrorMessage>
              </FormControl>
            </Stack>
            <Divider borderColor="blue.300" />

            <Stack direction={["column", "row"]} spacing={4}>
              <FormControl isInvalid={errors.location} isDisabled={visit}>
                <FormLabel htmlFor="location">Lokasyon</FormLabel>
                <SelectWrapper
                  register={{
                    ...register("location", { required: "Gerekli alan" }),
                  }}
                  data={
                    locations &&
                    locations.map((item) => ({
                      value: item._id,
                      text: item.name,
                    }))
                  }
                />
                <FormErrorMessage>
                  {errors.location && errors.location.message}
                </FormErrorMessage>
              </FormControl>

              {projectBreakdowns?.length % 2 === 1 && (
                <BreakdownSelect
                  breakdown={projectBreakdowns[0]}
                  register={register}
                  errors={errors}
                />
              )}
            </Stack>

            {projectBreakdowns?.length > 1 && (
              <BreakdownSelectList
                breakdowns={projectBreakdowns}
                register={register}
                errors={errors}
              />
            )}

            <Stack direction={["column", "row"]} spacing={4}>
              <FormControl isInvalid={errors.fieldManager} isDisabled={fm}>
                <FormLabel htmlFor="fieldManager">Saha Çalışanı</FormLabel>
                <SelectWrapper
                  register={{
                    ...register("fieldManager"),
                  }}
                  data={
                    fieldManagers &&
                    fieldManagers.map((item) => ({
                      value: item._id,
                      text: item.name,
                    }))
                  }
                />
                <FormErrorMessage>
                  {errors.fieldManager && errors.fieldManager.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.shopper}>
                <FormLabel htmlFor="shopper">Gizli Müşteri</FormLabel>
                <SelectWrapper
                  register={{
                    ...register("shopper"),
                  }}
                  data={
                    shoppersForFM &&
                    shoppersForFM.map((ms) => ({
                      value: ms._id,
                      text: ms.name,
                    }))
                  }
                />
                <FormErrorMessage>
                  {errors.shopper && errors.shopper.message}
                </FormErrorMessage>
              </FormControl>
            </Stack>

            <Stack direction={["column", "row"]} spacing={4}>
              <FormControl isInvalid={errors.plannedStartDate}>
                <FormLabel htmlFor="plannedStartDate">
                  Planlanan Başlangıç Tarihi
                </FormLabel>
                <Controller
                  control={control}
                  name="plannedStartDate"
                  render={({ field }) => <PickDate field={field} />}
                />
              </FormControl>
              <FormControl isInvalid={errors.plannedEndDate}>
                <FormLabel htmlFor="plannedEndDate">
                  Planlanan Bitiş Tarihi
                </FormLabel>
                <Controller
                  control={control}
                  name="plannedEndDate"
                  render={({ field }) => <PickDate field={field} />}
                />
              </FormControl>
            </Stack>
            <Divider borderColor="blue.300" />

            <GoalMultiSelect
              name={"visitGoals"}
              control={control}
              projectId={projectId}
              gridColumns={3}
            />
          </Stack>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button variant="outline" mr={3} onClick={closeModal}>
          Vazgeç
        </Button>
        <Button
          colorScheme={"pink"}
          isLoading={isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          Kaydet
        </Button>
      </ModalFooter>
    </>
  );
}

function BreakdownSelect({ breakdown, register, errors }) {
  const { _id: id, name, options } = breakdown;
  return (
    <FormControl key={id} isInvalid={errors.additionalBreakdowns?.[id]}>
      <FormLabel>{name}</FormLabel>
      <SelectWrapper
        register={{
          ...register(`additionalBreakdowns.${id}`, {
            required: "Gerekli alan",
          }),
        }}
        data={
          options &&
          options.map((option) => ({
            value: option._id,
            text: option.optionText,
          }))
        }
      />
      <FormErrorMessage>
        {errors.additionalBreakdowns?.[id]?.message}
      </FormErrorMessage>
    </FormControl>
  );
}

function BreakdownSelectList({ breakdowns, register, errors }) {
  const numberOfRows = Math.ceil(
    breakdowns.length % 2 === 0
      ? breakdowns.length / 2
      : (breakdowns.length - 1) / 2
  );

  const nestedBreakdowns = [];
  for (let i = 0; i < numberOfRows; i++) {
    if (breakdowns.length % 2 === 0) {
      nestedBreakdowns.push([breakdowns[i * 2], breakdowns[i * 2 + 1]]);
    } else {
      nestedBreakdowns.push([breakdowns[i * 2 + 1], breakdowns[i * 2 + 2]]);
    }
  }

  return nestedBreakdowns.map((row, index) => {
    return (
      <Stack key={index} direction={["column", "row"]} spacing={4}>
        {row.map((breakdown) => (
          <BreakdownSelect
            key={breakdown._id}
            breakdown={breakdown}
            register={register}
            errors={errors}
          />
        ))}
      </Stack>
    );
  });
}
