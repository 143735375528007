import { useToast } from "@chakra-ui/react";
import useSWR from "swr";
import PageLayout from "../../../components/Layout/PageLayout";
import LoadingIndicator from "../../../components/LoadingIndicator";
import {
  MSApplicationForm,
  MSTrainingPage,
  MSNotificationPage,
  MSProfilePage,
} from ".";

export default function MSProfileContainer({ id }) {
  const toast = useToast();
  const {
    data: mysteryShopper,
    error,
    mutate,
  } = useSWR(`/mystery-shoppers/${id}`);

  if (error) {
    toast({
      title: "Bir hata oluştu.",
      status: "error",
    });
  }

  if (!mysteryShopper) {
    return <LoadingIndicator />;
  }

  let componentToRender = null;
  const { applicationStatus } = mysteryShopper;
  switch (applicationStatus) {
    case "pending":
      componentToRender = (
        <MSApplicationForm ms={mysteryShopper} mutateMs={mutate} />
      );
      break;
    case "applicationFormCompleted":
      componentToRender = (
        <MSTrainingPage ms={mysteryShopper} mutateMs={mutate} />
      );
      break;
    case "trainingCompleted":
    case "denied":
      componentToRender = <MSNotificationPage status={applicationStatus} />;
      break;
    case "approved":
      componentToRender = (
        <MSProfilePage ms={mysteryShopper} mutateMs={mutate} />
      );
      break;
    default:
      componentToRender = <LoadingIndicator />;
  }

  return <PageLayout>{componentToRender}</PageLayout>;
}
