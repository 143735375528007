import { useMemo } from "react";
import ClientTable from "../../components/Table/ClientTable";
import { Link, useParams } from "react-router-dom";
import useSWR from "swr";
import LoadingIndicator from "../../components/LoadingIndicator";
import { format } from "date-fns";
import { VisitStatusTag } from "../pms/plan/visitStatusTag";
// import { VisitStatusTag } from "../../pms/plan/visitStatusTag";

// Admin Disindaki rollericin kullanilan tablo. Admin icin planIndexpage kullaniliyor.

export function VisitTable({ visits, lm }) {
  const columns = useMemo(
    () => [
      {
        Header: "Ziyaret Adi",
        accessor: "name",
        Cell: ({ cell }) => {
          const href = lm
            ? `lm/visits/${cell.row.original._id}`
            : `${cell.row.original._id}`;
          return <Link to={href}>{cell.row.values.name}</Link>;
        },
      },
      {
        Header: "Lokasyon",
        accessor: "location.name",
      },
      {
        Header: "Durum",
        accessor: "status",
        Cell: ({ cell }) => (
          <>
            <VisitStatusTag status={cell.row.values.status} size={"sm"} />
          </>
        ),
      },

      {
        Header: "Performans",
        accessor: "performance",
        Cell: ({ cell }) => <>{"%" + cell.row.values.performance}</>,
      },

      {
        Header: "Ziyaret Tarihi",
        accessor: "visitDate",
        Cell: ({ cell }) => (
          <>
            {cell.row.values.visitDate
              ? format(new Date(cell.row.values.visitDate), "dd-MM-yyyy")
              : "---"}{" "}
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      {!visits ? (
        <LoadingIndicator />
      ) : (
        <ClientTable data={visits} columns={columns} />
      )}
    </>
  );
}
