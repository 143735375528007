import { Box, Divider, Flex, Icon, Stack } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

// linkItems = [ { name, icon, to, quantity }, ...]
export default function SplitPageWithSideNav({
  linkItems,
  children,
  firstItemAlwaysBold,
}) {
  return (
    <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
      <Box
        // pt={{ base: 2 }}
        pb={2}
        m={8}
        // boxShadow="lg"
        borderRadius="lg"
        borderWidth="12 px"
        // bg={"blackAlpha.200"}
        // w={{ base: "full", md: 60 }}
        minW="260px"
      >
        {linkItems.map((link, index) => (
          <div key={link.name}>
            <NavItem
              icon={link.icon}
              to={link.to}
              firstItemAlwaysBold={index === 0 && firstItemAlwaysBold}
            >
              {link.name}
              {link.quantity && ` (${link.quantity})`}
            </NavItem>
            {link.divider && <Divider borderColor="gray.300" />}
          </div>
        ))}
      </Box>
      <Box w={{ base: "full" }}>{children}</Box>
    </Stack>
  );
}

const NavItem = ({ to, icon, children, firstItemAlwaysBold, ...rest }) => {
  return (
    <NavLink to={to}>
      {({ isActive }) => (
        <Flex
          bg={isActive && "gray.700"}
          color={isActive && "white"}
          fontWeight={isActive || firstItemAlwaysBold ? "bold" : "normal"}
          align="center"
          px="4"
          py="2"
          my={{ base: 1, md: 2 }}
          borderRadius="12"
          role="group"
          cursor="pointer"
          _hover={{
            bg: "gray.50",
            color: "gray.600",
          }}
          _active={{
            bg: "gray.100",
            color: "gray.600",
          }}
          {...rest}
        >
          {icon && (
            <Icon
              mr="3"
              fontSize="18"
              _groupHover={{
                color: "gray.600",
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      )}
    </NavLink>
  );
};
