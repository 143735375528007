class FileUploadApi {
  constructor(fetcher) {
    this.fetcher = fetcher;
  }

  async uploadAvatarForUser(userId, data) {
    const formData = new FormData();
    formData.append("avatar", data.avatar[0]);
    const res = await this.fetcher.post(
      `upload/user/${userId}/avatar`,
      formData
    );
    return res.data.data;
  }

  async uploadImageForVisit(visitId, data) {
    const formData = new FormData();
    formData.append("image", data.image[0]);
    const res = await this.fetcher.post(
      `upload/visit/${visitId}/image`,
      formData
    );
    return res.data.data;
  }

  async deleteImageForVisit(visitId, fileName) {
    const res = await this.fetcher.delete(
      `upload/visit/${visitId}/image/${fileName}`
    );
    return res.data.data;
  }

  async uploadLogoForCompany(companyId, data) {
    const formData = new FormData();
    formData.append("logo", data.logo[0]);
    const res = await this.fetcher.post(
      `upload/company/${companyId}/logo`,
      formData
    );
    return res.data.data;
  }
}

export default FileUploadApi;
