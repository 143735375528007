import Select from "react-select";
import useSWR from "swr";
import { useState } from "react";
import ReportChart from "../../components/Reporting/ReportChart";
import { Box, Flex } from "@chakra-ui/react";
import PageHeader from "../../components/Layout/PageHeader";
import PageLayout from "../../components/Layout/PageLayout";
import { useParams } from "react-router";
import Card from "../../components/Layout/Card";

export function BreakdownBasedReportPage() {
  const { projectId, baseBreakdownId } = useParams();
  const [category, setCategory] = useState(false);
  const [location, setLocation] = useState(false);
  const [breakdown, setBreakdown] = useState([]);

  const { data: project } = useSWR(`/projects/${projectId}`);
  const { data: categories } = useSWR(`/projects/${projectId}/categories`);
  const { data: breakdowns } = useSWR(
    `/projects/${projectId}/project-breakdowns`
  );
  const { data: locations } = useSWR(
    `/companies/${project?.company._id}/locations`
  );
  const baseBreakdown = breakdowns?.find(
    (x) => x._id.toString() === baseBreakdownId.toString()
  );

  const categoryOptions = categories?.map((category) => ({
    value: category._id,
    label: category.name,
  }));

  const onSelectCategory = (event) => {
    setCategory(event.value);
  };
  const locationOptions = locations?.map((category) => ({
    value: category._id,
    label: category.name,
  }));

  const onSelectLocation = (event) => {
    setLocation(event.value);
  };

  const onSelectBreakDown = ({ breakdownId, value }) => {
    setBreakdown((curr) => ({ ...curr, [breakdownId]: value }));
  };

  return (
    <>
      <PageLayout>
        <Card mt={8} type="white">
          <PageHeader
            inBox
            title={baseBreakdown && baseBreakdown.name + " Bazlı Raporlar"}
          />
          <Flex gap={4}>
            <Box w="100%">
              <Select
                placeholder={"Hizmet Kategorisi"}
                options={categoryOptions}
                onChange={onSelectCategory}
              />
            </Box>
            <Box w="100%">
              <Select
                placeholder={"Lokasyon"}
                options={locationOptions}
                onChange={onSelectLocation}
              />
            </Box>
            {breakdowns?.map((breakdown) => {
              if (breakdown._id !== baseBreakdownId) {
                return (
                  <Box w="100%" key={breakdown._id}>
                    <Select
                      placeholder={breakdown.name}
                      name={breakdown._id}
                      options={breakdown.options.map((option) => ({
                        value: option._id,
                        label: option.optionText,
                        breakdownId: breakdown._id,
                      }))}
                      onChange={onSelectBreakDown}
                    />
                  </Box>
                );
              }
            })}
          </Flex>
        </Card>

        <ReportChart
          reportType={"breakdown-based"}
          category={category}
          location={location}
          breakdown={breakdown}
          baseBreakdownId={baseBreakdownId}
          projectId={projectId}
        />
      </PageLayout>
    </>
  );
}
