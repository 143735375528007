import { Route } from "react-router-dom";
import SettingsPage from "../pages/settings/settingsPage";
import {
  UserIndexPage,
  NewUserPage,
  UserDetailPage,
} from "../pages/settings/users";

export const settingsRoutes = (
  <Route path="settings" element={<SettingsPage />}>
    <Route index element={<UserIndexPage />} />
    <Route path="users">
      <Route index element={<UserIndexPage />} />
      <Route path="new" element={<NewUserPage />} />
      <Route path=":id" element={<UserDetailPage />} />
    </Route>
    <Route path="email" element={<div>Email Settings</div>} />
    <Route path="kvkk" element={<div>KVKK Logs</div>} />
    <Route path="api-tokens" element={<div>Api Tokens</div>} />
  </Route>
);
