import { useParams } from "react-router-dom";
import useSWR from "swr";
import PageHeader from "../../../components/Layout/PageHeader";
import PageLayout from "../../../components/Layout/PageLayout";

export function MsVisitFinancialsPage() {
  let { id } = useParams();
  const { data: visit, mutate } = useSWR(`/visits/${id}`);

  return (
    <PageLayout>
      <PageHeader title={visit?.name} backUrl="../approved"></PageHeader>
      <div>FINANCIALS FOR VISIT {id}</div>
    </PageLayout>
  );
}
