import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react";
import { FiPlus, FiMoreHorizontal } from "react-icons/fi";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import PageHeader from "../../../components/Layout/PageHeader";
import PageLayout from "../../../components/Layout/PageLayout";
import Card from "../../../components/Layout/Card";
import ClientTable from "../../../components/Table/ClientTable";
import LoadingIndicator from "../../../components/LoadingIndicator";
import DeleteConfirmAlert from "../../../components/Alert/DeleteConfirmAlert";
import ProjectBreakDownUpsert from "./projectBreakDownUpsert";
import { useUiContext } from "../../../contexts/UiContext";
import { useApi } from "../../../contexts/ApiContext";

export function ProjectBreakDownsIndexPage() {
  const toast = useToast();
  const { showAlert, closeAlertBox } = useUiContext();
  const { id: projectId } = useParams();
  const { data: projectBreakdowns, mutate } = useSWR(
    `/projects/${projectId}/project-breakdowns`
  );
  const { data: project } = useSWR(`/projects/${projectId}`);
  const { projectBreakdownApi } = useApi();

  const columns = useMemo(
    () => [
      {
        Header: "KIRILIM ADI",
        accessor: "name",
      },
      {
        Header: "SEÇENEKLER",
        accessor: (row) => row.options.map((opt) => opt.optionText).join(" / "),
      },

      {
        Header: "",
        accessor: "_id",
        isNumeric: true,
        Cell: ({ cell }) => (
          <Menu>
            <MenuButton
              variant="ghost"
              size="sm"
              as={IconButton}
              icon={<FiMoreHorizontal />}
            />
            <MenuList>
              <MenuItem
                onClick={() => {
                  showAlert(
                    <ProjectBreakDownUpsert
                      projectBreakdown={{
                        id: cell.row.values._id,
                        name: cell.row.values.name,
                        options: cell.row.original.options,
                      }}
                      projectId={projectId}
                      onClose={closeAlertBox}
                      mutateProjectBreakdowns={mutate}
                    />
                  );
                }}
              >
                Düzenle
              </MenuItem>
              <MenuItem
                onClick={() => {
                  showAlert(
                    <DeleteConfirmAlert
                      name={"Ziyaret Kırılımı"}
                      onDelete={() => deleteRow(cell.row.values._id)}
                    />
                  );
                }}
              >
                Sil
              </MenuItem>
            </MenuList>
          </Menu>
        ),
      },
    ],
    []
  );

  async function deleteRow(id) {
    try {
      await projectBreakdownApi.deleteOne(id);
      mutate();
      toast({
        title: "Başarılı",
        description: "Ziyaret kırılımı silindi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  return (
    <PageLayout>
      <PageHeader title="Ziyaret Kırılımları">
        {!project?.isArchived && (
          <Button
            leftIcon={<FiPlus />}
            size="sm"
            variant="outline"
            colorScheme="teal"
            onClick={() => {
              showAlert(
                <ProjectBreakDownUpsert
                  projectId={projectId}
                  onClose={closeAlertBox}
                  mutateProjectBreakdowns={mutate}
                />
              );
            }}
          >
            Yeni Ekle
          </Button>
        )}
      </PageHeader>
      <Card topMargin>
        {!projectBreakdowns ? (
          <LoadingIndicator />
        ) : (
          <ClientTable data={projectBreakdowns} columns={columns} />
        )}
      </Card>
    </PageLayout>
  );
}
