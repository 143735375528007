import { Box } from "@chakra-ui/react";
import Card from "../../../../components/Layout/Card";
import { VisitPerformanceTable } from "../../../reporting";

export function VisitPerfomance({ id }) {
  return (
    <Box>
      <Card>
        <VisitPerformanceTable visitId={id} />
        <hr />
      </Card>
    </Box>
  );
}
