import DatePicker, { registerLocale } from "react-datepicker";
import tr from "date-fns/locale/tr"; // the locale you want
import { toDate } from "date-fns";

export default function PickDate({ field, placeholder }) {
  registerLocale("tr", tr);
  const { value, onChange } = field;

  return (
    <DatePicker
      placeholderText={placeholder ?? "Tarih Seçiniz"}
      onChange={(date) => {
        if (date?.getHours() === 0) {
          // save the date at midnight in UTC-0
          onChange(new Date(date.getTime() - date.getTimezoneOffset() * 60000));
        } else {
          onChange(date);
        }
      }}
      dateFormat="dd/MM/yyyy"
      selected={value ? toDate(new Date(value)) : null}
      locale="tr"
    />
  );
}
