import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Stack,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import FormContainer from "../../../components/Form/FormContainer";
import FormFooter from "../../../components/Form/FormFooter";
import StandardFormControl from "../../../components/Form/StandardFormControl";
import { useApi } from "../../../contexts/ApiContext";
import { useAuth } from "../../../contexts/AuthContext";
import { regexEmail, regexPassword } from "../../../util/regex";

// TODO: phone regex
export function UserUpdateForm({
  user,
  mutateUser,
  showStatus,
  disablePassword,
}) {
  const toast = useToast();
  const { userApi } = useApi();
  const { isAdmin } = useAuth();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name: user.name,
      email: user.email,
      password: "",
      phone: user.phone,
      status: user.status === "active" ? true : false,
      // picture: user.picture,
    },
  });

  const onSubmit = async (data) => {
    try {
      const { status, ...dataWithoutStatus } = data;
      data.status = status ? "active" : "inactive";
      // do not send status, if it is not included in the form
      let serverData = showStatus ? { ...data } : { ...dataWithoutStatus };

      // if password is empty don't send it!
      const { password, ...serverDataWithoutPassword } = serverData;
      serverData =
        password === "" ? { ...serverDataWithoutPassword } : { ...serverData };

      // if current user is non-admin, do not send email. Only admin can update user emails.
      const { email, ...serverDataWithoutEmail } = serverData;
      serverData = isAdmin()
        ? { ...serverData }
        : { ...serverDataWithoutEmail };

      await userApi.updateOne(user._id, serverData);
      mutateUser();
      toast({
        title: "Başarılı",
        description: "Bilgiler güncellendi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer>
        <Stack direction={{ base: "column", md: "row" }} spacing="24px">
          <StandardFormControl errors={errors} name="name" label="Ad Soyad">
            <Input
              id={"name"}
              {...register("name", { required: "Gerekli alan" })}
            />
          </StandardFormControl>

          <StandardFormControl errors={errors} name="email" label="E-Posta">
            <Input
              id="email"
              isDisabled={!isAdmin()}
              {...register("email", {
                required: "Gerekli alan",
                pattern: {
                  value: regexEmail,
                  message: "Geçersiz e-posta",
                },
              })}
            />
          </StandardFormControl>
        </Stack>

        <Stack direction={{ base: "column", md: "row" }} spacing="24px">
          <StandardFormControl
            errors={errors}
            name="password"
            label={
              <Tooltip
                label="Şifre değiştirmek istemiyorsanız lütfen boş bırakınız."
                placement="right-end"
              >
                Şifre ℹ️
              </Tooltip>
            }
          >
            <Input
              id="password"
              type="password"
              isDisabled={disablePassword ? true : false}
              {...register("password", {
                validate: function (pass) {
                  if (pass === "" || regexPassword.test(pass)) {
                    return true;
                  }
                  return "En az 8 karakter giriniz ya da boş bırakınız";
                },
              })}
            />
          </StandardFormControl>

          <StandardFormControl errors={errors} name="phone" label="Telefon">
            <Input id={"phone"} {...register("phone")} />
          </StandardFormControl>
        </Stack>

        {showStatus && (
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="status" mb="0">
              Aktif?
            </FormLabel>
            <Controller
              name="status"
              control={control}
              render={({ field }) => {
                return (
                  <Switch
                    id="status"
                    colorScheme="pink"
                    {...field}
                    isChecked={field.value ? true : false}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                );
              }}
            />
          </FormControl>
        )}

        <FormFooter>
          <Button colorScheme={"pink"} isLoading={isSubmitting} type="submit">
            Gönder
          </Button>
        </FormFooter>
      </FormContainer>
    </form>
  );
}
