import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import { FreeTextAnswer, MultiSelectAnswer, SingleSelectAnswer } from "./";
import { formatHtmlWithChakra } from "../../../../../util/stringUtils";

export function VisitQuestion({ question, answer, index }) {
  const { _id: id, questionText, questionType, extraNote, choices } = question;

  // TODO: if you want to add format for skipped questions
  const skipQuestion = false;

  return (
    <VStack
      align={"start"}
      // bg={"whiteAlpha.900"}
      p={4}
      marginY={4}
      border={"1px"}
      borderRadius={"md"}
      borderColor={"gray.300"}
      background={"gray.50"}
      // ml={4}
    >
      {/* <span size={"sm"}>{index + 1}-</span> */}
      <Box
        dangerouslySetInnerHTML={{
          __html: formatHtmlWithChakra(questionText),
        }}
        sx={skipQuestion && { filter: "opacity(50%)" }}
      />
      {questionType === "freeText" && <FreeTextAnswer answer={answer} />}
      {questionType === "singleSelect" && (
        <SingleSelectAnswer choices={choices} answer={answer} />
      )}
      {questionType === "multiSelect" && (
        <MultiSelectAnswer choices={choices} answer={answer} />
      )}
      {extraNote && <Text color={"gray.500"}>Notlar: {extraNote}</Text>}
    </VStack>
  );
}
