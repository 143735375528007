import { createContext, useContext, useEffect, useState } from "react";
import { apiPublicFetch } from "../util/fetch";
import { decodeToken } from "../util/jwt-token";

const AuthContext = createContext();

// wrapper for the auth context provider
export function AuthContextProvider({ children }) {
  // auth state
  function initialState() {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    return {
      token,
      user: user ? JSON.parse(user) : {},
    };
  }
  const [authState, setAuthState] = useState(() => initialState());

  // once the authState changes, update the local storage accordingly
  useEffect(() => {
    const { token, user } = authState;
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));
  }, [authState]);

  async function login({ email, password }) {
    try {
      const res = await apiPublicFetch.post("/auth", {
        email,
        password,
      });
      const { jwt: token, user } = res.data;
      setAuthState({
        token,
        user,
      });
    } catch (error) {
      throw error;
    }
  }

  function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setAuthState({
      token: null,
      user: {},
    });
  }

  function isAuthenticated() {
    const { token } = authState;
    const decodedToken = decodeToken(token);

    if (
      decodedToken &&
      decodedToken.hasOwnProperty("exp") &&
      decodedToken.exp * 1000 > Date.now()
    ) {
      return true;
    }
    return false;
  }

  function getCurrentUserId() {
    const { token } = authState;
    return token ? decodeToken(token).id : null;
  }

  function isMS() {
    const {
      user: { role },
    } = authState;
    return role === "ms";
  }
  function isCompanyMgr() {
    const {
      user: { role },
    } = authState;
    return role === "companyMgr";
  }
  function isLocationMgr() {
    const {
      user: { role },
    } = authState;
    return role === "locationMgr";
  }
  function isFieldMgr() {
    const {
      user: { role },
    } = authState;
    return role === "fieldMgr";
  }
  function isAdmin() {
    const {
      user: { role },
    } = authState;
    return role === "admin";
  }

  return (
    <AuthContext.Provider
      value={{
        authState,
        isAuthenticated,
        login,
        logout,
        getCurrentUserId,
        isMS,
        isFieldMgr,
        isCompanyMgr,
        isLocationMgr,
        isAdmin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

// custom auth hook
export function useAuth() {
  return useContext(AuthContext);
}
