import { Button } from "@chakra-ui/react";
import { FiCheck, FiUserPlus, FiUserX, FiX } from "react-icons/fi";
export function MSActionButtonsList({
  ms,
  handlePassive,
  handleActive,
  handleApprove,
  handleDeny,
}) {
  const { _id: id, status, applicationStatus } = ms;

  const passiveButton = (
    <Button
      key="0"
      colorScheme="purple"
      size="sm"
      leftIcon={<FiUserX />}
      onClick={() => handlePassive(id)}
    >
      Pasife Al
    </Button>
  );
  const activeButton = (
    <Button
      key="1"
      colorScheme="green"
      size="sm"
      leftIcon={<FiUserPlus />}
      onClick={() => handleActive(id)}
    >
      Aktive Et
    </Button>
  );
  const approveButton = (
    <Button
      key="2"
      colorScheme="green"
      size="sm"
      leftIcon={<FiCheck />}
      onClick={() => handleApprove(id)}
    >
      Onayla
    </Button>
  );
  const denyButton = (
    <Button
      key="3"
      colorScheme="red"
      size="sm"
      leftIcon={<FiX />}
      onClick={() => handleDeny(id)}
    >
      Reddet
    </Button>
  );

  let list = [];

  switch (applicationStatus) {
    case "approved":
      list = [passiveButton];
      break;
    case "trainingCompleted":
      list = [approveButton, denyButton];
      break;
    case "denied":
      list = [approveButton];
      break;
    default:
      list = [];
  }

  if (status === "inactive") {
    list = [activeButton];
  }

  return list;
}
