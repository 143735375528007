import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";

export default function StandardFormControl({
  errors,
  name,
  label,
  children,
  ...props
}) {
  return (
    <FormControl isInvalid={errors[name]} {...props}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      {children}
      <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
    </FormControl>
  );
}
