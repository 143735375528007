import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export function RequireAuth({ children }) {
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated()) {
    return <Navigate to="/" />;
  }
  return children;
}
