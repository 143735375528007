import {
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import FormContainer from "../../../components/Form/FormContainer";
import { useForm } from "react-hook-form";
import PageHeader from "../../../components/Layout/PageHeader";
import PageLayout from "../../../components/Layout/PageLayout";
import { useApi } from "../../../contexts/ApiContext";
import { useNavigate } from "react-router-dom";
import FormFooter from "../../../components/Form/FormFooter";
import Card from "../../../components/Layout/Card";

export function SectorUpsertPage({ sector, mutateSector }) {
  const toast = useToast();
  const navigate = useNavigate();
  const { sectorApi } = useApi();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name: sector?.name ?? "",
    },
  });

  const onSubmit = async (data) => {
    try {
      if (sector) {
        await sectorApi.updateOne(sector._id, data);
        mutateSector();
        toast({
          title: "Başarılı",
          description: "Sektör başarıyla düzenlendi.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await sectorApi.createOne(data);
        toast({
          title: "Başarılı",
          description: "Yeni sektör başarıyla eklendi.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      navigate("..");
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <PageLayout>
      <PageHeader
        title={sector ? "Sektör Düzenle" : "Yeni Sektör Ekle"}
        backUrl={".."}
      />
      <Card topMargin>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContainer>
            <FormControl isInvalid={errors.name}>
              <FormLabel htmlFor="name">Sektör</FormLabel>
              <Input
                id="name"
                placeholder="Sektör Adı"
                {...register("name", {
                  required: "Gerekli alan",
                  minLength: {
                    value: 3,
                    message: "En az 3 karakter gerekli.",
                  },
                })}
              />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
            <FormFooter>
              <Button
                colorScheme={"pink"}
                isLoading={isSubmitting}
                type="submit"
              >
                Kaydet
              </Button>
            </FormFooter>
          </FormContainer>
        </form>
      </Card>
    </PageLayout>
  );
}
