import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";

export default function RadioFormControl({
  control,
  errors,
  name,
  label,
  children,
}) {
  return (
    <FormControl as="fieldset" id={name} isInvalid={errors[name]}>
      <FormLabel as="legend">{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <RadioGroup {...field}>
            <Stack
              direction="row"
              spacing="24px"
              bg="gray.100"
              h="40px"
              pb="1px"
              px="16px"
              borderRadius="md"
              border="2px"
              borderColor={errors[name] ? "#E53E3E" : "transparent"}
            >
              {children}
            </Stack>
          </RadioGroup>
        )}
        rules={{
          required: { value: true, message: "Gerekli alan" },
        }}
      />
      <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
    </FormControl>
  );
}
