const commentUrl = "/comments";
const msUrlSegment = "/ms";
const visitUrlSegment = "/visit";

class CommentApi {
  constructor(fetcher) {
    this.fetcher = fetcher;
  }

  async createOneForMs(msId, data) {
    const res = await this.fetcher.post(
      `${msUrlSegment}/${msId}/comments`,
      data
    );
    return res.data.data;
  }

  async createOneForVisit(visitId, data) {
    const res = await this.fetcher.post(
      `${visitUrlSegment}/${visitId}/comments`,
      data
    );
    return res.data.data;
  }

  async updateOne(id, data) {
    const res = await this.fetcher.put(`${commentUrl}/${id}`, data);
    return res.data.data;
  }

  async deleteOne(id) {
    const res = await this.fetcher.delete(`${commentUrl}/${id}`);
    return res.data.data;
  }
}

export default CommentApi;
