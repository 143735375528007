class VisitInviteApi {
  constructor(fetcher) {
    this.fetcher = fetcher;
  }

  async createBatchForVisit(visitId, data) {
    const res = await this.fetcher.post(
      `/visits/${visitId}/visit-invites`,
      data
    );
    return res.data.data;
  }

  async updateStatus(inviteId, action, data = {}) {
    const res = await this.fetcher.put(
      `/visit-invites/${inviteId}/${action}`,
      data
    );
    return res.data.data;
  }
}

export default VisitInviteApi;
