import {
  Heading,
  Stack,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { MSContactInfoForm, MSPersonalInfoForm, MSBankInfoForm } from ".";
import Card from  "../../../components/Layout/Card";
export function MSProfilePage({ ms, mutateMs }) {
  return (
    <Stack>
      <Stack my={6} spacing={2} textAlign={"center"}>
        <Heading>{ms.name}, Ina'ya Hoş Geldiniz 👍</Heading>
        <Text color={"gray.600"} fontSize={"xl"}>
          Bilgilerinizi bu sayfada güncelleyebilirsiniz.
        </Text>
      </Stack>
      <Tabs variant="soft-rounded" colorScheme="green">
        <TabList justifyContent="center">
          <Tab>İletişim Bilgileri</Tab>
          <Tab>Kişisel Bilgiler</Tab>
          <Tab>Banka Bilgileri</Tab>
        </TabList>
        <Card topMargin>
        <TabPanels>
          <TabPanel>
            <MSContactInfoForm ms={ms} mutateMs={mutateMs} />
          </TabPanel>
          <TabPanel>
            <MSPersonalInfoForm ms={ms} />
          </TabPanel>
          <TabPanel>
            <MSBankInfoForm ms={ms} />
          </TabPanel>
        </TabPanels>
        </Card>
      </Tabs>
    </Stack>
  );
}
