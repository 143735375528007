const personnelTypeUrl = "/personneltypes";

class PersonnelTypeApi {
  constructor(fetcher) {
    this.fetcher = fetcher;
  }

  async createOneForProject(projectId, data) {
    const res = await this.fetcher.post(
      `projects/${projectId}/personneltypes`,
      data
    );
    return res.data.data;
  }

  async updateOne(id, data) {
    const res = await this.fetcher.put(`${personnelTypeUrl}/${id}`, data);
    return res.data.data;
  }

  async deleteOne(id) {
    const res = await this.fetcher.delete(`${personnelTypeUrl}/${id}`);
    return res.data.data;
  }
}

export default PersonnelTypeApi;
