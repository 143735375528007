// theme/index.js
import { extendTheme } from '@chakra-ui/react'

// // Global style overrides
import styles from './styles'

// // Foundational style overrides
// import borders from './foundations/borders'

// Component style overrides
import Button from './components/button'
import Input from './components/input'

const overrides = {
  styles,
  // borders,
  // // Other foundational style overrides go here
  components: {
    Button,
    Input,
    // Other components go here
  },
  fonts: {
    heading: "Nunito",
    body: "Nunito",
  },
}

export default extendTheme(overrides)
