import PageLayout from "../../../components/Layout/PageLayout";
import {
  Box,
  Stack,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Progress,
  Tag,
  Button,
  Flex,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import StatCard from "../../../components/Layout/StatCard";
import { FiMapPin, FiCalendar, FiMonitor, FiUsers } from "react-icons/fi";
import ReportChart from "../../../components/Reporting/ReportChart";
import { format } from "date-fns";
import PageHeader from "../../../components/Layout/PageHeader";
import { ProjectStatusTag } from "./projectStatusTag";
import { ProjectActionButton } from "./projectActionButton";
import { useAuth } from "../../../contexts/AuthContext";
import { useApi } from "../../../contexts/ApiContext";
import Card from "../../../components/Layout/Card";

//TODO: [Umut] Burasi genel dashboard olacak, projectDashboardPage ismi degismeli
export function ProjectDashboardPage() {
  //TODO: Route farkliligindan dolayi workaroung olarak yazdim.
  let { projectId } = useParams();
  const { id } = useParams();
  projectId = projectId ? projectId : id;
  const { isAdmin } = useAuth();
  const { projectApi } = useApi();

  const { data: project, mutate: mutateProject } = useSWR(
    `/projects/${projectId}`
  );
  const { data: metaData } = useSWR(`/projects/${projectId}/getMetaData`);
  const { data: locationBasedReport, error } = useSWR(
    `/location-based/${projectId}`
  );

  const calculate = async () => {
    await projectApi.calculate(projectId);
  };
  return (
    <PageLayout big>
      {isAdmin() && (
        <PageHeader
          title={
            <>
              {project?.name}
              {" ADMIN VIEW "}
              <ProjectStatusTag status={project?.status} size={"sm"} />
            </>
          }
          backUrl={project?.isArchived ? "../../archive" : "../.."}
        >
          <ProjectActionButton
            projectId={projectId}
            status={project?.status}
            isArchived={project?.isArchived}
            mutateProject={mutateProject}
          />
          <Button colorScheme="blue" size="sm" onClick={calculate}>
            Tekrar Hesapla
          </Button>
        </PageHeader>
      )}
      <Stack mt={10} spacing="24px">
        <Stack direction={["column", "row"]} spacing="24px">
          <StatCard
            label="Toplam Ziyaret"
            value={metaData?.visitCount}
            icon={<FiMonitor size={"30px"} color="#f27d74" />}
          />
          <StatCard
            label="Lokasyonlar"
            value={metaData?.uniqueLocationCount}
            icon={<FiMapPin size={"30px"} color="#9674f2" />}
          />
          <StatCard
            label="Gizli Müşteri"
            value={metaData?.uniqueShopperCount}
            icon={<FiUsers size={"30px"} color="#74ccf2" />}
          />
          <StatCard
            size="sm"
            label="Proje Süresi"
            value={
              metaData?.project.startDate &&
              metaData?.project.endDate &&
              format(new Date(metaData?.project.startDate), "dd-MMM-yy") +
                " - " +
                format(new Date(metaData?.project.endDate), "dd-MMM-yy")
            }
            icon={<FiCalendar size={"30px"} color="#f0d071" />}
          />
        </Stack>

        <ReportChart
          reportType={"location-based"}
          category={undefined}
          breakdown={[]}
          projectId={projectId}
          dashboard={true}
        />
        <Flex  gap={6}>
          <Card flex="1">
            <PageHeader inBox title="Lokasyonlara Göre Dağılım" />
            <TableContainer mt={8}>
              <Table variant="simple" size={"sm"}>
                <Thead>
                  <Tr>
                    <Th>Lokasyon</Th>
                    <Th isNumeric>Durum</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {locationBasedReport &&
                    locationBasedReport.chartData.map((item) => (
                      <Tr key={item.label}>
                        <Td>{item.label}</Td>
                        <Td isNumeric>
                          <Text fontSize="12px">{item.performance}</Text>
                          <Progress value={20} size="xs" colorScheme="red" />
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Card>
          <Card  flex="1">
            <PageHeader inBox title="Lokasyonlara Göre Dağılım" />
            <TableContainer mt={8}>
              <Table variant="simple" size={"sm"}>
                <Thead>
                  <Tr>
                    <Th>Gizli Müşteri</Th>
                    <Th isNumeric>Planlanan</Th>
                    <Th isNumeric>Süreçte</Th>
                    <Th isNumeric>Onaylandı</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>Umut Güncan</Td>
                    <Td isNumeric>
                      <Tag colorScheme={"pink"}>2</Tag>
                    </Td>
                    <Td isNumeric>
                      <Tag colorScheme={"blue"}>2</Tag>
                    </Td>
                    <Td isNumeric>
                      <Tag colorScheme={"green"}>2</Tag>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Utku Kocaman</Td>
                    <Td isNumeric>
                      <Tag colorScheme={"pink"}>1</Tag>
                    </Td>
                    <Td isNumeric>
                      <Tag colorScheme={"blue"}>0</Tag>
                    </Td>
                    <Td isNumeric>
                      <Tag colorScheme={"green"}>0</Tag>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Umut Güncan</Td>
                    <Td isNumeric>
                      <Tag colorScheme={"pink"}>2</Tag>
                    </Td>
                    <Td isNumeric>
                      <Tag colorScheme={"blue"}>2</Tag>
                    </Td>
                    <Td isNumeric>
                      <Tag colorScheme={"green"}>2</Tag>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Utku Kocaman</Td>
                    <Td isNumeric>
                      <Tag colorScheme={"pink"}>1</Tag>
                    </Td>
                    <Td isNumeric>
                      <Tag colorScheme={"blue"}>0</Tag>
                    </Td>
                    <Td isNumeric>
                      <Tag colorScheme={"green"}>0</Tag>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Umut Güncan</Td>
                    <Td isNumeric>
                      <Tag colorScheme={"pink"}>2</Tag>
                    </Td>
                    <Td isNumeric>
                      <Tag colorScheme={"blue"}>2</Tag>
                    </Td>
                    <Td isNumeric>
                      <Tag colorScheme={"green"}>2</Tag>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Utku Kocaman</Td>
                    <Td isNumeric>
                      <Tag colorScheme={"pink"}>1</Tag>
                    </Td>
                    <Td isNumeric>
                      <Tag colorScheme={"blue"}>0</Tag>
                    </Td>
                    <Td isNumeric>
                      <Tag colorScheme={"green"}>0</Tag>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Umut Güncan</Td>
                    <Td isNumeric>
                      <Tag colorScheme={"pink"}>2</Tag>
                    </Td>
                    <Td isNumeric>
                      <Tag colorScheme={"blue"}>2</Tag>
                    </Td>
                    <Td isNumeric>
                      <Tag colorScheme={"green"}>2</Tag>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </Card>
        </Flex>
      </Stack>
    </PageLayout>
  );
}
