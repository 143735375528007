import { Box, Flex, Image } from "@chakra-ui/react";

// TODO: Check server url in production, update env var
const IMAGE_SOURCE = "/public/images/visit";
const SERVER_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3005" // Proxy did not work
    : process.env.REACT_APP_SERVER_URL;

export function VisitImages({ images }) {
  if (!images) {
    return null;
  }
  return (
    <Flex wrap={"wrap"} justify={"flex-start"} my={4}>
      {images.map((src) => (
        <a
          key={src}
          href={`${SERVER_URL}${IMAGE_SOURCE}/${src}`}
          target={"_blank"}
          rel="noreferrer"
        >
          <Box
            border={"1px"}
            borderColor={"gray.200"}
            borderRadius={"md"}
            padding={1}
            marginBottom={4}
            marginX={{ base: 2, md: 4 }}
          >
            <Image
              src={`${IMAGE_SOURCE}/${src}`}
              boxSize={{ base: "130px", md: "210px" }}
              objectFit={"cover"}
            />
          </Box>
        </a>
      ))}
    </Flex>
  );
}
