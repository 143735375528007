import { Route } from "react-router-dom";
import {
  ClientProjectListPage,
  ClientVisitListPage,
  ClientProjectDetail,
} from "../pages/accClient";
import { PlanDetailPage } from "../pages/pms/plan";
import { ProjectDashboardPage } from "../pages/pms/project";
import { LocationBasedReportPage,CategoryBasedReportPage,BreakdownBasedReportPage,PersonnelReportPage,ProjectHeatMapReportPage } from "../pages/reporting";
export const companyManagerRoutes = (
  <Route path="client">
    <Route index element={<ClientProjectListPage />} />
    <Route path="project/:projectId" element={<ClientProjectDetail />}>
      <Route path="dashboard" element={<ProjectDashboardPage />} />
      <Route path="visits" element={<ClientVisitListPage />} />
      <Route path="visits/:visitId" element={<PlanDetailPage />} />
      <Route path="heat-map-report" element={<ProjectHeatMapReportPage />} />
      <Route
        path="location-based-report"
        element={<LocationBasedReportPage />}
      />
      <Route
        path="category-based-report"
        element={<CategoryBasedReportPage />}
      />
      <Route
        path="breakdown-based-report/:baseBreakdownId"
        element={<BreakdownBasedReportPage />}
      />
      <Route path="personnel-report" element={<PersonnelReportPage />} />
      <Route path="anecdote-report" element={<>Anektodlar</>} />
      <Route path="export-report" element={<>Export Data</>} />
    </Route>
  </Route>
);
