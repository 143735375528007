import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react";
import { FiPlus, FiMoreHorizontal } from "react-icons/fi";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import PageHeader from "../../../components/Layout/PageHeader";
import PageLayout from "../../../components/Layout/PageLayout";
import Card from "../../../components/Layout/Card";
import ClientTable from "../../../components/Table/ClientTable";
import LoadingIndicator from "../../../components/LoadingIndicator";
import DeleteConfirmAlert from "../../../components/Alert/DeleteConfirmAlert";
import { VisitScenarioUpsertAlert } from "./visitScenarioUpsert";
import { useApi } from "../../../contexts/ApiContext";
import { useUiContext } from "../../../contexts/UiContext";

export function VisitScenariosIndexPage() {
  const toast = useToast();
  const { showAlert, closeAlertBox } = useUiContext();
  const { id: projectId } = useParams();
  const { data: scenarios, mutate } = useSWR(
    `/projects/${projectId}/visit-scenarios`
  );
  const { data: project } = useSWR(`/projects/${projectId}`);
  const { visitScenarioApi } = useApi();

  const columns = useMemo(
    () => [
      {
        Header: "SENARYO",
        accessor: "title",
      },
      {
        Header: "AÇIKLAMA",
        accessor: "description",
      },
      {
        Header: "",
        accessor: "_id",
        isNumeric: true,
        Cell: ({ cell }) => (
          <Menu>
            <MenuButton
              variant="ghost"
              size="sm"
              as={IconButton}
              icon={<FiMoreHorizontal />}
            />
            <MenuList>
              <MenuItem
                onClick={() => {
                  showAlert(
                    <VisitScenarioUpsertAlert
                      scenario={{
                        id: cell.row.values._id,
                        title: cell.row.values.title,
                        description: cell.row.values.description,
                      }}
                      projectId={projectId}
                      onClose={closeAlertBox}
                      mutateScenarios={mutate}
                    />
                  );
                }}
              >
                Düzenle
              </MenuItem>
              <MenuItem
                onClick={() => {
                  showAlert(
                    <DeleteConfirmAlert
                      name={"Ziyaret Senaryosu"}
                      onDelete={() => deleteRow(cell.row.values._id)}
                    />
                  );
                }}
              >
                Sil
              </MenuItem>
            </MenuList>
          </Menu>
        ),
      },
    ],
    []
  );

  async function deleteRow(id) {
    try {
      await visitScenarioApi.deleteOne(id);
      mutate();
      toast({
        title: "Başarılı",
        description: "Senaryo silindi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  return (
    <PageLayout>
      <PageHeader title="Ziyaret Senaryoları">
        {!project?.isArchived && (
          <Button
            leftIcon={<FiPlus />}
            size="sm"
            variant="outline"
            colorScheme="teal"
            onClick={() => {
              showAlert(
                <VisitScenarioUpsertAlert
                  projectId={projectId}
                  onClose={closeAlertBox}
                  mutateScenarios={mutate}
                />
              );
            }}
          >
            Yeni Ekle
          </Button>
        )}
      </PageHeader>
      <Card topMargin>
        {!scenarios ? (
          <LoadingIndicator />
        ) : (
          <ClientTable data={scenarios} columns={columns} />
        )}
      </Card>
    </PageLayout>
  );
}
