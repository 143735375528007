import {
  Box,
  Flex,
  Image,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import { NotAllowedIcon } from "@chakra-ui/icons";
import {
  Link as RouterLink,
  Outlet,
  useLocation,
  useParams,
} from "react-router-dom";
import useSWR from "swr";
import {
  CompanyLogoAlert,
  LOGO_SOURCE,
} from "../../../components/CompanyLogoAlert";
import ItemDetail from "../../../components/ItemDetail";
import Card from "../../../components/Layout/Card";
import PageHeader from "../../../components/Layout/PageHeader";
import PageLayout from "../../../components/Layout/PageLayout";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { useUiContext } from "../../../contexts/UiContext";

const IMAGE_SIZE = "64px";

export function CompanyDetailPage() {
  const { id } = useParams();
  const { data: company } = useSWR(`/companies/${id}`);
  const location = useLocation();
  const { showAlert } = useUiContext();

  if (!company) {
    return <LoadingIndicator />;
  }

  const { name, fullName, logo, phone, email, website, sector, address } =
    company;

  return (
    <PageLayout>
      <PageHeader title={name} backUrl=".." />
      <Box mt={8} mb={4}>
        <Card padding={4}>
          <VStack spacing={4} align={"start"}>
            <Flex width={"100%"} justify={"space-between"} align={"center"}>
              <Link
                onClick={() =>
                  showAlert(<CompanyLogoAlert companyId={id} logo={logo} />)
                }
              >
                <Image
                  borderRadius="full"
                  borderStyle={"solid"}
                  borderWidth={"thin"}
                  boxSize={IMAGE_SIZE}
                  objectFit="cover"
                  src={`${LOGO_SOURCE}/${logo}`}
                  fallback={
                    <NotAllowedIcon boxSize={IMAGE_SIZE} color={"gray.200"} />
                  }
                  alt={name}
                />
              </Link>
              <ItemDetail
                medium
                width={"400px"}
                px={2}
                label="Firma Ticari Ad"
                value={fullName}
              />
              <ItemDetail
                medium
                width={"400px"}
                px={2}
                label="Sektör"
                value={sector?.name}
              />
            </Flex>
            <Flex width={"100%"} justify={"space-between"}>
              <Box width={IMAGE_SIZE} />
              <ItemDetail
                medium
                width={"400px"}
                px={2}
                label="Telefon"
                value={phone}
              />
              <ItemDetail
                medium
                width={"400px"}
                px={2}
                label="Eposta"
                value={email}
              />
            </Flex>
            <Flex width={"100%"} justify={"space-between"}>
              <Box width={IMAGE_SIZE} />
              <ItemDetail
                medium
                width={"400px"}
                px={2}
                label="Web Sitesi"
                value={website}
              />
              <ItemDetail
                medium
                width={"400px"}
                px={2}
                label="Adres"
                value={address}
              />
            </Flex>
          </VStack>
        </Card>
      </Box>
      <Tabs
        defaultIndex={location.pathname.includes("locations") ? 1 : 0}
      >
        <TabList>
          <RouterLink to={"personnel"}>
            <Tab>Firma Kullanıcıları</Tab>
          </RouterLink>
          <RouterLink to={"locations"}>
            <Tab>Lokasyonlar</Tab>
          </RouterLink>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Outlet />
          </TabPanel>
          <TabPanel>
            <Outlet />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageLayout>
  );
}
