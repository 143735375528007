import {
  Button,
  FormControl,
  FormErrorMessage,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { FiCheck } from "react-icons/fi";
import { useForm } from "react-hook-form";
import { useApi } from "../../contexts/ApiContext";

export function EditCommentForm({ comment, mutateComments, setIsEditing }) {
  const toast = useToast();
  const { commentApi } = useApi();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      body: comment.body,
    },
  });

  const onSubmit = async (data) => {
    try {
      await commentApi.updateOne(comment._id, data);
      mutateComments();
      setIsEditing(false);
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Stack direction={"column"} spacing={4}>
      <Stack direction={"row"} spacing={8} justifyContent={"space-between"}>
        <FormControl isInvalid={errors.body}>
          <Textarea
            px={2}
            id="body"
            {...register("body", {
              required: "Gerekli alan",
            })}
          />
          <FormErrorMessage>{errors.body?.message}</FormErrorMessage>
        </FormControl>
        <Text as="i" flexShrink={0}>
          {comment.author.name}
        </Text>
      </Stack>
      <Button
        alignSelf={"flex-start"}
        size="sm"
        borderRadius={"xl"}
        colorScheme={"green"}
        isLoading={isSubmitting}
        onClick={handleSubmit(onSubmit)}
      >
        <FiCheck />
      </Button>
    </Stack>
  );
}
