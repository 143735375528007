import {
  Button,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogBody,
  Input,
  useToast,
  Stack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import StandardFormControl from "../../../../components/Form/StandardFormControl";
import { GoalMultiSelect } from "./goalMultiSelect";
import { useApi } from "../../../../contexts/ApiContext";

export function CategoryUpsertAlert({
  category,
  projectId,
  mainCategoryId,
  onClose,
  mutateQuestionForm,
}) {
  const toast = useToast();
  const { formApi } = useApi();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name: category?.name ?? "",
      relatedGoals: category?.relatedGoals ?? [],
    },
  });

  const onSubmit = async (data) => {
    if (mainCategoryId) {
      data.mainCategory = mainCategoryId;
    }
    let description = "";
    try {
      if (category) {
        await formApi.updateCategory(category._id, data);
        description = "Kategori başarıyla düzenlendi.";
      } else {
        await formApi.createCategoryForProject(projectId, data);
        description = "Yeni kategori başarıyla eklendi.";
      }
      toast({
        title: "Başarılı",
        description,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      mutateQuestionForm();
      onClose();
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <AlertDialogContent>
      <AlertDialogHeader fontSize="lg" fontWeight="bold">
        {category ? "Kategori Düzenle" : "Yeni Kategori Ekle"}
      </AlertDialogHeader>

      <form onSubmit={handleSubmit(onSubmit)}>
        <AlertDialogBody>
          <Stack direction={"column"} spacing="16px">
            <StandardFormControl
              errors={errors}
              name="name"
              label="Kategori Adı"
            >
              <Input
                id="name"
                placeholder="Kategori Adı"
                {...register("name", {
                  required: "Gerekli alan",
                  minLength: {
                    value: 3,
                    message: "En az 3 karakter gerekli.",
                  },
                })}
              />
            </StandardFormControl>
            <GoalMultiSelect
              name={"relatedGoals"}
              control={control}
              projectId={projectId}
              gridColumns={2}
            />
          </Stack>
          <AlertDialogFooter marginTop={4}>
            <Button onClick={onClose}>Vazgeç</Button>
            <Button
              colorScheme="green"
              ml={3}
              isLoading={isSubmitting}
              type="submit"
            >
              Kaydet
            </Button>
          </AlertDialogFooter>
        </AlertDialogBody>
      </form>
    </AlertDialogContent>
  );
}
