import Select from "react-select";
import useSWR from "swr";
import { useState } from "react";
import ReportChart from "../../components/Reporting/ReportChart";
import { Box, Flex } from "@chakra-ui/react";
import PageHeader from "../../components/Layout/PageHeader";
import PageLayout from "../../components/Layout/PageLayout";
import { useParams } from "react-router";
import Card from "../../components/Layout/Card";

export function PersonnelReportPage() {
  const { projectId } = useParams();

  const { data: reportData } = useSWR(`/project-personnel-report/${projectId}`);

  return (
    <>
      <PageLayout>
        <PageHeader inBox title="Personel Raporu" backUrl=".." />
      </PageLayout>
    </>
  );
}
