import {
  Button,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogBody,
  Textarea,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useUiContext } from "../../../../contexts/UiContext";

export function RejectionAlert({
  header,
  buttonLabel,
  buttonColorScheme,
  onAction,
}) {
  const { closeAlertBox } = useUiContext();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      rejectionReason: "",
    },
  });

  const onSubmit = async (data) => {
    onAction(data);
    closeAlertBox();
  };

  return (
    <AlertDialogContent>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          {header}
        </AlertDialogHeader>
        <AlertDialogBody>
          <FormControl isInvalid={errors.rejectionReason}>
            <FormLabel htmlFor="rejectionReason">
              Reddetme sebebini belirtiniz (Gizli müşteri ile paylaşılacaktır)
            </FormLabel>
            <Textarea
              // px={2}
              id="rejectionReason"
              {...register("rejectionReason", {
                required: "Gerekli alan",
              })}
            />
            <FormErrorMessage>
              {errors.rejectionReason?.message}
            </FormErrorMessage>
          </FormControl>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button onClick={closeAlertBox}>Iptal</Button>
          <Button
            colorScheme={buttonColorScheme ?? "red"}
            type={"submit"}
            ml={3}
          >
            {buttonLabel}
          </Button>
        </AlertDialogFooter>
      </form>
    </AlertDialogContent>
  );
}
