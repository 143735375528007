import { Stack, StackDivider } from "@chakra-ui/react";

export function ChoiceStack({ error, children }) {
  return (
    <Stack
      direction={"column"}
      spacing={"3"}
      // bg="gray.100"
      divider={<StackDivider borderColor="gray.100" />}
      py={2}
      px={4}
      borderRadius="md"
      border={error ? "2px" : "1px"}
      borderColor={error ? "#E53E3E" : "gray.200"}
    >
      {children}
    </Stack>
  );
}
