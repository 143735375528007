import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { useState } from "react";
import { useController } from "react-hook-form";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";

export function RichTextInput({ name, label, control, rules }) {
  const modules = {
    toolbar: [
      // [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ header: [1, 2, 3, false] }],
      // ["bold", "italic", "underline", "strike", "blockquote"],
      ["bold", "italic", "underline"],
      // [{ size: [] }],
      // [{ font: [] }],
      // [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      // ["link", "image"],
      ["image"],
      [{ color: ["red", "green", false] }],
      [{ background: ["red", "yellow", false] }],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    // "strike",
    // "blockquote",
    "list",
    "bullet",
    // "link",
    "color",
    "image",
    "background",
    // "align",
    // "size",
    // "font",
  ];

  const {
    field: { value, onChange },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: rules,
  });

  const [content, setContent] = useState(value || "Metin giriniz...");

  const handleContentChange = (content, delta, source, editor) => {
    setContent(content);
    onChange(content);
  };

  return (
    <FormControl isInvalid={invalid}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        value={content}
        onChange={handleContentChange}
        // onFocus
        // onBlur
      />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}
