import { Box } from "@chakra-ui/react";

export default function Card({ type,topMargin, children, ...props }) {
  if (type === "white") {
    return (
      <Box
        bg="white"
        boxShadow="sm"
        borderRadius="lg"
        borderWidth="12 px"
        p={6}
        mt={topMargin && 6}
        {...props}
      >
        {children}
      </Box>
    );
  }
  return (
    <Box
      bg="gray.50"
      borderRadius="xl"
      borderWidth="2px"
      borderColor="gray.300"
      borderStyle={"dashed"}
      p={6}
      mt={topMargin && 6}

      {...props}
    >
      {children}
    </Box>
  );
}
