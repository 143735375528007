import {
  Button,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogBody,
  FormControl,
  Input,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useApi } from "../../../contexts/ApiContext";
import { BreakdownOptionsArray } from "./breakdownOptionsArray";

export default function ProjectBreakDownUpsert({
  projectBreakdown = {},
  projectId,
  onClose,
  mutateProjectBreakdowns,
}) {
  const toast = useToast();
  const { projectBreakdownApi } = useApi();

  const { id, name, options } = projectBreakdown;
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name: name ?? "",
      options: options ?? [{ optionText: "" }, { optionText: "" }],
    },
  });

  const onSubmit = async (data) => {
    let description = "";
    try {
      if (id) {
        await projectBreakdownApi.updateOne(id, data);
        description = "Proje kırılımı başarıyla düzenlendi.";
      } else {
        await projectBreakdownApi.createOneForProject(projectId, data);
        description = "Yeni proje kırılımı başarıyla eklendi.";
      }
      toast({
        title: "Başarılı",
        description,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      mutateProjectBreakdowns();
      onClose();
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <AlertDialogContent>
      <AlertDialogHeader fontSize="lg" fontWeight="bold">
        {id ? "Proje Kırılımı Düzenle" : "Yeni Proje Kırılımı Ekle"}
      </AlertDialogHeader>

      <form onSubmit={handleSubmit(onSubmit)}>
        <AlertDialogBody>
          <FormControl isInvalid={errors.name}>
            <Input
              id="name"
              placeholder="Proje Kırılım Adı"
              {...register("name", {
                required: "Gerekli alan",
                minLength: {
                  value: 3,
                  message: "En az 3 karakter gerekli.",
                },
              })}
            />
            <FormErrorMessage>
              {errors.name && errors.name.message}
            </FormErrorMessage>
          </FormControl>

          <BreakdownOptionsArray
            register={register}
            control={control}
            errors={errors}
            id={id}
          />
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button onClick={onClose}>Vazgeç</Button>
          <Button
            colorScheme="pink"
            ml={3}
            isLoading={isSubmitting}
            type="submit"
          >
            Kaydet
          </Button>
        </AlertDialogFooter>
      </form>
    </AlertDialogContent>
  );
}
