import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Stack,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import FormContainer from "../../../components/Form/FormContainer";
import FormFooter from "../../../components/Form/FormFooter";
import StandardFormControl from "../../../components/Form/StandardFormControl";
import { useApi } from "../../../contexts/ApiContext";
import { Controller, useForm } from "react-hook-form";
import { useAuth } from "../../../contexts/AuthContext";
import { regexEmail, regexPassword } from "../../../util/regex";

// TODO: phone regex
export function FieldMgrUpdateForm({
  fieldMgr,
  mutateFieldMgr,
  showStatus,
  disablePassword,
}) {
  const { fieldMgrApi } = useApi();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name: fieldMgr.name,
      email: fieldMgr.email,
      password: "",
      phone: fieldMgr.phone,
      status: fieldMgr.status === "active" ? true : false,
      region: fieldMgr.region,
      // picture: fieldMgr?.picture,
    },
  });
  const toast = useToast();
  const { isAdmin } = useAuth();

  const onSubmit = async (data) => {
    try {
      const { status } = data;
      data.status = status ? "active" : "inactive";
      // If password is empty don't send it!
      let serverData = { ...data };
      if (data.password === "") {
        const { password, ...dataWithoutPassword } = data;
        serverData = { ...dataWithoutPassword };
      }
      // console.log(serverData);
      await fieldMgrApi.updateOne(fieldMgr._id, serverData);
      mutateFieldMgr();
      toast({
        title: "Başarılı",
        description: "Bilgiler güncellendi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer>
        <Stack direction={{ base: "column", md: "row" }} spacing="24px">
          <StandardFormControl errors={errors} name="name" label="Ad Soyad">
            <Input
              id={"name"}
              {...register("name", { required: "Gerekli alan" })}
            />
          </StandardFormControl>

          <StandardFormControl errors={errors} name="email" label="E-Posta">
            <Input
              id="email"
              isDisabled={!isAdmin()}
              {...register("email", {
                required: "Gerekli alan",
                pattern: {
                  value: regexEmail,
                  message: "Geçersiz e-posta",
                },
              })}
            />
          </StandardFormControl>
        </Stack>

        <Stack direction={{ base: "column", md: "row" }} spacing="24px">
          <StandardFormControl
            errors={errors}
            name="password"
            label={
              <Tooltip
                label="Şifre değiştirmek istemiyorsanız lütfen boş bırakınız."
                placement="right-end"
              >
                Şifre ℹ️
              </Tooltip>
            }
          >
            <Input
              id="password"
              type="password"
              isDisabled={disablePassword ? true : false}
              {...register("password", {
                validate: function (pass) {
                  if (pass === "" || regexPassword.test(pass)) {
                    return true;
                  }
                  return "En az 8 karakter giriniz ya da boş bırakınız";
                },
              })}
            />
          </StandardFormControl>

          <StandardFormControl errors={errors} name="phone" label="Telefon">
            <Input id={"phone"} {...register("phone")} />
          </StandardFormControl>
        </Stack>

        <Stack direction={{ base: "column", md: "row" }} spacing="24px">
          <StandardFormControl
            errors={errors}
            name="region"
            label="Çalışma Bölgesi"
          >
            <Input id={"region"} {...register("region")} />
          </StandardFormControl>

          {showStatus && (
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="status" mb="0">
                Aktif?
              </FormLabel>
              <Controller
                name="status"
                control={control}
                render={({ field }) => {
                  return (
                    <Switch
                      id="status"
                      colorScheme="pink"
                      {...field}
                      isChecked={field.value ? true : false}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  );
                }}
              />
            </FormControl>
          )}
        </Stack>

        <FormFooter>
          <Button colorScheme={"pink"} isLoading={isSubmitting} type="submit">
            Gönder
          </Button>
        </FormFooter>
      </FormContainer>
    </form>
  );
}
