import { Select } from "@chakra-ui/react";

export default function SelectWrapper({ data, register, defaultValue }) {
  return (
    <Select
      variant={"filled"}
      placeholder="Lütfen seçiniz"
      {...register}
      defaultValue={defaultValue}
    >
      {data &&
        data.map((item) => (
          <option key={item.value} value={item.value}>
            {item.text}
          </option>
        ))}
    </Select>
  );
}
