import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";

export default function DrawerBox({ isOpen, onClose, children }) {
  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="right" size={"md"}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          {children}
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
}
