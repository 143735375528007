import {
  Button,
  FormControl,
  FormLabel,
  Input,
  DrawerBody,
  DrawerFooter,
  Stack,
  Switch,
  useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import useSWR, { useSWRConfig } from "swr";
import { useApi } from "../../../contexts/ApiContext";
import FormContainer from "../../../components/Form/FormContainer";
import StandardFormControl from "../../../components/Form/StandardFormControl";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { LocationMultiSelect } from "../../../components/Form/LocationMultiSelect";
import { regexEmail } from "../../../util/regex";

// TODO: phone regex
export function PersonnelUpdateForm({
  personnel,
  mutatePersonnel,
  closeDrawer,
}) {
  const toast = useToast();
  const {
    _id: id,
    role,
    name,
    email,
    phone,
    status,
    location,
    company,
  } = personnel;
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name,
      email,
      password: "",
      phone,
      status: status === "active" ? true : false,
      location: role === "locationMgr" ? location.map((loc) => loc._id) : "",
    },
  });

  const { companyMgrApi, locationMgrApi } = useApi();
  const { mutate } = useSWRConfig();
  const companyId = company._id;
  const { data: locations, error: locationDataError } = useSWR(
    `/companies/${companyId}/locations`
  );

  async function onSubmit(data) {
    const { status } = data;
    data.status = status ? "active" : "inactive";
    const { password, ...dataWithoutPassword } = data;
    try {
      if (role === "companyMgr") {
        await companyMgrApi.updateOne(id, dataWithoutPassword);
      } else {
        await locationMgrApi.updateOne(id, dataWithoutPassword);
      }
      mutate(`/companies/${companyId}/personnel`);
      mutatePersonnel();
      toast({
        title: "Başarılı",
        description: "Kullanıcı başarıyla kaydedildi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      closeDrawer();
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  if (locationDataError) {
    toast({
      title: "Bir hata oluştu.",
      description: "Sunucu hatası.",
      status: "error",
    });
  }

  if (role === "locationMgr" && !locations) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <DrawerBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContainer p={2}>
            <Stack direction={"column"} spacing="24px">
              <StandardFormControl errors={errors} name="name" label="Ad Soyad">
                <Input
                  id="name"
                  placeholder="Ad Soyad"
                  {...register("name", {
                    required: "Gerekli alan",
                    minLength: {
                      value: 4,
                      message: "En az 4 karakter gerekli.",
                    },
                  })}
                />
              </StandardFormControl>

              <StandardFormControl errors={errors} name="email" label="E-posta">
                <Input
                  id="email"
                  placeholder="abc@company.com"
                  {...register("email", {
                    required: "Gerekli alan",
                    pattern: {
                      value: regexEmail,
                      message: "Geçersiz e-posta",
                    },
                  })}
                />
              </StandardFormControl>

              <StandardFormControl errors={errors} name="phone" label="Telefon">
                <Input
                  id="phone"
                  placeholder="Telefon"
                  {...register("phone")}
                />
              </StandardFormControl>

              {role === "locationMgr" && (
                <LocationMultiSelect
                  name="location"
                  control={control}
                  companyId={companyId}
                />
              )}

              <StandardFormControl
                errors={errors}
                name="password"
                label="Şifre"
              >
                <Input
                  id="password"
                  isDisabled
                  // placeholder="Parola"
                  {...register("password")}
                />
              </StandardFormControl>

              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="status" mb="0">
                  Aktif?
                </FormLabel>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Switch
                        id="status"
                        colorScheme="pink"
                        {...field}
                        isChecked={field.value ? true : false}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    );
                  }}
                />
              </FormControl>
            </Stack>
          </FormContainer>
        </form>
      </DrawerBody>
      <DrawerFooter>
        <Button variant="outline" mr={3} onClick={closeDrawer}>
          Vazgeç
        </Button>
        <Button
          colorScheme={"pink"}
          isLoading={isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          Kaydet
        </Button>
      </DrawerFooter>
    </>
  );
}
