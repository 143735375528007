import { Box, Text } from "@chakra-ui/react";

export function FreeTextAnswer({ question, answer }) {
  return (
    <Box>
      <Text fontSize={"lg"} color={"blue.700"}>
        {answer?.freeTextAnswer || "---"}
      </Text>
    </Box>
  );
}
