import Select from "react-select";
import useSWR from "swr";
import { useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import PageHeader from "../../components/Layout/PageHeader";
import PageLayout from "../../components/Layout/PageLayout";
import { useParams } from "react-router";
import Card from "../../components/Layout/Card";
import ReportChart from "../../components/Reporting/ReportChart";

export function CategoryBasedReportPage() {
  const { projectId } = useParams();
  const [location, setLocation] = useState(false);
  const [breakdown, setBreakdown] = useState({});

  const { data: project } = useSWR(`/projects/${projectId}`);

  const { data: breakdowns } = useSWR(
    `/projects/${projectId}/project-breakdowns`
  );

  const { data: locations } = useSWR(
    `/companies/${project?.company._id}/locations`
  );

  const locationOptions = locations?.map((category) => ({
    value: category._id,
    label: category.name,
  }));

  const onSelectLocation = (event) => {
    setLocation(event.value);
  };
  const onSelectBreakDown = ({ breakdownId, value }) => {
    setBreakdown((curr) => ({ ...curr, [breakdownId]: value }));
  };

  return (
    <>
      <PageLayout>
        <Card mt={8} type="white">
          <PageHeader inBox title="Hizmet Kirilimi  Rapor" backUrl=".." />
          <Flex gap={4}>
            <Box w="100%">
              <Select
                placeholder={"Hizmet Kategorisi"}
                options={locationOptions}
                onChange={onSelectLocation}
              />
            </Box>
            {breakdowns?.map((breakdown) => {
              return (
                <Box w="100%" key={breakdown._id}>
                  <Select
                    placeholder={breakdown.name}
                    name={breakdown._id}
                    options={breakdown.options.map((option) => ({
                      value: option._id,
                      label: option.optionText,
                      breakdownId: breakdown._id,
                    }))}
                    onChange={onSelectBreakDown}
                  />
                </Box>
              );
            })}
          </Flex>
        </Card>
        <ReportChart
          reportType={"category-based"}
          location={location}
          breakdown={breakdown}
          projectId={projectId}
        />
      </PageLayout>
    </>
  );
}
