import { extendTheme } from '@chakra-ui/react'

 const styles ={
    global: {
      body: {
        bg: 'gray.100',
      },
    },
        
  
}

export default styles;

 