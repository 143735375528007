import { createContext, useContext } from "react";
import apiFactory from "../api/apiFactory";
import { useApiAuthFetch } from "./FetchContext";

const ApiContext = createContext();

// wrapper for the context
export function ApiContextProvider({ children }) {
  const apiAuthFetch = useApiAuthFetch();

  // inject apiAuthFetch as the fetcher for api objects
  // apiFactory returns an object with api objects, e.g. sectorApi, companyApi etc.
  // each api object has methods to fetch data, e.g. xxxApi.createOne()
  const api = apiFactory(apiAuthFetch);

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
}

// custom hook to get the api object
export function useApi() {
  return useContext(ApiContext);
}
