import {
  Button,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogBody,
  Textarea,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useUiContext } from "../../../../contexts/UiContext";

export function ScoreAlert({
  header,
  buttonLabel,
  buttonColorScheme,
  onAction,
}) {
  const { closeAlertBox } = useUiContext();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      msVisitScore: 5,
      msVisitScoreComment: "",
    },
  });

  const onSubmit = async (data) => {
    onAction(data);
    closeAlertBox();
  };

  return (
    <AlertDialogContent>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          {header}
        </AlertDialogHeader>
        <AlertDialogBody>
          <VStack spacing={4}>
            <FormControl isInvalid={errors.msVisitScore}>
              <FormLabel htmlFor="msVisitScore">
                Gizli Müşteri Ziyaret Puanı
              </FormLabel>
              <NumberInput defaultValue={5} min={0} max={10}>
                <NumberInputField
                  id="msVisitScore"
                  {...register("msVisitScore", {
                    required: "Gerekli alan",
                  })}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <FormErrorMessage>
                {errors.msVisitScore?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.msVisitScoreComment}>
              <FormLabel htmlFor="msVisitScoreComment">
                Ziyaret Puan Yorumu
              </FormLabel>
              <Textarea
                id="msVisitScoreComment"
                {...register("msVisitScoreComment")}
              />
              <FormErrorMessage>
                {errors.msVisitScoreComment?.message}
              </FormErrorMessage>
            </FormControl>
          </VStack>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button onClick={closeAlertBox}>Iptal</Button>
          <Button
            colorScheme={buttonColorScheme ?? "green"}
            type={"submit"}
            ml={3}
          >
            {buttonLabel}
          </Button>
        </AlertDialogFooter>
      </form>
    </AlertDialogContent>
  );
}
