import { Route } from "react-router-dom";
import {
  MysteryShopperListComp,
  MysteryShopperIndexPage,
  MysteryShopperDetailPage,
} from "../pages/msm";

export const msmRoutes = (
  <Route path="msm/mystery-shoppers" element={<MysteryShopperIndexPage />}>
    <Route index element={<MysteryShopperListComp status="active" />} />
    <Route path="active" element={<MysteryShopperListComp status="active" />} />
    <Route
      path="pending-approval"
      element={<MysteryShopperListComp status="trainingCompleted" />}
    />
    <Route
      path="new-applicants"
      element={<MysteryShopperListComp status="pending" />}
    />
    {/* <Route
      path="pending-education"
      element={<MysteryShopperListComp status="applicationFormCompleted" />}
    /> */}
    <Route path="denied" element={<MysteryShopperListComp status="denied" />} />
    <Route
      path="inactive"
      element={<MysteryShopperListComp status="inactive" />}
    />
    <Route
      path="reports"
      element={
        <div>
          MS Raporlari, Pinar Hanim'dan almak gerekiyor. Egitim durumu,
          sehirlere gore, puanlara gore, tecrube, yas, cinsiyet...
        </div>
      }
    />
    <Route path=":id" element={<MysteryShopperDetailPage />} />
  </Route>
);
