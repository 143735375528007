import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Radio,
  Stack,
  useToast,
  Heading,
  Text,
} from "@chakra-ui/react";
import FormContainer from "../../../components/Form/FormContainer";
import FormFooter from "../../../components/Form/FormFooter";
import SelectWrapper from "../../../components/Form/SelectWrapper";
import StandardFormControl from "../../../components/Form/StandardFormControl";
import RadioFormControl from "../../../components/Form/RadioFormControl";
import {
  CityDistrictCombo,
  HowDidYouHear,
  MaskedDateInput,
} from "../../../components/Form";
import { useApi } from "../../../contexts/ApiContext";
import { useForm } from "react-hook-form";
import Card from "../../../components/Layout/Card";

// TODO: phone regex
export function MSApplicationForm({ ms, mutateMs }) {
  const toast = useToast();
  const { mysteryShopperApi } = useApi();
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name: ms.name,
      email: ms.email,
      phone: "",
      gender: "",
      birthDate: "",
      birthPlace: "",
      education: "",
      occupation: "",
      previousMsExperience: "",
      city: "",
      district: "",
      address: "",
      howDidYouHearAboutUs: "",
      howDidYouHearDetail: "",
    },
  });

  const onSubmit = async (data) => {
    try {
      let serverData;
      // do not send empty -""- howDidYouHearDetail
      if (data.howDidYouHearDetail === "") {
        const { howDidYouHearDetail, ...dataWithoutDetail } = data;
        serverData = { ...dataWithoutDetail };
      } else {
        serverData = { ...data };
      }
      await mysteryShopperApi.registerApplication(ms._id, serverData);
      mutateMs();
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Stack spacing={8}>
      <Stack spacing={2} textAlign={"center"}>
        <Heading>Ina'ya Hoş Geldiniz</Heading>
        <Text color={"gray.600"} fontSize={"xl"}>
          Gizli müşteri başvurusunda bulunmak için lütfen formu doldurunuz 👇
        </Text>
      </Stack>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContainer>
            <Stack direction={{ base: "column", md: "row" }} spacing="24px">
              <FormControl>
                <FormLabel htmlFor="name">Ad Soyad</FormLabel>
                <Input id="name" isDisabled {...register("name")} />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="email">E-Posta</FormLabel>
                <Input id="email" isDisabled {...register("email")} />
              </FormControl>
            </Stack>

            <Stack direction={{ base: "column", md: "row" }} spacing="24px">
              <StandardFormControl errors={errors} name="phone" label="Telefon">
                <Input
                  id={"phone"}
                  placeholder={"+90 (xxx) xxx xxx"}
                  {...register("phone", { required: "Gerekli alan" })}
                />
              </StandardFormControl>

              <RadioFormControl
                control={control}
                errors={errors}
                name="gender"
                label="Cinsiyet"
              >
                <Radio value="female">Kadın</Radio>
                <Radio value="male">Erkek</Radio>
              </RadioFormControl>
            </Stack>

            <Stack direction={{ base: "column", md: "row" }} spacing="24px">
              <StandardFormControl
                errors={errors}
                name="birthDate"
                label="Doğum Tarihiniz"
              >
                <MaskedDateInput name="birthDate" control={control} />
              </StandardFormControl>

              <StandardFormControl
                errors={errors}
                name="birthPlace"
                label="Doğum Yeriniz"
              >
                <Input
                  id={"birthPlace"}
                  {...register("birthPlace", { required: "Gerekli alan" })}
                />
              </StandardFormControl>
            </Stack>

            <Stack direction={{ base: "column", md: "row" }} spacing="24px">
              <StandardFormControl
                errors={errors}
                name="education"
                label="Eğitim Durumunuz"
              >
                <SelectWrapper
                  register={{
                    ...register("education", { required: "Gerekli alan" }),
                  }}
                  data={[
                    { value: "İlkokul", text: "İlkokul" },
                    { value: "Ortaokul", text: "Ortaokul" },
                    { value: "Lise", text: "Lise" },
                    { value: "Üniversite", text: "Üniversite" },
                    { value: "Yüksek Lisans", text: "Yüksek Lisans" },
                    { value: "Doktora", text: "Doktora" },
                  ]}
                />
              </StandardFormControl>

              <StandardFormControl
                errors={errors}
                name="occupation"
                label="Mesleğiniz"
              >
                <Input
                  id={"occupation"}
                  {...register("occupation", { required: "Gerekli alan" })}
                />
              </StandardFormControl>
            </Stack>

            <StandardFormControl
              errors={errors}
              name="previousMsExperience"
              label="Daha önce hiç anket veya gizli müşteri ziyareti yaptınız mı?
              Yaptınızsa hangi firma ve projelerde?"
            >
              <Textarea
                variant={"filled"}
                id="previousMsExperience"
                {...register(
                  "previousMsExperience"
                  // { required: "Gerekli alan",  }
                )}
              />
            </StandardFormControl>

            <CityDistrictCombo
              control={control}
              setValue={setValue}
              errors={errors}
              register={register}
            />

            <StandardFormControl errors={errors} name="address" label="Adres">
              <Textarea
                variant={"filled"}
                id="address"
                {...register("address", { required: "Gerekli alan" })}
              />
            </StandardFormControl>

            <HowDidYouHear
              register={register}
              errors={errors}
              control={control}
              setValue={setValue}
            />

            <FormFooter>
              <Button
                colorScheme={"pink"}
                isLoading={isSubmitting}
                type="submit"
              >
                Gönder
              </Button>
            </FormFooter>
          </FormContainer>
        </form>
      </Card>
    </Stack>
  );
}
