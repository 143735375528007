class SurveyApi {
  constructor(fetcher) {
    this.fetcher = fetcher;
  }

  async sendSurvey({ visitId, data, action }) {
    const { personnelNames, visitDate, ...answers } = data;
    const surveyAnswers = Object.keys(answers)
      // convert question answers object into an array
      .map((question) => {
        const answer = answers[question];
        return {
          question,
          ...answer,
        };
      })
      // filter array for answered ones
      .filter(
        (answer) =>
          (answer.freeTextAnswer && answer.freeTextAnswer !== "") ||
          answer.selectedChoice ||
          answer.selectedMultiChoices?.length > 0
      );

    const res = await this.fetcher.post(`visits/${visitId}/survey/${action}`, {
      surveyAnswers,
      personnelNames: Object.keys(personnelNames).map((id) => ({
        personnelType: id,
        name: personnelNames[id],
      })),
      visitDate,
    });
    return res.data.data;
  }
}
export default SurveyApi;
