import { Box, Stack, VStack, Divider } from "@chakra-ui/react";
import ItemDetail from "../../../components/ItemDetail";
import Card from "../../../components/Layout/Card";
import PageHeader from "../../../components/Layout/PageHeader";
import {
  getBooleanLabel,
  getHowDidYouHearLabel,
  getMaritalStatusLabel,
  getTravelLabel,
} from "../../../util/stringUtils";
import { useLanguageLabels } from "../../../hooks/useLanguageLabels";
import { format } from "date-fns";
import { FiUser,FiPhoneCall, FiDollarSign } from "react-icons/fi";

export function MSInfoTab({ ms }) {
  const languageLabels = useLanguageLabels();
  const {
    name,
    email,
    phone,
    city,
    district,
    address,
    birthDate,
    birthPlace,
    education,
    occupation,
    howDidYouHearAboutUs,
    howDidYouHearDetail,
    previousMsExperience,
    bankName,
    IBAN,
  } = ms;
  const frequentlyVisitedDistricts = ms.frequentlyVisitedDistricts
    .map((d) => `${d.city} / ${d.district}`)
    .join(", ");
  const gender =
    ms.gender === "female" ? "Kadın" : ms.gender === "male" ? "Erkek" : "";
  const maritalStatus = getMaritalStatusLabel(ms.maritalStatus);
  const howDidYouHear = getHowDidYouHearLabel(
    howDidYouHearAboutUs,
    howDidYouHearDetail
  );
  const hasPassport = getBooleanLabel(ms.hasPassport);
  const hasDriversLicense = getBooleanLabel(ms.hasDriversLicense);
  const hasCar = getBooleanLabel(ms.hasCar);
  const hasKids = getBooleanLabel(ms.hasKids);
  const ableToTravel = getTravelLabel(ms.ableToTravel);
  const foreignLanguages = ms.foreignLanguages
    .map((lang) => languageLabels?.[lang])
    .join(", ");

  return (
    <Box mt={2} mb={8}>
      <Card>
        <VStack spacing={4} pb={8} align={"flex-start"}>
          <PageHeader
            title="İletişim Bilgileri"
            icon={<FiPhoneCall />}
          ></PageHeader>
          <Stack direction={["column", "row"]} justify={"space-between"}>
            <ItemDetail medium label="Ad Soyad" value={name} />
            <ItemDetail medium label="Eposta" value={email} />
            <ItemDetail medium label="Telefon" value={phone || "---"} />
          </Stack>
          <Stack direction={["column", "row"]} justify={"space-between"}>
            <ItemDetail medium label="Yaşadığı İl" value={city || "---"} />
            <ItemDetail medium label="İlçe" value={district || "---"} />
          </Stack>
          <ItemDetail medium label="Adres" value={address || "---"} />
          <Stack direction={["column", "row"]} justify={"space-between"}>
            <ItemDetail
              medium
              label="Sık Ziyaret Ettiği İlçeler"
              value={frequentlyVisitedDistricts || "---"}
            />
            <ItemDetail
              medium
              label="Bizi Nereden Duydunuz?"
              value={howDidYouHear || "---"}
            />
          </Stack>
        </VStack>
        <Divider />
        <VStack spacing={4} pb={8} mt={8} align={"flex-start"}>
          <PageHeader
            title="Kişisel Bilgiler"
            icon={<FiUser />}
          ></PageHeader>
          <Stack direction={["column", "row"]} justify={"space-between"}>
            <ItemDetail
              medium
              label="Doğum Tarihi"
              value={birthDate || "---"}
            />
            <ItemDetail medium label="Doğum Yeri" value={birthPlace || "---"} />
            <ItemDetail medium label="Cinsiyet" value={gender || "---"} />
          </Stack>
          <Stack direction={["column", "row"]} justify={"space-between"}>
            <ItemDetail
              medium
              label="Medeni Durumu"
              value={maritalStatus || "---"}
            />
            <ItemDetail medium label="Eğitim" value={education || "---"} />
            <ItemDetail medium label="Meslek" value={occupation || "---"} />
          </Stack>
          <Stack direction={["column", "row"]} justify={"space-between"}>
            <ItemDetail
              medium
              label="Gizli Müşteri Tecrübesi"
              value={previousMsExperience || "---"}
            />
            <ItemDetail medium label="Pasaport" value={hasPassport || "---"} />
            <ItemDetail
              medium
              label="Ehliyet"
              value={hasDriversLicense || "---"}
            />
          </Stack>
          <Stack direction={["column", "row"]} justify={"space-between"}>
            <ItemDetail
              medium
              label="Seyahat Durumu"
              value={ableToTravel || "---"}
            />
            <ItemDetail medium label="Araba" value={hasCar || "---"} />
            <ItemDetail medium label="Çocuk" value={hasKids || "---"} />
          </Stack>
          <ItemDetail
            medium
            label="Yabancı Diller"
            value={foreignLanguages || "---"}
          />
        </VStack>
        <Divider />
        <VStack spacing={4} mt={8} align={"flex-start"}>
          <PageHeader
            title="Ödeme Bilgileri"
            icon={<FiDollarSign />}
          ></PageHeader>
          <Stack direction={["column", "row"]} justify={"space-between"}>
            <ItemDetail medium label="Banka" value={bankName || "---"} />
            <ItemDetail medium label="IBAN" value={IBAN || "---"} />
          </Stack>
        </VStack>
      </Card>
    </Box>
  );
}
