import { Modal, ModalOverlay } from "@chakra-ui/react";
import { createContext, useContext, useState, useReducer } from "react";
import AlertBox from "../components/Alert/AlertBox";
import DrawerBox from "../components/DrawerBox";

const UiContext = createContext();

// wrapper for the context
export function UiContextProvider({ children }) {
  const [isAlertBoxOpen, setIsAlertBoxOpen] = useState(false);
  const [alertContent, setAlertContent] = useState(null);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const [uiState, dispatchUiChange] = useReducer(reducer, {});

  function reducer(state, action) {
    // action = { type, payload: { name, state } }
    if (action.type === "tableUIChange") {
      return {
        ...state,
        [action.payload.name]: action.payload.state,
        // currently replacing the whole table state. If we want we can also fine tune
        // { ...state[action.payload.name], ...action.payload.state }
      };
    }
    if (action.type === "tableUIReset") {
      return {
        ...state,
        [action.payload.name]: {},
      };
    }
    throw Error("Unknown action.");
  }

  // ----------- Alert Box ------------------------
  function closeAlertBox() {
    setIsAlertBoxOpen(false);
  }
  function openAlertBox() {
    setIsAlertBoxOpen(true);
  }
  function alertBox() {
    return (
      <AlertBox isOpen={isAlertBoxOpen} onClose={closeAlertBox}>
        {alertContent}
      </AlertBox>
    );
  }
  function showAlert(alertContent) {
    setAlertContent(alertContent);
    openAlertBox();
  }

  // ----------- Drawer ------------------------
  function closeDrawer() {
    setIsDrawerOpen(false);
  }
  function openDrawer() {
    setIsDrawerOpen(true);
  }
  function drawer() {
    return (
      <DrawerBox isOpen={isDrawerOpen} onClose={closeDrawer}>
        {drawerContent}
      </DrawerBox>
    );
  }
  function showDrawer(drawerContent) {
    setDrawerContent(drawerContent);
    openDrawer();
  }

  // ----------- Modal ------------------------
  function closeModal() {
    setIsModalOpen(false);
  }
  function openModal() {
    setIsModalOpen(true);
  }
  function bigModal() {
    return (
      <Modal isOpen={isModalOpen} onClose={closeModal} size={"3xl"}>
        <ModalOverlay>{modalContent}</ModalOverlay>
      </Modal>
    );
  }
  function showModal(modalContent) {
    setModalContent(modalContent);
    openModal();
  }

  return (
    <UiContext.Provider
      value={{
        alertBox,
        closeAlertBox,
        showAlert,
        drawer,
        closeDrawer,
        showDrawer,
        bigModal,
        closeModal,
        showModal,
        uiState,
        dispatchUiChange,
      }}
    >
      {children}
    </UiContext.Provider>
  );
}

export function useUiContext() {
  return useContext(UiContext);
}
