import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react";
import { FiPlus, FiMoreHorizontal } from "react-icons/fi";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import PageHeader from "../../../components/Layout/PageHeader";
import PageLayout from "../../../components/Layout/PageLayout";
import Card from "../../../components/Layout/Card";
import ClientTable from "../../../components/Table/ClientTable";
import LoadingIndicator from "../../../components/LoadingIndicator";
import DeleteConfirmAlert from "../../../components/Alert/DeleteConfirmAlert";
import GoalUpsertAlert from "./goalUpsert";
import { useApi } from "../../../contexts/ApiContext";
import { useUiContext } from "../../../contexts/UiContext";

export function GoalsIndexPage() {
  const toast = useToast();
  const { goalApi } = useApi();
  const { id: projectId } = useParams();
  const { data: goals, mutate } = useSWR(`/projects/${projectId}/goals`);
  const { data: project } = useSWR(`projects/${projectId}`);
  const { showAlert, closeAlertBox } = useUiContext();

  const columns = useMemo(
    () => [
      {
        Header: "PROJE AMACI",
        accessor: "name",
      },
      {
        Header: "",
        accessor: "_id",
        isNumeric: true,
        Cell: ({ cell }) => (
          <Menu>
            <MenuButton
              variant="ghost"
              size="sm"
              as={IconButton}
              icon={<FiMoreHorizontal />}
            />
            <MenuList>
              <MenuItem
                onClick={() => {
                  showAlert(
                    <GoalUpsertAlert
                      goal={{
                        id: cell.row.values._id,
                        name: cell.row.values.name,
                      }}
                      projectId={projectId}
                      onClose={closeAlertBox}
                      mutateGoals={mutate}
                    />
                  );
                }}
              >
                Düzenle
              </MenuItem>
              <MenuItem
                onClick={() => {
                  showAlert(
                    <DeleteConfirmAlert
                      name={"Proje Amacı"}
                      onDelete={() => deleteRow(cell.row.values._id)}
                    />
                  );
                }}
              >
                Sil
              </MenuItem>
            </MenuList>
          </Menu>
        ),
      },
    ],
    []
  );

  async function deleteRow(id) {
    try {
      await goalApi.deleteOne(id);
      mutate();
      toast({
        title: "Başarılı",
        description: "Proje amacı silindi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  return (
    <PageLayout>
      <PageHeader title="Proje Amaçları">
        {!project?.isArchived && (
          <Button
            leftIcon={<FiPlus />}
            size="sm"
            variant="outline"
            colorScheme="teal"
            onClick={() => {
              showAlert(
                <GoalUpsertAlert
                  projectId={projectId}
                  onClose={closeAlertBox}
                  mutateGoals={mutate}
                />
              );
            }}
          >
            Yeni Ekle
          </Button>
        )}
      </PageHeader>
      <Card topMargin>
        {!goals ? (
          <LoadingIndicator />
        ) : (
          <ClientTable data={goals} columns={columns} />
        )}
      </Card>
    </PageLayout>
  );
}
