import { Button, Heading, Text, VStack } from "@chakra-ui/react";
import { FiFastForward } from "react-icons/fi";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import PageHeader from "../../../components/Layout/PageHeader";
import PageLayout from "../../../components/Layout/PageLayout";
import LoadingIndicator from "../../../components/LoadingIndicator";
import Card from "../../../components/Layout/Card";

export function MsVisitDetailsPage() {
  let { id } = useParams();
  const { data: visit } = useSWR(`/visits/${id}`);
  const { data: visitLocation } = useSWR(
    () => `locations/${visit.location._id}`
  );
  const navigate = useNavigate();

  const backUrl = {
    ms_confirmed: "../pending",
    rejected: "../rejected",
    fm_rejected: "../rejected",
    waiting_approval_admin: "../in-progress",
    waiting_approval_fm: "../in-progress",
    fm_approved: "../in-progress",
  };

  return (
    <PageLayout>
      {!visit || !visitLocation ? (
        <LoadingIndicator />
      ) : visit.status === "approved" || visit.status === "published" ? (
        <Navigate to="financials" />
      ) : (
        <>
          <PageHeader
            title={visit.name}
            backUrl={backUrl[visit.status]}
          ></PageHeader>


<Card topMargin>

          <VStack align={"start"} padding={4} mt={4} spacing={6}>
            <VStack align={"start"} spacing={1}>
              <Heading as="h2" size="md">
                Lokasyon
              </Heading>
              <Text fontWeight={"medium"}>
                {visitLocation?.company.name} - {visitLocation.name}
              </Text>
              <Text>{visitLocation.addressDescription}</Text>
              <Text>
                {visitLocation.district} / {visitLocation.city}
              </Text>
            </VStack>
            <VStack align={"start"}>
              <Heading as="h2" size="md">
                Açıklamalar
              </Heading>
              <Text>{visit.scenario || "---"}</Text>
            </VStack>
            {(visit.status === "rejected" ||
              visit.status === "fm_rejected") && (
              <VStack align={"start"}>
                <Heading as="h2" size="md">
                  Revize Edilmesi Gerekenler
                </Heading>
                <Text>{visit.latestRejectionReason?.body}</Text>
              </VStack>
            )}
            {(visit.status === "ms_confirmed" ||
              visit.status === "rejected" ||
              visit.status === "fm_rejected") && (
              <Button
                colorScheme={"pink"}
                leftIcon={<FiFastForward />}
                onClick={() => navigate("survey")}
              >
                Ankete Başla
              </Button>
            )}
          </VStack>
          </Card>
        </>
      )}
    </PageLayout>
  );
}
