import { Tag } from "@chakra-ui/react";

export function getStatusLabel(status, applicationStatus) {
  if (status === "inactive") return "pasif";

  const labels = {
    approved: "aktif",
    applicationFormCompleted: "eğitim eksik",
    trainingCompleted: "onay bekliyor",
    denied: "reddedildi",
    pending: "yeni başvuru",
  };
  return labels[applicationStatus];
}

function getStatusColor(status, applicationStatus) {
  if (status === "inactive") return "purple";

  const colors = {
    approved: "green",
    applicationFormCompleted: "yellow",
    trainingCompleted: "blue",
    denied: "red",
    pending: "gray",
  };
  return colors[applicationStatus];
}

export function MSStatusTag({ status, applicationStatus, ...rest }) {
  return (
    <Tag colorScheme={getStatusColor(status, applicationStatus)} {...rest}>
      {getStatusLabel(status, applicationStatus)}
    </Tag>
  );
}
