class Api {
  constructor(url, fetcher) {
    this.url = url;
    this.fetcher = fetcher;
  }

  async getAll() {
    const res = await this.fetcher.get(this.url);
    return res.data.data;
  }

  async getOne(id) {
    const res = await this.fetcher.get(`${this.url}/${id}`);
    return res.data.data;
  }

  async createOne(data) {
    const res = await this.fetcher.post(this.url, data);
    return res.data.data;
  }

  async updateOne(id, data) {
    const res = await this.fetcher.put(`${this.url}/${id}`, data);
    return res.data.data;
  }

  async deleteOne(id) {
    const res = await this.fetcher.delete(`${this.url}/${id}`);
    return res.data.data;
  }
}

export default Api;
