import { Box, Image, Text } from "@chakra-ui/react";
import useSWR from "swr";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import Card from "../../components/Layout/Card";
import { useParams } from "react-router-dom";
export  function ProjectHeatMapReportPage() {
  //Burada bu visit icin answer datasini alabiliriz. oradan hesaplanan degeri gosteririz.

  const { projectId } = useParams();
  const { data } = useSWR(`/project-heatmap-report/${projectId}`);
  return (
    <h1>Heat map geliyor :)</h1>

    // <>
    //   {data && (
    //     <TableContainer>
    //       <Table variant="simple" size="lg">
    //         <Thead>
    //           <Tr>
    //             <Th rowSpan={2}>
    //               <Image src="/beymen.png" height={33} />
    //             </Th>
    //             <Th>Ziyaret Puanı</Th>
    //             <Th>Lokasyon Ortalaması</Th>
    //             <Th>Proje Ortalaması</Th>
    //           </Tr>
    //           <Tr>
    //             <Th>
    //               %{data.visitPerformance.performance} -{" "}
    //               {data.visitPerformance.totalScore} /{" "}
    //               {data.visitPerformance.totalMaxScore}
    //             </Th>
    //             <Th>
    //               %{data.locationPerformance.performance} -{" "}
    //               {data.locationPerformance.totalScore} /{" "}
    //               {data.locationPerformance.totalMaxScore}
    //             </Th>
    //             <Th>
    //               %{data.projectPerformance.performance} -{" "}
    //               {data.projectPerformance.totalScore} /{" "}
    //               {data.projectPerformance.totalMaxScore}
    //             </Th>
    //           </Tr>
    //         </Thead>
    //         <Tbody>
    //           {data.mainCategoryList.map((mainCat) => {
    //             const visitCategory = data.visitPerformance.categoryScores.find(
    //               (x) => x.category === mainCat._id
    //             );
    //             const locationCategory =
    //               data.locationPerformance.categoryScores.find(
    //                 (x) => x.category === mainCat._id
    //               );
    //             const projectCategory =
    //               data.projectPerformance.categoryScores.find(
    //                 (x) => x.category === mainCat._id
    //               );
    //             return (
    //               <>
    //                 <Tr>
    //                   <Td>{mainCat.name}</Td>
    //                   <Td>{reportTableCell(visitCategory)}</Td>
    //                   <Td>{reportTableCell(locationCategory)}</Td>
    //                   <Td>{reportTableCell(projectCategory)}</Td>
    //                 </Tr>
    //                 {mainCat.subCategories.length > 0 &&
    //                   mainCat.subCategories.map((subCat) => {
    //                     const visitSubCategory =
    //                       data.visitPerformance.categoryScores.find(
    //                         (x) => x.category === subCat._id
    //                       );
    //                     const locationSubCategory =
    //                       data.locationPerformance.categoryScores.find(
    //                         (x) => x.category === subCat._id
    //                       );
    //                     const projectSubCategory =
    //                       data.projectPerformance.categoryScores.find(
    //                         (x) => x.category === subCat._id
    //                       );
    //                     return (
    //                       <Tr>
    //                         <Td>--{subCat.name}</Td>
    //                         <Td>{reportTableCell(visitSubCategory)}</Td>
    //                         <Td>{reportTableCell(locationSubCategory)}</Td>
    //                         <Td>{reportTableCell(projectSubCategory)}</Td>
    //                       </Tr>
    //                     );
    //                   })}
    //               </>
    //             );
    //           })}
    //         </Tbody>
    //       </Table>
    //     </TableContainer>
    //   )}

    // </>
  );
}

const reportTableCell = (data) => {
  return (
    <>
      <Text fontSize="2xl"> {"%" + data.performance} </Text>
      <Text fontSize="xs">{data.score + " / " + data.maxScore}</Text>
    </>
  );
};
