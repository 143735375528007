import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  VStack,
} from "@chakra-ui/react";
import Card   from "../../../components/Layout/Card";
export function PersonnelNameInputs({
  personnelNames,
  personnelTypeLabels,
  errors,
  register,
}) {
  if (Object.keys(personnelNames).length === 0) return null;
  return (
     <Card topMargin>
      <Heading size={"md"} marginBottom={4} color={"blue.600"}>
        Çalışanların İsimleri
      </Heading>
      <VStack
        borderRadius={"md"}
        borderColor={"gray.200"}
      >
        {Object.keys(personnelNames).map((personnelType) => (
          <FormControl
            key={personnelType}
            isInvalid={errors?.personnelNames?.[personnelType]}
          >
            <FormLabel htmlFor={`personnelNames.${personnelType}`}>
              {personnelTypeLabels[personnelType]}
            </FormLabel>
            <Input
              id={`personnelNames.${personnelType}`}
              {...register(`personnelNames.${personnelType}`, {
                required: "Gerekli alan",
              })}
            />
            <FormErrorMessage>
              {errors?.personnelNames?.[personnelType]?.message}
            </FormErrorMessage>
          </FormControl>
        ))}
      </VStack>
      </Card>
  );
}
