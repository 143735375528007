import {
  Box,
  Button,
  IconButton,
  Link,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  useToast,
} from "@chakra-ui/react";
import { FiPlus, FiCheck, FiSend } from "react-icons/fi";
import { useMemo, useState } from "react";
import useSWR from "swr";
import { Link as RouterLink } from "react-router-dom";
import ClientTable from "../../../../components/Table/ClientTable";
import { Scale } from "../../../../components/Scale";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import { useApi } from "../../../../contexts/ApiContext";
import { useUiContext } from "../../../../contexts/UiContext";

export function NewVisitInvites({ visit, mutateInvites }) {
  const [msList, setMsList] = useState([]); //msList = [msId, ...]
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: unfilteredShoppers, error: shoppersError } = useSWR(
    `/mystery-shoppers/status/active`
  );
  const shoppers = unfilteredShoppers?.filter((ms) =>
    visit.fieldManager?._id ? ms.fieldMgr?._id === visit.fieldManager._id : ms
  );
  const { visitInviteApi } = useApi();
  const { closeModal, dispatchUiChange } = useUiContext();
  const toast = useToast();

  async function sendInvites() {
    try {
      setIsSubmitting(true);
      await visitInviteApi.createBatchForVisit(visit._id, { ms_list: msList });
      mutateInvites();
      setIsSubmitting(false);
      dispatchUiChange({
        type: "tableUIReset",
        payload: { name: "projectplan-new-invites" },
      });
      closeModal();
    } catch (error) {
      setIsSubmitting(false);
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  function addMS(msId) {
    setMsList((msList) => {
      if (!msList.includes(msId)) {
        return [...msList, msId];
      } else {
        return msList;
      }
    });
  }

  function removeMS(msId) {
    setMsList((msList) => {
      return msList.filter((id) => id !== msId);
    });
  }

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "_id",
        Cell: ({ cell }) => {
          const msId = cell.row.values._id;
          return !msList.includes(msId) ? (
            <IconButton
              size={"xs"}
              colorScheme={"green"}
              aria-label="Ekle"
              icon={<FiPlus />}
              isDisabled={isSubmitting}
              onClick={() => addMS(msId)}
            />
          ) : (
            <IconButton
              size={"xs"}
              colorScheme={"blue"}
              aria-label="Ekle"
              icon={<FiCheck />}
              isDisabled={isSubmitting}
              onClick={() => removeMS(msId)}
            />
          );
        },
      },
      {
        Header: "GİZLİ MÜŞTERİ",
        accessor: "name",
        Cell: ({ cell }) => {
          const { _id, name, email } = cell.row.original;
          return (
            <Link
              as={RouterLink}
              to={`/ina/msm/mystery-shoppers/${_id}`}
              isExternal
            >
              <Box>
                <Text fontWeight="bold">{name}</Text>
                {/* <Text fontSize="sm">{email}</Text> */}
              </Box>
            </Link>
          );
        },
      },
      {
        Header: "PUAN",
        accessor: "averageVisitScore",
        Cell: ({ cell }) => {
          const score = cell.row.values?.averageVisitScore;
          return score ? score : null;
        },
      },
      {
        Header: "ŞEHİR",
        accessor: "city",
      },
      {
        Header: "CİNSİYET",
        accessor: (row) =>
          row.gender === "female"
            ? "Kadın"
            : row.gender === "male"
            ? "Erkek"
            : "",
      },
      {
        Header: "MESLEK",
        accessor: "occupation",
      },
    ],
    [msList]
  );

  if (!shoppers) {
    return (
      <ModalContent>
        <ModalHeader>Yeni GM Davetiyesi Gönder</ModalHeader>
        <ModalBody>
          <LoadingIndicator />
        </ModalBody>
      </ModalContent>
    );
  }

  return (
    <ModalContent>
      <ModalHeader>Yeni GM Davetiyesi Gönder</ModalHeader>
      <ModalBody size="xl">
        <ClientTable
          data={shoppers}
          columns={columns}
          tableName="projectplan-new-invites"
        />
      </ModalBody>
      <ModalFooter>
        <Button
          mb={4}
          size={"sm"}
          colorScheme={"blue"}
          leftIcon={<FiSend />}
          isDisabled={msList.length === 0}
          isLoading={isSubmitting}
          onClick={sendInvites}
        >
          Davet Gönder
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}
