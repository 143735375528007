import { useMemo } from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import PageHeader from "../../components/Layout/PageHeader";
import PageLayout from "../../components/Layout/PageLayout";
import ClientTable from "../../components/Table/ClientTable";
import LoadingIndicator from "../../components/LoadingIndicator";
import { projectStatus } from "../../util/enums";

export default function FmProjectListPage() {
  const { data: projects } = useSWR("/projects");

  const columns = useMemo(
    () => [
      {
        Header: "Proje Adi",
        accessor: "name",
        Cell: ({ cell }) => (
          <Link to={`fm/projects/${cell.row.original._id}/project-plan`}>
            {cell.row.values.name}
          </Link>
        ),
      },
      {
        Header: "Firma",
        accessor: "company.name",
      },
      {
        Header: "Durum",
        accessor: (row) => projectStatus[row.status].tr,
      },
    ],
    []
  );

  return (
    <PageLayout>
      <PageHeader title="Proje Listesi"></PageHeader>
      {!projects ? (
        <LoadingIndicator />
      ) : (
        <ClientTable data={projects} columns={columns} />
      )}
    </PageLayout>
  );
}
