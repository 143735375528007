import {
  Button,
  FormControl,
  FormLabel,
  Input,
  DrawerBody,
  DrawerFooter,
  Stack,
  Switch,
  useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { useSWRConfig } from "swr";
import { useApi } from "../../../contexts/ApiContext";
import FormContainer from "../../../components/Form/FormContainer";
import SelectWrapper from "../../../components/Form/SelectWrapper";
import StandardFormControl from "../../../components/Form/StandardFormControl";
import { LocationMultiSelect } from "../../../components/Form/LocationMultiSelect";
import { getRoleLabel } from "../../../util/stringUtils";
import { regexEmail } from "../../../util/regex";

// TODO: phone regex
export function NewPersonnelForm({ companyId, closeDrawer }) {
  const toast = useToast();
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      role: "companyMgr",
      name: "",
      email: "",
      password: "Pass1923",
      phone: "",
      status: true,
      location: "",
    },
  });

  const { companyMgrApi, locationMgrApi } = useApi();
  const { mutate } = useSWRConfig();

  // if (locationDataError) {
  //   toast({
  //     title: "Bir hata oluştu.",
  //     status: "error",
  //     duration: 3000,
  //   });
  // }

  const watchRole = watch("role");

  async function onSubmit(data) {
    const { status } = data;
    data.status = status ? "active" : "inactive";
    try {
      if (data.role === "companyMgr") {
        await companyMgrApi.createOneForCompany(companyId, data);
      } else {
        await locationMgrApi.createOneForCompany(companyId, data);
      }
      mutate(`/companies/${companyId}/personnel`);
      toast({
        title: "Başarılı",
        description: "Yeni kullanıcı oluşturuldu.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      closeDrawer();
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  return (
    <>
      <DrawerBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContainer p={2}>
            <Stack direction={"column"} spacing="24px">
              <StandardFormControl
                errors={errors}
                name="role"
                label="Kullanıcı Rolü"
              >
                <SelectWrapper
                  register={{
                    ...register("role", { required: "Gerekli alan" }),
                  }}
                  data={[
                    { value: "companyMgr", text: getRoleLabel("companyMgr") },
                    { value: "locationMgr", text: getRoleLabel("locationMgr") },
                  ]}
                />
              </StandardFormControl>

              <StandardFormControl errors={errors} name="name" label="Ad Soyad">
                <Input
                  id="name"
                  placeholder="Ad Soyad"
                  {...register("name", {
                    required: "Gerekli alan",
                    minLength: {
                      value: 4,
                      message: "En az 4 karakter gerekli.",
                    },
                  })}
                />
              </StandardFormControl>

              <StandardFormControl errors={errors} name="email" label="E-posta">
                <Input
                  id="email"
                  placeholder="abc@company.com"
                  {...register("email", {
                    required: "Gerekli alan",
                    pattern: {
                      value: regexEmail,
                      message: "Geçersiz e-posta",
                    },
                  })}
                />
              </StandardFormControl>

              <StandardFormControl errors={errors} name="phone" label="Telefon">
                <Input
                  id="phone"
                  placeholder="Telefon"
                  {...register("phone")}
                />
              </StandardFormControl>

              {watchRole === "locationMgr" && (
                <LocationMultiSelect
                  name="location"
                  control={control}
                  companyId={companyId}
                />
              )}

              <StandardFormControl
                errors={errors}
                name="password"
                label="Şifre"
              >
                <Input
                  id="password"
                  isDisabled
                  // placeholder="Parola"
                  {...register("password", {
                    required: "Gerekli alan",
                  })}
                />
              </StandardFormControl>

              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="status" mb="0">
                  Aktif?
                </FormLabel>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Switch
                        id="status"
                        colorScheme="pink"
                        {...field}
                        isChecked={field.value ? true : false}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    );
                  }}
                />
              </FormControl>
            </Stack>
          </FormContainer>
        </form>
      </DrawerBody>
      <DrawerFooter>
        <Button variant="outline" mr={3} onClick={closeDrawer}>
          Vazgeç
        </Button>
        <Button
          colorScheme={"pink"}
          isLoading={isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          Kaydet
        </Button>
      </DrawerFooter>
    </>
  );
}
