import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  StackDivider,
  VStack,
} from "@chakra-ui/react";
import { useFieldArray } from "react-hook-form";

export function BreakdownOptionsArray({ register, control, errors, id }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "options",
  });

  return (
    <Box mt={6}>
      <Flex justify={"space-between"} mb={4}>
        <FormLabel>Seçenekler</FormLabel>
        <Button
          rightIcon={<AddIcon />}
          colorScheme="blue"
          variant="outline"
          aria-label="Seçenek Ekle"
          size="sm"
          onClick={() => append({ optionText: "" })}
        >
          Seçenek Ekle
        </Button>
      </Flex>

      <VStack
        divider={<StackDivider borderColor="blue.300" />}
        spacing={2}
        align="stretch"
      >
        {fields.map((item, index) => (
          <HStack
            key={item.id}
            align={"center"}
            minWidth="100%"
            spacing={4}
            paddingRight={index > 1 && !id && 2}
          >
            <FormControl isInvalid={errors.options?.[index]?.optionText}>
              <Input
                placeholder={`${index + 1}. Seçenek`}
                {...register(`options.${index}.optionText`, {
                  required: "Gerekli alan",
                })}
              />
              <FormErrorMessage>
                {errors.options?.[index]?.optionText?.message}
              </FormErrorMessage>
            </FormControl>

            {index > 1 && !id && (
              <IconButton
                variant="outline"
                colorScheme="pink"
                aria-label="Sil"
                size="xs"
                fontSize="11px"
                icon={<CloseIcon />}
                onClick={() => remove(index)}
              />
            )}
          </HStack>
        ))}
      </VStack>
    </Box>
  );
}
