import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  NumberInput,
  NumberInputField,
  VStack,
} from "@chakra-ui/react";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { useFieldArray } from "react-hook-form";
import { regexNoEmptyString } from "../../../../util/regex";

export function ChoicesArray({ register, control, errors, personnelType }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "choices",
  });

  return (
    <>
      <Flex justify={"space-between"} mt={4} mb={1}>
        <FormLabel>Soru Şıkları</FormLabel>
        <Button
          rightIcon={<AddIcon />}
          colorScheme="blue"
          variant="outline"
          aria-label="Şık Ekle"
          size="sm"
          onClick={() => append({ choiceText: "", score: "" })}
        >
          Şık Ekle
        </Button>
      </Flex>

      <VStack spacing={3} align="stretch">
        {fields.map((item, index) => (
          <VStack
            key={item.id}
            borderRadius="xl"
            borderWidth="2px"
            borderColor="gray.300"
            borderStyle={"dashed"}
            p={4}
          >
            <FormControl isInvalid={errors.choices?.[index]?.choiceText}>
              <Input
                placeholder={`${index + 1}.Şık`}
                {...register(`choices.${index}.choiceText`, {
                  required: "Gerekli alan",
                  pattern: {
                    value: regexNoEmptyString,
                    message: "Gerekli alan",
                  },
                })}
              />
              <FormErrorMessage>
                {errors.choices?.[index]?.choiceText?.message}
              </FormErrorMessage>
            </FormControl>
            <HStack minWidth="100%">
              <FormControl isInvalid={errors.choices?.[index]?.score}>
                <NumberInput>
                  <NumberInputField
                    placeholder="Soru Puanı"
                    {...register(`choices.${index}.score`, {
                      required: "Gerekli alan",
                      min: {
                        value: 0,
                        message: "0-100 arası",
                      },
                      max: {
                        value: 100,
                        message: "0-100 arası",
                      },
                    })}
                  />
                </NumberInput>
                <FormErrorMessage>
                  {errors.choices?.[index]?.score?.message}
                </FormErrorMessage>
                {!errors.choices?.[index]?.score &&
                  errors.choices?.[index]?.personnelScore && (
                    <Box color={"white"}>.</Box>
                  )}
              </FormControl>

              {personnelType && (
                <FormControl
                  isInvalid={errors.choices?.[index]?.personnelScore}
                >
                  <NumberInput>
                    <NumberInputField
                      placeholder="Personel Puanı"
                      {...register(`choices.${index}.personnelScore`, {
                        // required: "Gerekli alan",
                        min: {
                          value: 0,
                          message: "0-100 arası",
                        },
                        max: {
                          value: 100,
                          message: "0-100 arası",
                        },
                      })}
                    />
                  </NumberInput>
                  <FormErrorMessage>
                    {errors.choices?.[index]?.personnelScore?.message}
                  </FormErrorMessage>
                  {!errors.choices?.[index]?.personnelScore &&
                    errors.choices?.[index]?.score && (
                      <Box color={"white"}>.</Box>
                    )}
                </FormControl>
              )}
            </HStack>

            <HStack
              align={"center"}
              minWidth="100%"
              spacing={4}
              paddingRight={index > 1 && 2}
            >
              <FormControl isInvalid={errors.choices?.[index]?.descriptionText}>
                <Input
                  placeholder="Açıklayıcı Metin"
                  {...register(`choices.${index}.descriptionText`, {
                    pattern: {
                      value: regexNoEmptyString,
                      message: "Hatalı metin",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.choices?.[index]?.descriptionText?.message}
                </FormErrorMessage>
              </FormControl>
              {index > 1 && (
                <IconButton
                  variant="outline"
                  colorScheme="pink"
                  aria-label="Sil"
                  size="xs"
                  fontSize="11px"
                  icon={<CloseIcon />}
                  onClick={() => remove(index)}
                />
              )}
            </HStack>
          </VStack>
        ))}
      </VStack>
    </>
  );
}
