import { Button, useToast } from "@chakra-ui/react";
import { FiArchive, FiPlay, FiSend } from "react-icons/fi";
import ConfirmAlert from "../../../components/Alert/ConfirmAlert";
import { useApi } from "../../../contexts/ApiContext";
import { useUiContext } from "../../../contexts/UiContext";

export function ProjectActionButton({
  projectId,
  status,
  isArchived,
  mutateProject,
}) {
  const { showAlert } = useUiContext();
  const toast = useToast();
  const { projectApi } = useApi();

  const actionType =
    status === "draft"
      ? "start-project"
      : status === "inProgress"
      ? "complete-project"
      : "archive-project";

  async function handleAction() {
    const descriptons = {
      "start-project": "Proje başlatıldı.",
      "complete-project": "Proje müşteriye hazır.",
      "archive-project": "Proje arşivlendi.",
    };

    try {
      await projectApi.actionExecuter(projectId, actionType);
      mutateProject();
      toast({
        title: "Başarılı",
        description: descriptons[actionType],
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  const header =
    status === "draft"
      ? "Projeyi Başlat"
      : status === "inProgress"
      ? "Müşteriyle Paylaş"
      : "Projeyi Arşivle";

  const body =
    status === "draft"
      ? "Bu projeyi başlatmak istediğinize emin misiniz?"
      : status === "inProgress"
      ? "Bu projeyi müşteriyle paylaşmak istediğinize emin misiniz?"
      : "Bu projeyi arşivlemek istediğinize emin misiniz?";

  const buttonColorScheme =
    status === "draft" ? "blue" : status === "inProgress" ? "purple" : "gray";

  if (isArchived) return null;

  return (
    <Button
      size={"sm"}
      colorScheme={buttonColorScheme}
      leftIcon={
        status === "draft" ? (
          <FiPlay />
        ) : status === "inProgress" ? (
          <FiSend />
        ) : (
          <FiArchive />
        )
      }
      onClick={() => {
        showAlert(
          <ConfirmAlert
            header={header}
            body={body}
            buttonLabel="Onayla"
            buttonColorScheme="green"
            onAction={handleAction}
          />
        );
      }}
    >
      {status === "draft"
        ? "Projeyi Başlat"
        : status === "inProgress"
        ? "Müşteriyle Paylaş"
        : "Arşivle"}
    </Button>
  );
}
