import { StarIcon } from "@chakra-ui/icons";
import { Flex, Box, Center, Text } from "@chakra-ui/react";
export function Scale({ value, color = "red.400" }) {
  // 1-10 (1-2: 1 star, 3-4: 2 star, 5-6: 3 star, ...)
  const ColoredStar = () => <StarIcon color={color} />;
  return (
    <>
      <Flex align={"center"}>
          
          <Box mt={-1}>
            <ColoredStar />
            {value >= 3 ? <ColoredStar /> : <StarIcon />}
            {value >= 5 ? <ColoredStar /> : <StarIcon />}
            {value >= 7 ? <ColoredStar /> : <StarIcon />}
            {value >= 9 ? <ColoredStar /> : <StarIcon />}
          </Box>
          <Box pl={2}>
            <Text fontSize="12">{value}</Text>
          </Box>
      </Flex>
    </>
  );
}
