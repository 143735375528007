import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react";
import { FiPlus, FiMoreHorizontal } from "react-icons/fi";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import PageHeader from "../../../components/Layout/PageHeader";
import PageLayout from "../../../components/Layout/PageLayout";
import Card from "../../../components/Layout/Card";
import ClientTable from "../../../components/Table/ClientTable";
import LoadingIndicator from "../../../components/LoadingIndicator";
import DeleteConfirmAlert from "../../../components/Alert/DeleteConfirmAlert";
import PersonnelTypeUpsertAlert from "./personnelTypeUpsert";
import { useApi } from "../../../contexts/ApiContext";
import { useUiContext } from "../../../contexts/UiContext";

export function PersonnelTypesIndexPage() {
  const toast = useToast();
  const { personnelTypeApi } = useApi();
  const { id: projectId } = useParams();
  const { data: personnelTypes, mutate } = useSWR(
    `/projects/${projectId}/personneltypes`
  );
  const { data: project } = useSWR(`/projects/${projectId}`);
  const { showAlert, closeAlertBox } = useUiContext();
  console.log("Serverdan geliyor mu",personnelTypes)
  const columns = useMemo(
    () => [
      {
        Header: "PERSONEL TİPİ",
        accessor: "name",
      },
      {
        Header: "Renk",
        accessor: "color",
        Cell: ({ cell }) => {
          return (
            <div
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: cell.row.values.color,
                borderRadius: "50%",
              }}
            ></div>
          );
        },
      },
      {
        Header: "",
        accessor: "_id",
        isNumeric: true,
        Cell: ({ cell }) => {
          console.log("cell",cell)
          return (
            <Menu>
            <MenuButton
              variant="ghost"
              size="sm"
              as={IconButton}
              icon={<FiMoreHorizontal />}
            />
            <MenuList>
              <MenuItem
                onClick={() => {
                  showAlert(
                    <PersonnelTypeUpsertAlert
                      personnelType={{
                        id: cell.row.values._id,
                        name: cell.row.values.name,
                        color: cell.row.values.color,
                      }}
                      projectId={projectId}
                      onClose={closeAlertBox}
                      mutatePersonnelTypes={mutate}
                    />
                  );
                }}
              >
                Düzenle
              </MenuItem>
              <MenuItem
                onClick={() => {
                  showAlert(
                    <DeleteConfirmAlert
                      name={"Personel"}
                      onDelete={() => deleteRow(cell.row.values._id)}
                    />
                  );
                }}
              >
                Sil
              </MenuItem>
            </MenuList>
          </Menu>
            )
        }
      },
    ],
    []
  );

  async function deleteRow(id) {
    try {
      await personnelTypeApi.deleteOne(id);
      mutate();
      toast({
        title: "Başarılı",
        description: "Personel tipi silindi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  return (
    <PageLayout>
      <PageHeader title="Personel Tipleri">
        {!project?.isArchived && (
          <Button
            leftIcon={<FiPlus />}
            size="sm"
            variant="outline"
            colorScheme="teal"
            onClick={() => {
              showAlert(
                <PersonnelTypeUpsertAlert
                  projectId={projectId}
                  onClose={closeAlertBox}
                  mutatePersonnelTypes={mutate}
                />
              );
            }}
          >
            Yeni Ekle
          </Button>
        )}
      </PageHeader>
      <Card topMargin>
        {!personnelTypes ? (
          <LoadingIndicator />
        ) : (
          <ClientTable data={personnelTypes} columns={columns} />
        )}
      </Card>
    </PageLayout>
  );
}
