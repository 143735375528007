import { Outlet } from "react-router-dom";
import SplitPageWithSideNav from "../../components/SplitPageWithSideNav";
import { FiUsers, FiMail, FiHardDrive, FiKey } from "react-icons/fi";

export default function SettingsPage() {
  const LinkItems = [
    {
      name: "Kullanıcılar",
      icon: FiUsers,
      to: "users",
    },
    {
      name: "Mail Ayarları",
      icon: FiMail,
      to: "email",
    },
    {
      name: "KVKK Logs",
      icon: FiHardDrive,
      to: "kvkk",
    },
    {
      name: "API Tokens",
      icon: FiKey,
      to: "api-tokens",
    },
  ];

  return (
    <SplitPageWithSideNav linkItems={LinkItems}>
      <Outlet />
    </SplitPageWithSideNav>
  );
}
