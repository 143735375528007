import { Box } from "@chakra-ui/react";
import { useEffect } from "react";
import { useWatch } from "react-hook-form";
import {
  FreeTextQuestion,
  SingleSelectQuestion,
  MultiSelectQuestion,
} from "./";

export function SurveyQuestion({
  question,
  index,
  register,
  errors,
  control,
  setValue,
}) {
  const { _id, questionType, skipQuestionIf } = question;

  const watchedParentQuestion = useWatch({
    control,
    name: skipQuestionIf?.parentQuestion,
  });

  const skipQuestion = skipQuestionIf?.parentQuestion
    ? skipQuestionIf?.choice === watchedParentQuestion?.selectedChoice
    : false;

  useEffect(() => {
    // if skip question changes (to true), update the value to null
    if (skipQuestion === true) {
      if (questionType === "freeText") {
        setValue(`${_id}.freeTextAnswer`, "");
      }
      if (questionType === "singleSelect") {
        setValue(`${_id}.selectedChoice`, undefined);
        // setValue(`${_id}.choiceDescriptions`, {});
      }
      if (questionType === "multiSelect") {
        setValue(`${_id}.selectedMultiChoices`, []);
      }
    }
  }, [skipQuestion]);

  return (
    <Box
      bg={"white"}
      p={4}
      marginY={4}
      border={"1px"}
      borderRadius={"md"}
      borderColor={"gray.200"}
      ml={4}
    >
      {questionType === "freeText" && (
        <FreeTextQuestion
          question={question}
          index={index}
          register={register}
          errors={errors}
          skipQuestion={skipQuestion}
        />
      )}

      {questionType === "singleSelect" && (
        <SingleSelectQuestion
          question={question}
          index={index}
          errors={errors}
          control={control}
          skipQuestion={skipQuestion}
          register={register}
        />
      )}

      {questionType === "multiSelect" && (
        <MultiSelectQuestion
          question={question}
          index={index}
          errors={errors}
          control={control}
          skipQuestion={skipQuestion}
          register={register}
        />
      )}
    </Box>
  );
}
