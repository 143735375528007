import { useToast } from "@chakra-ui/react";
import useSWR from "swr";
import LocationUpsertForm from "./locationUpsertForm";

// Container component to retrieve location data to be updated
export default function LocationUpsertComp({ companyId, id, closeDrawer }) {
  const toast = useToast();
  const {
    data: location,
    error,
    mutate,
  } = useSWR(id ? `/locations/${id}` : null);

  if (error) {
    toast({
      title: "Bir hata oluştu.",
      description: "Lütfen tekrar deneyin.",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
  }

  // Loading...
  if (id && !location) {
    return null;
  }

  return (
    <LocationUpsertForm
      companyId={companyId}
      location={location}
      mutateLocation={mutate}
      closeDrawer={closeDrawer}
    />
  );
}
