import { SurveyQuestion } from "./surveyQuestion";

export function SurveyQuestionList({
  questions,
  mutateQuestionForm,
  register,
  errors,
  control,
  setValue,
}) {
  return questions.map((question, index) => (
    <SurveyQuestion
      key={question._id}
      index={index}
      question={question}
      mutateQuestionForm={mutateQuestionForm}
      register={register}
      errors={errors}
      control={control}
      setValue={setValue}
    />
  ));
}
